import {useEffect, useState, ChangeEvent} from 'react';

import * as R from 'ramda';
import CloseIcon from '@material-ui/icons/Close';
import {Box, IconButton, InputAdornment, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';

import MediumTextField from 'components/inputs/MediumTextField';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import Row from './Row';
import type {ProviderTokenAccount} from 'types';

export interface account {
  id: string
  name: string
}

interface Props {
  isTrial: boolean
  maxSubaccountsCount?: number
  companyAccounts: ProviderTokenAccount[]
  tokenAccounts: ProviderTokenAccount[]
  disableButtons?: boolean
  onSave: (accounts: account[]) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      ...theme.typography.body1,
      color: theme.palette.text.secondary,
      whiteSpace: 'pre-line',
    },
    tagText: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
    removeIcon: {
      padding: theme.spacing(1),
    },
  }),
);

const MAX_SUBACCOUNTS_TO_SHOW = 100;

const Form = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<string>('');

  useEffect(() => {
    if (props.tokenAccounts.length > 0) {
      const savedIds = props.tokenAccounts.map(v => v.id);
      const savedAccounts = props.companyAccounts.filter(v => savedIds.includes(v.id));
      if (savedAccounts.length > 0) {
        setSelectedCurrency(savedAccounts[0].currency);
        setSelectedAccounts(savedAccounts.map(v => v.id));
      }
    }
  }, [props.tokenAccounts, props.companyAccounts]);

  const renderSearch = () => {
    const handleChange = (event: ChangeEvent<{value: unknown}>) => {
      setSearch(event.target.value as string);
    };

    const handleClear = () => {
      setSearch('');
    };

    return (
      <Box mt={4}>
        <MediumTextField
          placeholder={t(
            'Поиск по ID или имени аккаунта',
            'Поиск по ID или имени аккаунта',
          )}
          value={search}
          myProps={{placeholderFocused: ''}}
          InputProps={{
            endAdornment: search ? (
              <InputAdornment position='end'>
                <IconButton edge='end' onClick={handleClear}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={handleChange}
        />
      </Box>
    );
  };

  const companyAccountsByIdMap = props.companyAccounts.reduce((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {} as {[key: string]: ProviderTokenAccount});

  const tokenAccountsByIdMap = props.tokenAccounts.reduce((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {} as {[key: string]: ProviderTokenAccount});

  const isAllSelected = R.pipe(
    R.map<ProviderTokenAccount, string>(v => v.id),
    R.symmetricDifference(selectedAccounts),
    R.isEmpty,
  )(props.companyAccounts);

  const limitReached =  !!props.maxSubaccountsCount &&
    selectedAccounts.length >= props.maxSubaccountsCount;

  const renderTag = (id: string) => {
    const handleRemove = () => {
      setSelectedAccounts(R.without([id], selectedAccounts));
    };

    // Subaccount might have been added a long time ago and is not present
    // in company accounts now => use the name stored in token.accounts then
    const name = companyAccountsByIdMap[id]
      ? companyAccountsByIdMap[id].name
      : tokenAccountsByIdMap[id].name;

    return (
      <Box key={id} alignItems='center' display='flex' mr={4}>
        <Typography className={classes.tagText}>{name}</Typography>
        <IconButton className={classes.removeIcon} onClick={handleRemove}>
          <img
            alt='Remove'
            height={18}
            src={new URL('./images/remove.png', import.meta.url).href}
            width={18}
          />
        </IconButton>
      </Box>
    );
  };

  const renderAccounts = () => {
    const canSelectAll = props.maxSubaccountsCount == null ||
      props.maxSubaccountsCount >= props.companyAccounts.length;

    const handleSelect = (id: string, currency: string) => () => {
      if (R.includes(id, selectedAccounts)) {
        const newSelectedAccounts = R.without([id], selectedAccounts);
        setSelectedAccounts(newSelectedAccounts);
        if (newSelectedAccounts.length == 0) {
          setSelectedCurrency('');
        }
      } else {
        if (selectedAccounts.length == 0) {
          setSelectedCurrency(currency);
        }
        if (!limitReached) {
          setSelectedAccounts(R.concat(selectedAccounts, [id]));
        }
      }
    };

    const handleSelectAll = () => {
      if (isAllSelected) {
        setSelectedAccounts([]);
      } else {
        const companyAccountIds = props.companyAccounts.map(v => v.id);
        if (!limitReached) {
          setSelectedAccounts(companyAccountIds);
        }
      }
    };

    const currencies = R.pipe(
      R.map(R.prop('currency')),
      R.uniq,
    )(props.companyAccounts);

    const filteredCompanyAccounts = R.pipe(
      R.sortBy<ProviderTokenAccount>(R.prop('name')),
      R.filter<ProviderTokenAccount>(
        v =>
          v.id.toLowerCase().includes(search.toLowerCase()) ||
          v.name.toLowerCase().includes(search.toLowerCase()),
      ),
    )(props.companyAccounts);

    return (
      <Box mt={4}>
        <Row
          selected={isAllSelected}
          title={t('Выбрать все', 'Выбрать все')}
          disabled={currencies.length > 1 || !canSelectAll}
          onSelect={handleSelectAll}
        />

        {filteredCompanyAccounts
          .slice(0, MAX_SUBACCOUNTS_TO_SHOW)
          .map((v, i) => {
            const selected = R.includes(v.id, selectedAccounts);
            const currencyNotMatched = selectedCurrency != '' &&
              v.currency != selectedCurrency;
            const disabled = !selected && (currencyNotMatched || limitReached);

            return (
              <Row
                key={i}
                selected={selected}
                subtitle={` (ID ${v.id})`}
                title={v.name}
                currency={v.currency}
                disabled={disabled}
                onSelect={handleSelect(v.id, v.currency)}
              />
            );
          })}
      </Box>
    );
  };

  const renderButtons = () => {
    const handleClick = async () => {
      const accounts = props.companyAccounts
        .filter(v => R.includes(v.id, selectedAccounts))
        .map(v => ({id: v.id, name: v.name}));

      props.onSave(accounts);
    };

    const disabled = props.disableButtons || selectedAccounts.length === 0;

    return (
      <Box mt={4}>
        <PrimaryMediumButton
          disabled={disabled}
          fullWidth
          onClick={handleClick}
        >
          <Trans>Продолжить</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  return (
    <Box>
      {renderSearch()}

      <Box display='flex' flexWrap='wrap' mt={2}>
        {selectedAccounts.map(v => renderTag(v))}
      </Box>
      {limitReached && !isAllSelected && (
        <Box display='flex' flexWrap='wrap' mt={2}>
          {props.isTrial && (
            <Trans>
              Исчерпан лимит установленный для вашего триал-режима.
              <br />
              Для расширения возможностей - обратитесь к вашему менеджеру.
            </Trans>
          )}
          {!props.isTrial && (
            <Trans>
              Исчерпан лимит установленный для вашего тарифа.
              <br />
              Для расширения возможностей - обратитесь к вашему менеджеру.
            </Trans>
          )}
        </Box>
      )}

      {renderAccounts()}
      {renderButtons()}
    </Box>
  );
};

export default Form;
