/** @format */

import {useEffect, useState} from 'react';

import {Box, Button, Grow, Typography, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';

export const ACTION_BANNER_Z_INDEX = 999;

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.action.disabledBackground,
    display: 'flex',
    minHeight: theme.spacing(16),
    position: 'relative',
    zIndex: ACTION_BANNER_Z_INDEX,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  containerNew: {
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    padding: '16px',
    zIndex: ACTION_BANNER_Z_INDEX,
    background: '#AE5658',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },
  innerNew: {
    width: '936px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  text: {
    fontWeight: '400',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '14px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      lineHeight: '20px',
      marginLeft: theme.spacing(2),
    },
  },
  button: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: '20px',
    minHeight: 40,
    minWidth: 135,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
    },
  },
  textNew: {
    fontSize: '16px',
    fontWeight: 700,
  },
  buttonNew: {
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #FFF',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));

const ActionBanner = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {isNewVersionEnabled} = useFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setTimeout(() => setOpen(true), 100);
  }, []);

  return isNewVersionEnabled ? (
    <Grow in={open}>
      <Box className={classes.containerNew}>
        <Box className={classes.innerNew}>
          <Typography className={classes.textNew}>{props.text}</Typography>
          <Button
            className={classes.buttonNew}
            fullWidth={false}
            onClick={props.onClick}
          >
            {props.buttonText}
          </Button>
        </Box>
      </Box>
    </Grow>
  ) : (
    <Grow in={open}>
      <Box className={classes.container}>
        {!isMobile && (
          <img
            alt='Icon'
            src={
              new URL(`./images/${props.iconName}_desktop.svg`, import.meta.url)
            }
          />
        )}
        <Typography className={classes.text}>{props.text}</Typography>
        <Button
          className={classes.button}
          color='primary'
          fullWidth={false}
          size='medium'
          variant='contained'
          onClick={props.onClick}
        >
          {props.buttonText}
        </Button>
      </Box>
    </Grow>
  );
};

export default ActionBanner;
