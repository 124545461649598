/** @format */

import {Backdrop, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(_theme => ({
  backdrop: {
    backgroundColor: 'rgba(20, 20, 20, 0.7)',
    zIndex: 1,
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress />
    </Backdrop>
  );
};

export default Loading;
