/** @format */

import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import imageHelpers from 'helpers/imageHelpers';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(4),
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#2B2B2B',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

interface Props {
  adTypes: string[];
  tokens: {
    provider: string;
  }[];
}

const ConnectedProvidersNew = (props: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.iconsContainer}>
      {props.adTypes.includes('CONTEXT') && (
        <>
          {props.tokens.find(v => v.provider === 'YANDEX') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('YANDEX')}
            />
          )}
          {props.tokens.find(v => v.provider === 'GOOGLE') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('GOOGLE')}
            />
          )}
        </>
      )}

      {props.adTypes.includes('TARGET') && (
        <>
          {props.tokens.find(v => v.provider === 'VKONTAKTE') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('VKONTAKTE')}
            />
          )}
          {props.tokens.find(v => v.provider === 'VKONTAKTE_ADS') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('VKONTAKTE_ADS')}
            />
          )}
          {props.tokens.find(v => v.provider === 'FACEBOOK') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('FACEBOOK')}
            />
          )}
        </>
      )}
      {props.adTypes.includes('STATS') && (
        <>
          {props.tokens.find(v => v.provider === 'METRIKA') && (
            <img
              alt='Icon'
              className={classes.icon}
              src={imageHelpers.providerNewIconSrc('METRIKA')}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ConnectedProvidersNew;
