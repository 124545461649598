/** @format */

import {useEffect} from 'react';

import Alert from '@material-ui/lab/Alert';
import {Box, LinearProgress} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {useNavigate, useLocation} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';

import Breadcrumbs from 'components/Breadcrumbs';
import Dashboard from 'components/Dashboard';
import DashboardNew from 'components/DashboardNew';
import adTypeHelpers from 'helpers/adTypeHelpers';
import checkHelpers from 'helpers/checkHelpers';
import connectHelpers from 'helpers/connectHelpers';
import paymentHelpers from 'helpers/paymentHelpers';
import routerHelpers from 'helpers/routerHelpers';
import wizardHelpers from 'helpers/wizardHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import {ROUTES} from 'constants/ROUTES';

import Header from './Header';
import Issues from './Issues';
import NoTokens from './NoTokens';
import Performance from './Performance';

import HeaderNew from './HeaderNew';
import IssuesNew from './IssuesNew';
import PerformanceNew from './PerformanceNew';
import Indicators from './Indicators';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      currentCompany {
        id
        name
        sensorExclusions {
          sensorName
        }
        tokens {
          lastError
          provider
        }
        isLaunched
        isLaunchEnabled
        lastCheckCollectDate
        lastCheckCreateDate
        lastChecks {
          id
          checks
          cost
          found
          new
          fixed
          sensor {
            id
            name
            platform
            isError
          }
        }
        regions {
          id
          name
        }
        lastBudgetScores {
          adType
          value
        }
        isClientAccess
        mediaplan {
          yandex
          google
          vkontakte
          vkontakteAds
          facebook
        }
      }
      companies {
        id
      }
      shouldShowCongratulation: isFlagEnabled(name: CONGRATULATION)
      subscription {
        planId
      }
      type
    }
  }
`;

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {mixpanel} = useMixpanelContext();
  const {currentAdType, currentCompany} = useCompanyContext();

  const {isNewVersionEnabled} = useFlags();

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER);

  routerHelpers.useCallbackOnReturn(refetch);

  useEffect(() => {
    if (!data) return;
    if (!data.currentUser.currentCompany) return;
    if (!data.currentUser.currentCompany.lastCheckCollectDate) return;

    const {lastChecks} = data.currentUser.currentCompany;
    const groupedLastChecks = checkHelpers.groupChecksByName(lastChecks);

    const errorsCount = checkHelpers.errorsCount(groupedLastChecks);
    const warningsCount = checkHelpers.warningsCount(groupedLastChecks);

    const issues = groupedLastChecks.reduce((acc, v) => {
      // replaceAll is not available in Safari
      const snakeCaseName = v.sensor.name.replace(/-/g, '_');
      acc[snakeCaseName] = v.found;
      return acc;
    }, {});

    mixpanel.track('view_status_page', {
      errors: errorsCount,
      warnings: warningsCount,
      ...issues,
    });
  }, [data, mixpanel]);

  useEffect(() => {
    if (!data) return;

    const {shouldShowCongratulation, subscription} = data.currentUser;
    if (!shouldShowCongratulation) return;
    if (!paymentHelpers.isOneTimePlan(subscription.planId)) return;

    // Do not use {background: location} so that Congratulation is a separate
    // page rendered on its own - it allows to navigate to current page and
    // get up-to-date shouldShowCongratulation value without reloading page
    navigate('/home/main/congratulation');
  }, [navigate, location, data]);

  const [redirectPathLoaded, redirectPath] =
    wizardHelpers.useRedirectToCurrentStep();

  if (!redirectPathLoaded) return <LinearProgress style={{flex: 1}} />;
  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  if (redirectPath) {
    navigate(redirectPath, {replace: true});
    return null;
  }

  const {
    name,
    sensorExclusions,
    tokens,
    isClientAccess,
    isLaunched,
    isLaunchEnabled,
    lastCheckCollectDate,
    lastCheckCreateDate,
    lastChecks,
    regions,
    lastBudgetScores,
  } = data.currentUser.currentCompany;

  if (tokens.length === 0) {
    return (
      <>
        <Header
          name={name}
          userType={data.currentUser.type}
          isClientAccess={isClientAccess}
          tokens={tokens}
        />
        <NoTokens id={data.currentUser.currentCompany.id} name={name} />
      </>
    );
  }

  const isPlanExceeded = paymentHelpers.isPlanExceeded(
    data.currentUser.currentCompany.id,
    data.currentUser.companies,
    data.currentUser.subscription.planId,
  );

  if (!lastCheckCollectDate || isPlanExceeded) {
    navigate('/home/main/wait');
    return null;
  }

  const connectedTokens = connectHelpers.connectedTokens(tokens);
  const isAdTypeConnected = adTypeHelpers.isAdTypeConnected(
    connectedTokens,
    currentAdType,
  );

  const groupedLastChecks = checkHelpers.groupChecksByName(lastChecks);

  const budgetScore =
    lastBudgetScores.length === 0
      ? null
      : lastBudgetScores.find(v => v.adType === currentAdType).value;

  const handleLaunch = () => {
    refetch();
  };

  const breadcrumbs = [
    {
      href: ROUTES.HOME_NEW,
      title: <Trans>Проекты</Trans>,
    },
    {
      href: '',
      title: <Trans>{name}</Trans>,
    },
  ];

  return isNewVersionEnabled ? (
    <>
      <Box mt={12} mb={10}>
        <Breadcrumbs items={breadcrumbs} />
      </Box>

      <HeaderNew
        name={name}
        lastCheckCreateDate={lastCheckCreateDate}
        companyId={currentCompany.id}
        isLaunchEnabled={isLaunchEnabled}
        onLaunch={handleLaunch}
        isLaunched={isLaunched}
      />

      {isAdTypeConnected && <DashboardNew />}

      <Box mt={12}>
        <Indicators />
      </Box>

      {isAdTypeConnected && groupedLastChecks.length > 0 && (
        <>
          {budgetScore !== null && (
            <Box mb={3}>
              <PerformanceNew
                budgetScore={budgetScore}
                isLaunched={isLaunched}
                lastCheckCreateDate={lastCheckCreateDate}
                lastChecks={groupedLastChecks}
              />
            </Box>
          )}

          <IssuesNew
            lastChecks={groupedLastChecks}
            regions={regions}
            sensorExclusions={sensorExclusions}
          />
        </>
      )}
    </>
  ) : (
    <>
      <Header
        name={name}
        userType={data.currentUser.type}
        isClientAccess={isClientAccess}
        tokens={tokens}
      />
      {isAdTypeConnected && <Dashboard />}
      {isAdTypeConnected && groupedLastChecks.length > 0 && (
        <>
          {budgetScore !== null && (
            <Box mb={3}>
              <Performance
                budgetScore={budgetScore}
                isLaunchEnabled={isLaunchEnabled}
                isLaunched={isLaunched}
                lastCheckCreateDate={lastCheckCreateDate}
                lastChecks={groupedLastChecks}
                onLaunch={handleLaunch}
              />
            </Box>
          )}

          <Issues
            lastChecks={groupedLastChecks}
            regions={regions}
            sensorExclusions={sensorExclusions}
          />
        </>
      )}
    </>
  );
};

export default Main;
