/** @format */

import {useCallback, useState} from 'react';

import {Box, Grid, Tooltip, Typography, useMediaQuery} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {HelpOutline} from '@material-ui/icons';
import {Trans, useTranslation} from 'react-i18next';
import {clsx} from 'clsx';

import PlanFactPie from 'components/graphs/PlanFactPie';
import {
  GOOD_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/mediaplanBreakpoints';
import numberHelpers from 'helpers/numberHelpers';

const useStyles = makeStyles(theme => ({
  title: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 'normal',
    marginBottom: theme.spacing(1),
  },
  hint: {
    color: '#9F9F9F',
    fontSize: 13,
    textTransform: 'capitalize',
    fontWeight: 600,
  },
  value: {
    color: '#DFDFDF',
    fontSize: 16,
    lineHeight: '24px',
  },
  overspend: {
    color: '#CE5959',
    fontSize: 13,
    lineHeight: '16px',
    paddingTop: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
  },
  main: {
    width: 'calc(100% - 4px)',
    borderRadius: theme.spacing(2),
    backgroundColor: '#202020',
    padding: theme.spacing(4),
    columnGap: theme.spacing(4),
  },
  plannedValue: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#DFDFDF',
  },
  remainder: {
    marginTop: theme.spacing(3),
    fontSize: 14,
    color: '#677C41',
  },
  help: {
    width: 12,
    height: 12,
    color: '#777',
    marginLeft: theme.spacing(1.5),
  },
  good: {
    color: '#677C41',
  },
  unoptimal: {
    color: '#AA7F53',
  },
  bad: {
    color: '#CE8080',
  },
}));

const Spent = props => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const toggleTooltip = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const getClassNameByPlan = useCallback(
    (value, plannedValue) => {
      const diff = plannedValue - value;

      const percentage = (diff / plannedValue) * 100;

      if (percentage >= GOOD_BREAKPOINT) {
        return classes.good;
      }

      if (percentage >= UNOPTIMAL_BREAKPOINT) {
        return classes.unoptimal;
      }

      return classes.bad;
    },
    [classes],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {t, i18n} = useTranslation();
  const {currency} = props.platformStat;
  const currencyFormatter = numberHelpers.buildFormatter(
    currency,
    i18n.language,
  );

  const renderCell = (title, statsName, mediaplanName) => {
    const value = props.platformStat[statsName];
    let diff = null;
    let formattedDiff = null;
    let formattedPlannedValue = null;
    let plannedValue = null;

    if (props.mediaplan) {
      plannedValue = props.mediaplan[mediaplanName];
      diff = plannedValue - value;
      formattedDiff = currencyFormatter.format(Math.abs(diff));
      formattedPlannedValue = currencyFormatter.format(plannedValue);
    }
    const formattedValue = currencyFormatter.format(value);

    const remainderClassName = getClassNameByPlan(value, plannedValue);

    return (
      <Box display='flex' alignItems='center' flexWrap={'wrap'} flexGrow='1'>
        <Box display='flex' alignItems='center' className={classes.main}>
          {props.mediaplan ? (
            <PlanFactPie
              hasWarning={!!diff && diff < 0}
              plan={props.mediaplan[mediaplanName]}
              fact={value}
            />
          ) : (
            <PlanFactPie />
          )}
          <Box flexGrow={1}>
            <Box display='flex' flexDirection={'column'} mt={2}>
              <Box mb={3}>
                <Typography className={classes.title}>{title}</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                <Typography className={classes.value}>
                  <strong>{formattedValue}</strong> <Trans>из</Trans>{' '}
                  {formattedPlannedValue || '-'}{' '}
                </Typography>
                {mediaplanName === 'dayly' &&
                  plannedValue &&
                  (isMobile ? (
                    <Tooltip
                      key='mobile-tooltip'
                      open={isOpen}
                      onClick={toggleTooltip}
                      title={
                        <Trans>
                          Средне-математическое значение дневного бюджета,
                          рассчитанное из указанного вами месячного медиаплана
                          для проекта.
                        </Trans>
                      }
                      arrow
                    >
                      <HelpOutline className={classes.help} />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      key='tooltip'
                      title={
                        <Trans>
                          Средне-математическое значение дневного бюджета,
                          рассчитанное из указанного вами месячного медиаплана
                          для проекта.
                        </Trans>
                      }
                      arrow
                    >
                      <HelpOutline className={classes.help} />
                    </Tooltip>
                  ))}
              </Box>
            </Box>
            {diff && diff > 0 && (
              <Typography
                className={clsx(classes.remainder, remainderClassName)}
              >
                <Trans>остаток</Trans>: {formattedDiff}
              </Typography>
            )}
            {((diff && diff < 0) || !plannedValue) && (
              <Typography className={classes.overspend}>
                {plannedValue ? (
                  <Trans>перерасход {{formattedDiff}}</Trans>
                ) : (
                  <Trans>Укажите медиаплан</Trans>
                )}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
      flexGrow={1}
      justifyContent={'space-between'}
    >
      {renderCell(
        t('Расход за месяц', 'Расход за месяц'),
        'monthSpent',
        'monthly',
      )}
      {renderCell(
        t('Расход за прошлые сутки', 'Расход за прошлые сутки'),
        'spent',
        'dayly',
      )}
    </Box>
  );
};

export default Spent;
