/** @format */

import {useState} from 'react';

import {IconButton, InputAdornment} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import LegacyTextField from 'components/inputs/LegacyTextField';

// https://material-ui.com/components/text-fields/#input-adornments
const PasswordField = props => {
  const [showPassword, setShowPassword] = useState(false);

  const {t} = useTranslation();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const imgSrc = showPassword
    ? new URL('./images/eye_open.png', import.meta.url)
    : new URL('./images/eye_closed.png', import.meta.url);

  const placeholder = () => {
    if (props.placeholder == null) {
      return t('Пароль', 'Пароль');
    }

    return props.placeholder;
  };

  return (
    <LegacyTextField
      myProps={{
        placeholderFocused: t('Не менее 3 знаков', 'Не менее 3 знаков'),
        ...props.myProps,
      }}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        // https://github.com/mui-org/material-ui/issues/18556
        autoComplete: 'new-password',
        endAdornment: props.value ? (
          <InputAdornment position='end'>
            <IconButton
              edge='end'
              onClick={handleShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              <img alt='Visibility' height='24' src={imgSrc} width='24' />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      {...props}
      placeholder={placeholder()}
    />
  );
};

export default PasswordField;
