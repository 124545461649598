/** @format */
import {Box, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hint: {
      backgroundColor: theme.palette.background.default,
      color: '#A6A6A6',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
    },
  }),
);

type Props = {
  text: string;
};

const Hint = (props: Props) => {
  const classes = useStyles();

  return (
    <Box mt={1}>
      <Typography className={classes.hint}>{props.text}</Typography>
    </Box>
  );
};

export default Hint;
