/** @format */

import * as R from 'ramda';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PauseOutlinedIcon from '@material-ui/icons/PauseOutlined';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {Box, Grid, Hidden, Typography} from '@material-ui/core';
import {Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import BackHeader from 'components/BackHeader';
import BoxPlate from 'components/plates/BoxPlate';
import OutlinedSmallButton from 'components/buttons/OutlinedSmallButton';
import Platforms from 'components/icons/Platforms';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import Title from 'components/Title';
import checkHelpers from 'helpers/checkHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {useTexts} from 'helpers/sensorsTextsHelpers';
import CheckGraph from 'components/graphs/CheckGraph';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    fontWeight: '600',
    lineHeight: '30px',
  },
  titleText: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 650,
    },
  },
  subtitle: {
    fontSize: 14,
    fontWeight: '700',
    lineHeight: '17px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 240,
    },
  },
  rowTitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '17px',
  },
  rowValue: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '700',
    lineHeight: '17px',
  },
}));

const Header = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = routerHelpers.useSearchParams();
  const name = searchParams.get('name');
  const humanName = searchParams.get('humanName');

  const {t} = useTranslation();
  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();
  const sensorTexts = useTexts();
  const {currentAdType} = useCompanyContext();

  const totalCheck = checkHelpers.sumChecks(props.lastChecks);

  const renderRow = (title, value) => (
    <Box className={classes.row}>
      <Typography className={classes.rowTitle}>{title}</Typography>
      <Typography className={classes.rowValue}>{value}</Typography>
    </Box>
  );

  const renderTitle = () => {
    const sensorPlatforms = R.pipe(
      R.map(v => v.sensor.platform),
      R.uniq,
    )(props.lastChecks);

    return (
      <Title>
        <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
          <Box className={classes.titleText}>{humanName}</Box>
          <Box display='flex' justifyContent='end' alignItems='center'>
            <Typography>
              <Trans>Подходит для систем</Trans>
            </Typography>
            <Platforms platforms={sensorPlatforms} size={20} />
          </Box>
        </Box>
      </Title>
    );
  };

  const handleShowDescription = () => {
    mixpanel.track('open_issue_description_page', {sensor: name});

    navigate(
      {
        pathname: '/home/main/error-description',
        search: `?${searchParams.toString()}`,
      },
      {
        state: {background: location},
      },
    );
  };

  const renderSettingsButton = () => {
    const handleShowSettings = () => {
      mixpanel.track('open_issue_settings_page', {sensor: name});

      navigate({
        pathname: '/home/main/error-settings',
        search: `?${searchParams.toString()}`,
      });
    };

    return (
      <OutlinedSmallButton
        onClick={handleShowSettings}
        style={{marginLeft: 8}}
        endIcon={
          <Hidden xsDown>
            <SettingsOutlinedIcon htmlColor='#6e990f' />
          </Hidden>
        }
      >
        <Hidden xsDown>
          <Trans>Настроить</Trans>
        </Hidden>
        <Hidden smUp>
          <SettingsOutlinedIcon htmlColor='#6e990f' />
        </Hidden>
      </OutlinedSmallButton>
    );
  };

  const renderOnOffButton = () => {
    if (props.sensorExclusion) {
      return (
        <OutlinedSmallButton
          style={{marginLeft: 8}}
          disabled={props.sensorExclusionLoading}
          onClick={props.onDeleteSensorExclusion}
          endIcon={
            <Hidden xsDown>
              <PlayArrowOutlinedIcon htmlColor='#6e990f' />
            </Hidden>
          }
        >
          <Hidden xsDown>
            <Trans>Включить сенсор</Trans>
          </Hidden>
          <Hidden smUp>
            <PlayArrowOutlinedIcon htmlColor='#6e990f' />
          </Hidden>
        </OutlinedSmallButton>
      );
    }

    return (
      <OutlinedSmallButton
        disabled={props.sensorExclusionLoading}
        style={{marginLeft: 8}}
        onClick={props.onCreateSensorExclusion}
        endIcon={
          <Hidden xsDown>
            <PauseOutlinedIcon htmlColor='#6e990f' />
          </Hidden>
        }
      >
        <Hidden xsDown>
          <Trans>Отключить сенсор</Trans>
        </Hidden>
        <Hidden smUp>
          <PauseOutlinedIcon htmlColor='#6e990f' />
        </Hidden>
      </OutlinedSmallButton>
    );
  };

  const handleClickDownload = () => {
    mixpanel.people.increment('open_partial_report_page_count');
    mixpanel.track('open_partial_report_page', {sensor: name});

    navigate(`/home/main/report?sensors=${name}`, {
      state: {background: location},
    });
  };

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <BackHeader />

        <Box>
          <OutlinedSmallButton
            onClick={handleShowDescription}
            endIcon={
              <Hidden xsDown>
                <InfoOutlinedIcon htmlColor='#6e990f' />
              </Hidden>
            }
          >
            <Hidden xsDown>
              <Trans>О сенсоре</Trans>
            </Hidden>
            <Hidden smUp>
              <InfoOutlinedIcon htmlColor='#6e990f' />
            </Hidden>
          </OutlinedSmallButton>
          {renderSettingsButton()}
          {renderOnOffButton()}
        </Box>
      </Box>

      <Box mt={8}>
        {props.sensorExclusion ? (
          <Box display='flex' flexDirection='column'>
            {renderTitle()}
            <Box mt={3}>
              {sensorTexts[props.sensorName].short_info[currentAdType]}
            </Box>
            <Box mt={4}>
              <Typography className={classes.subtitle}>
                <Trans>Сенсор отключен</Trans>
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            {renderTitle()}
            <Box mt={3}>
              {sensorTexts[props.sensorName].short_info[currentAdType]}
            </Box>
          </>
        )}
      </Box>

      {props.sensorName === 'as-strat-low-conv' && (
        <Box mt={6}>
          <BoxPlate>
            <Typography>
              <Trans>
                Сенсор «Обучение автостратегии приостановлено» может работать
                некорректно, если у вас настроены пакетные стратегии в Яндекс
                Директе. Мы работаем над оптимизацией сенсора с учетом пакетных
                стратегий и скоро вернемся со сроками доработок.
              </Trans>
            </Typography>
          </BoxPlate>
        </Box>
      )}

      <Box mt={6}>
        <CheckGraph graphChecks={props.sortedGraphChecks} />
      </Box>

      <Box mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box display='flex' flexDirection='column'>
              {renderRow(
                t('Проверено объектов', 'Проверено объектов'),
                totalCheck.checks,
              )}
              {renderRow(t('Найдено', 'Найдено'), totalCheck.found)}
              {props.reportItems.length > 0 &&
                renderRow(
                  t('Новых за сегодня', 'Новых за сегодня'),
                  totalCheck.new,
                )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='end'>
              {!props.sensorExclusion && (
                <SecondaryMediumButton onClick={handleClickDownload}>
                  <img
                    alt='Download'
                    src={new URL('./images/download.svg', import.meta.url)}
                  />
                  <Box ml={3}>
                    <Trans>Скачать отчет</Trans>
                  </Box>
                </SecondaryMediumButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Header;
