/** @format */

import {Fragment, useMemo, useState} from 'react';

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import type {GridSize} from '@material-ui/core/Grid/Grid';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {DatePicker} from '@material-ui/pickers';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {add, endOfMonth, format, isFuture, parse, startOfMonth} from 'date-fns';
import {gql, useQuery} from '@apollo/client';
import {CSVLink} from 'react-csv';

import AccountStats from './AccountStats';
import CampaignStats from './CampaignStats';
import CheckGraph from './graphs/CheckGraph';
import NativeTabs from 'components/tabs/NativeTabs';
import SecondarySelect from 'components/selects/SecondarySelect';
import constants from 'helpers/constants';
import {sensorName} from 'helpers/sensor_names';
import type {AdType, ExtendedPlatform, GraphCheck, Platform} from 'types';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID!, $startDate: Date!, $endDate: Date!) {
    currentUser {
      company(id: $companyId) {
        errorStats(startDate: $startDate, endDate: $endDate) {
          totalFound
          found
          fixed
          left
          foundFirst
          new
          errorFixRate
          warningFixRate
          usedSensorHumanNames
          topMaxFoundErrorSensors {
            sensorHumanName
            found
          }
          topMaxFoundWarningSensors {
            sensorHumanName
            found
          }
          topMostFrequentErrorSensors {
            sensorHumanName
            days
          }
          topMostFrequentWarningSensors {
            sensorHumanName
            days
          }
        }
        maxMetrics(startDate: $startDate, endDate: $endDate) {
          platform
          maxCampaigns
          maxAds
        }
        lastChecks: checks(date: $endDate) {
          avgDays
          sensor {
            isError
          }
        }
        graphChecks(startDate: $startDate, endDate: $endDate) {
          old
          new
          fixed
          collectedOn
        }
        graphSensorChecks(startDate: $startDate, endDate: $endDate) {
          old
          new
          fixed
          sensorName
          collectedOn
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 100,
      justifyContent: 'center',
      width: '100%',
    },
    infoAlert: {
      color: '#1C88C0',
      lineHeight: '18px',
    },
    divider: {
      backgroundColor: '#999999',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    csvLink: {
      color: theme.palette.text.primary,
    },
  }),
);

type StatsType = 'ACCOUNTS' | 'CAMPAIGNS';

type Sensor = {
  isError: boolean;
};

type Check = {
  avgDays: number;
  sensor: Sensor;
};

type TopMaxFoundSensor = {
  sensorHumanName: string;
  found: number;
};

type TopMostFrequentSensor = {
  sensorHumanName: string;
  days: number;
};

type MaxMetrics = {
  platform: Platform;
  maxCampaigns: number;
  maxAds: number;
};

type Props = {
  adType: AdType;
  companyId: string | null;
  extendedPlatform: ExtendedPlatform;
  platform: Platform;
};

const Graphs = (props: Props) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const now = new Date();
  const yesterday = add(now, {days: -1});

  const [startDate, setStartDate] = useState<Date>(startOfMonth(yesterday));
  const [endDate, setEndDate] = useState<Date>(yesterday);
  const [statsType, setStatsType] = useState<StatsType>('ACCOUNTS');

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {
      companyId: props.companyId,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
  });

  const sortedGraphChecks = useMemo(() => {
    if (!data) return [];

    const {graphChecks} = data.currentUser.company;
    return R.sortBy<GraphCheck>(v => v.collectedOn, graphChecks);
  }, [data]);

  const sortedGraphSensorChecks = useMemo(() => {
    if (!data) return [];

    const {graphSensorChecks} = data.currentUser.company;
    return R.pipe(
      R.sortBy<GraphSensorCheck>(v => v.collectedOn),
      R.filter<GraphSensorCheck>(v => v.old + v.new + v.fixed > 0),
    )(graphSensorChecks);
  }, [data]);

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const renderMonthPicker = () => {
    const handleSelect = (value: string) => {
      const date = parse(value, 'MMMM yyyy', now);
      setStartDate(startOfMonth(date));

      if (isFuture(endOfMonth(date))) {
        setEndDate(yesterday);
      } else {
        setEndDate(endOfMonth(date));
      }
    };

    const options = [
      now,
      add(now, {months: -1}),
      add(now, {months: -2}),
      add(now, {months: -3}),
      add(now, {months: -4}),
      add(now, {months: -5}),
      add(now, {months: -6}),
    ].map(v => {
      const formattedDate = format(v, 'MMMM yyyy');
      return {
        value: formattedDate,
        text: formattedDate,
      };
    });

    return (
      <SecondarySelect
        options={options}
        value={format(startDate, 'MMMM yyyy')}
        onSelect={handleSelect}
      />
    );
  };

  const renderDateRangePicker = () => {
    const handleChangeStartDate = (date: Date | null) => {
      setStartDate(date as Date);
    };

    const handleChangeEndDate = (date: Date | null) => {
      setEndDate(date as Date);
    };

    return (
      <Box display='flex' justifyContent='space-between'>
        <DatePicker
          disableToolbar
          disabled
          format='MM-dd-yyyy'
          margin='normal'
          value={startDate}
          variant='inline'
          onChange={handleChangeStartDate}
        />
        <Box ml={2} />
        <DatePicker
          disableFuture
          disableToolbar
          disabled
          format='MM-dd-yyyy'
          margin='normal'
          value={endDate}
          variant='inline'
          onChange={handleChangeEndDate}
        />
      </Box>
    );
  };

  const generateChecksCSV = () => {
    const header = ['Date', 'Old', 'New', 'Fixed'];
    const rows = sortedGraphChecks.map(v => [
      v.collectedOn,
      v.old,
      v.new,
      v.fixed,
    ]);

    return [header].concat(rows);
  };

  const generateSensorChecksCSV = () => {
    const header = ['Date', 'SensorName', 'SensorHumanName', 'Old', 'New', 'Fixed'];
    const rows = sortedGraphSensorChecks.map(v => [
      v.collectedOn,
      v.sensorName,
      t(sensorName(v.sensorName)),
      v.old,
      v.new,
      v.fixed,
    ]);

    return [header].concat(rows);
  };

  const renderErrorStats = () => {
    const {
      errorStats,
    }: {
      errorStats: {
        totalFound: number;
        found: number;
        fixed: number;
        left: number;
        foundFirst: number;
        new: number;
        errorFixRate: number;
        warningFixRate: number;
        usedSensorHumanNames: string[];
        topMaxFoundErrorSensors: TopMaxFoundSensor[];
        topMaxFoundWarningSensors: TopMaxFoundSensor[];
        topMostFrequentErrorSensors: TopMostFrequentSensor[];
        topMostFrequentWarningSensors: TopMostFrequentSensor[];
      };
    } = data.currentUser.company;

    const renderRow = (
      title: string,
      value: number | null,
      titleXs: GridSize = 6,
    ) => (
      <>
        <Grid item xs={titleXs}>
          <Typography variant='body1'>{title}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant='body1'>{value || '—'}</Typography>
        </Grid>
      </>
    );

    return (
      <>
        <Alert className={classes.infoAlert} variant='outlined' severity='info'>
          Статистику по ошибкам и скорость исправления считаем за указанный
          период по всем платформам для выбранного типа рекламы
        </Alert>

        <Box mt={4}>
          <Grid container spacing={2}>
            {renderRow(
              'Нашли за все время использования',
              errorStats.totalFound,
            )}
            {renderRow('Нашли за период', errorStats.found)}
            {renderRow('Ваш специалист исправил за период', errorStats.fixed)}
            {renderRow('Осталось исправить', errorStats.left)}
            {renderRow(
              'Нашли в первую проверку за период',
              errorStats.foundFirst,
            )}

            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            {renderRow(
              'Скорость исправления ошибок, дней',
              errorStats.errorFixRate,
            )}
            {renderRow(
              'Скорость исправления замечаний, дней',
              errorStats.warningFixRate,
            )}

            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h2'>
                Все найденные ошибки и замечания
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2'>
                {errorStats.usedSensorHumanNames
                  .map((v: string) => `${constants.BULLET} ${v}`)
                  .sort()
                  .join('\n')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h2'>
                ТОП-3 ошибок по количеству инцидентов
              </Typography>
            </Grid>
            {errorStats.topMaxFoundErrorSensors.map((v, i) => (
              <Fragment key={i}>
                {renderRow(v.sensorHumanName, v.found, 10)}
              </Fragment>
            ))}

            <Grid item xs={12}>
              <Box mt={3} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h2'>
                ТОП-3 замечаний по количеству инцидентов
              </Typography>
            </Grid>
            {errorStats.topMaxFoundWarningSensors.map((v, i) => (
              <Fragment key={i}>
                {renderRow(v.sensorHumanName, v.found, 10)}
              </Fragment>
            ))}

            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h2'>
                ТОП-3 ошибок по частоте появления (в днях)
              </Typography>
            </Grid>
            {errorStats.topMostFrequentErrorSensors.map((v, i) => (
              <Fragment key={i}>
                {renderRow(v.sensorHumanName, v.days, 10)}
              </Fragment>
            ))}

            <Grid item xs={12}>
              <Box mt={3} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant='h2'>
                ТОП-3 замечаний по частоте появления (в днях)
              </Typography>
            </Grid>
            {errorStats.topMostFrequentWarningSensors.map((v, i) => (
              <Fragment key={i}>
                {renderRow(v.sensorHumanName, v.days, 10)}
              </Fragment>
            ))}
          </Grid>
        </Box>
      </>
    );
  };

  const renderMetrics = () => {
    const {maxMetrics}: {maxMetrics: MaxMetrics[]} = data.currentUser.company;

    const renderRow = (maxMetrics: MaxMetrics) => (
      <>
        <Grid item xs={12}>
          <Typography variant='h2'>
            {t(`provider_names.${maxMetrics.platform}`)}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='body1'>Max кампаний</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant='body1'>{maxMetrics.maxCampaigns}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='body1'>Max объявлений</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant='body1'>{maxMetrics.maxAds}</Typography>
        </Grid>
      </>
    );

    return (
      <>
        <Box mt={4}>
          <Grid container spacing={2}>
            {maxMetrics.map((v, i) => (
              <Fragment key={i}>
                {renderRow(v)}
                <Grid item xs={12}>
                  <Box mt={3} />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
      </>
    );
  };

  const lastChecks: Check[] = data.currentUser.company.lastChecks;

  const errorsAvgDays = lastChecks
    .filter(v => v.sensor.isError)
    .map(v => v.avgDays)
    .reduce((v, acc) => v + acc, 0);

  const warningsAvgDays = lastChecks
    .filter(v => !v.sensor.isError)
    .map(v => v.avgDays)
    .reduce((v, acc) => v + acc, 0);

  const renderStatsTypeTabs = () => {
    const tabs = [
      {path: '#', name: 'ACCOUNTS', text: 'АККАУНТЫ'},
      {path: '#', name: 'CAMPAIGNS', text: 'КАМПАНИИ'},
    ];

    const handleChange = (value: StatsType) => {
      setStatsType(value);
    };

    return (
      <NativeTabs
        activeTabName={statsType}
        tabs={tabs}
        variant='DARK'
        onChange={handleChange}
      />
    );
  };

  return (
    <>
      <Box mt={6}>{renderMonthPicker()}</Box>
      <Box mt={1}>{renderDateRangePicker()}</Box>

      <Box mt={5}>
        <Typography variant='h1'>Статистика по ошибкам и замечаниям</Typography>
        <Box mt={6}>{renderErrorStats()}</Box>
      </Box>

      <Box mt={5}>
        <Typography variant='h1'>
          Статистика по кампаниям и объявлениям
        </Typography>
        <Box mt={6}>{renderMetrics()}</Box>
      </Box>

      {sortedGraphChecks.length > 0 && (
        <Box mt={10}>
          <Typography variant='h1'>Динамика ошибок и замечаний</Typography>

          <Box mt={6}>
            <Alert
              className={classes.infoAlert}
              variant='outlined'
              severity='info'
            >
              {'Ошибки и замечания показываем всегда по всем платформам и типам ' +
                'рекламы\n\n' +
                'Средний показатель исправления - это средний срок жизни ' +
                'ошибок или исправлений, найденных в последний день выбранного ' +
                'периода'}
            </Alert>
          </Box>

          <Box mt={4}>
            <CSVLink
              className={classes.csvLink}
              data={generateChecksCSV()}
              filename='Динамика ошибок и замечаний.csv'
              target='_blank'
            >
              <Typography variant='body1'>
                Скачать CSV с ошибками и замечаниями
              </Typography>
            </CSVLink>
          </Box>
          <Box mt={4}>
            <CSVLink
              className={classes.csvLink}
              data={generateSensorChecksCSV()}
              filename='Динамика ошибок и замечаний по сенсорам.csv'
              target='_blank'
            >
              <Typography variant='body1'>
                Скачать CSV с ошибками и замечаниями по сенсорам
              </Typography>
            </CSVLink>
          </Box>

          <CheckGraph
            errorsAvgDays={errorsAvgDays}
            graphChecks={sortedGraphChecks}
            warningsAvgDays={warningsAvgDays}
          />

          <Box mt={10}>{renderStatsTypeTabs()}</Box>

          {statsType === 'ACCOUNTS' && (
            <AccountStats
              adType={props.adType}
              companyId={props.companyId}
              endDate={endDate}
              extendedPlatform={props.extendedPlatform}
              startDate={startDate}
            />
          )}

          {statsType === 'CAMPAIGNS' && (
            <CampaignStats
              adType={props.adType}
              companyId={props.companyId}
              endDate={endDate}
              platform={props.platform}
              startDate={startDate}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Graphs;
