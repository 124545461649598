/** @format */

import {useMemo, useState} from 'react';

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {Box, CircularProgress, Typography} from '@material-ui/core';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {add, format} from 'date-fns';
import {gql, useQuery} from '@apollo/client';

import BackHeader from 'components/BackHeader';
import CheckGraph from 'components/graphs/CheckGraph';
import PrimarySelect from 'components/selects/PrimarySelect';
import type {GraphCheck, GraphScore} from 'types';
import {useCompanyContext} from 'contexts/CompanyContext';

import BudgetChecksTable from './BudgetChecksTable';

type MenuValue = '7_DAYS' | '15_DAYS' | '30_DAYS';

type MenuOption = {
  value: MenuValue;
  days: number;
};

const MENU_OPTIONS: MenuOption[] = [
  {value: '7_DAYS', days: 7},
  {value: '15_DAYS', days: 15},
  {value: '30_DAYS', days: 30},
];

const GET_CURRENT_USER = gql`
  query GetCurrentUser($startDate: Date!, $endDate: Date!) {
    currentUser {
      currentCompany {
        uuid
        sensorExclusions {
          sensorName
        }
        graphBudgetScores(startDate: $startDate, endDate: $endDate) {
          collectedOn
          value
        }
        lastChecks {
          sensor {
            name
          }
        }
        graphChecks(startDate: $startDate, endDate: $endDate) {
          old
          new
          fixed
          collectedOn
        }
      }
      uuid
      lang
    }
  }
`;

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 441,
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const Graphs = () => {
  const classes = useStyles();

  const {t} = useTranslation();

  const {currentAdType} = useCompanyContext();

  const [menuOption, setMenuOption] = useState(MENU_OPTIONS[0]);

  const now = new Date();
  const startDate = add(now, {days: -(menuOption.days - 1)});
  const endDate = now;

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
  });

  const sortedGraphChecks = useMemo(() => {
    if (!data) return [];

    const {graphChecks} = data.currentUser.currentCompany;
    return R.sortBy<GraphCheck>(v => v.collectedOn, graphChecks);
  }, [data]);

  const sortedBudgetScoreChecks = useMemo(() => {
    if (!data) return [];

    const {graphBudgetScores} = data.currentUser.currentCompany;
    const byCollectedOn = R.descend<GraphScore>(R.prop('collectedOn'));

    return R.sort(byCollectedOn, graphBudgetScores);
  }, [data]);

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const handleSelectPeriod = (value: string) => {
    const newMenuOption = MENU_OPTIONS.find(v => v.value === value);
    setMenuOption(newMenuOption as MenuOption);
  };

  const daysOptions = MENU_OPTIONS.map(v => ({
    value: v.value,
    text: t('За {{daysCount}} дней', {daysCount: v.days}),
  }));

  const {uuid, sensorExclusions, lastChecks} = data.currentUser.currentCompany;

  return (
    <>
      <Box mb={3}>
        <BackHeader />
      </Box>

      <Box
        className={classes.titleContainer}
        alignItems='baseline'
        display='flex'
      >
        <Typography variant='h1'>
          <Trans>Динамика показателей</Trans>
        </Typography>
        <Box ml={5}>
          <PrimarySelect
            options={daysOptions}
            value={menuOption.value}
            onSelect={handleSelectPeriod}
          />
        </Box>
      </Box>

      <Box mt={5}>
        <Typography variant='h2'>
          <Trans>Срабатывание сенсоров</Trans>
        </Typography>
        <CheckGraph graphChecks={sortedGraphChecks} />
      </Box>
      <BudgetChecksTable
        uuid={uuid}
        userUuid={data.currentUser.uuid}
        lang={data.currentUser.lang}
        sensorExclusions={sensorExclusions}
        lastChecks={lastChecks}
        budgetScoreChecks={sortedBudgetScoreChecks}
        graphChecks={sortedGraphChecks}
        currentAdType={currentAdType}
      />
    </>
  );
};

export default Graphs;
