/** @format */

import Alert from '@material-ui/lab/Alert';
import {Box, Grid, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';

import JuliaBot from 'components/managers/JuliaBot';
import Loading from 'components/loading/Loading';
import ModalPage from 'components/ModalPage';
import PaymentMethod from 'pages/Home/Settings/PaymentMethods/PaymentMethod';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import numberHelpers from 'helpers/numberHelpers';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      budget
      paymentMethods {
        id
        paymentSystem
        last4
        isExpired
      }
    }
  }
`;

const CHARGE_POSTPAY_BILL = gql`
  mutation ChargePostpayBill($paymentMethodId: Int!) {
    chargePostpayBill(paymentMethodId: $paymentMethodId) {
      confirmationUrl
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    budgetText: {
      color: '#4A4A4A',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
    body: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
);

const ConfirmPostpayBudget = () => {
  const classes = useStyles();

  const {t} = useTranslation();

  const {loading, error, data} = useQuery(GET_CURRENT_USER);
  const [chargePostpayBill, {loading: chargePostpayBillLoading}] =
    useMutation(CHARGE_POSTPAY_BILL);

  if (loading) return <Loading />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {budget, paymentMethods} = data.currentUser;

  const activePaymentMethod = paymentMethods.find(
    (v: PaymentMethod) => !v.isExpired,
  );

  const renderButton = () => {
    const handleChargePostpayBill = async () => {
      const paymentMethodId = activePaymentMethod ? activePaymentMethod.id : -1;

      const {data: chargePostpayBillData} = await chargePostpayBill({
        variables: {paymentMethodId},
      });

      const {confirmationUrl} = chargePostpayBillData.chargePostpayBill;
      window.location.href = confirmationUrl;
    };

    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <PrimaryMediumButton
            disabled={chargePostpayBillLoading}
            fullWidth
            onClick={handleChargePostpayBill}
          >
            <Trans>Оплатить</Trans>
          </PrimaryMediumButton>
        </Grid>
      </Grid>
    );
  };

  const formattedBudget = numberHelpers.formatRUB(budget);
  return (
    <ModalPage
      header={t(
        'Оплата подписки за завершенный период использования',
        'Оплата подписки за завершенный период использования',
      )}
    >
      <Box mt={3}>
        <Typography className={classes.budgetText}>
          <Trans>Ваш бюджет {{formattedBudget}}</Trans>
        </Typography>
      </Box>

      <Box mt={10}>
        <Typography className={classes.body}>
          <Trans>
            Если возникли трудности с оплатой, свяжитесь с менеджером AdSensor
          </Trans>
        </Typography>
        <Box mt={3}>
          <JuliaBot buttonVariant='SECONDARY' variant='LIGHT' />
        </Box>
      </Box>

      <Box mt={10}>
        {activePaymentMethod && (
          <Box mb={3}>
            <PaymentMethod
              paymentMethod={activePaymentMethod}
              variant='LIGHT'
            />
          </Box>
        )}

        {renderButton()}
      </Box>
    </ModalPage>
  );
};

export default ConfirmPostpayBudget;
