/** @format */

import {useMemo} from 'react';

import {clsx} from 'clsx';
import * as R from 'ramda';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  Typography,
  Link,
} from '@material-ui/core';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {Trans} from 'react-i18next';
import {format, parseISO} from 'date-fns';

import type {Check, GraphCheck, GraphScore, SensorExclusion} from 'types';
import reportHelpers from 'helpers/reportHelpers';
import adTypeHelpers from 'helpers/adTypeHelpers';
import TransparentSmallButton from 'components/buttons/TransparentSmallButton';
import BudgetScoreStatus from 'components/BudgetScoreStatus';
import {
  GOOD_BREAKPOINT,
  ALMOST_GOOD_BREAKPOINT,
  NORMAL_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/budgetScoreBreakpoints';

import downloadSrc from './images/download.svg';
export const GOOD_TEXT = 'Без замечаний';
export const NORMAL_TEXT = 'Почти без замечаний';
export const UNOPTIMAL_TEXT = 'Не оптимально';
export const BAD_TEXT = 'Не эффективно';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tableContainer: {
      backgroundColor: '#202020',
      padding: `0 ${theme.spacing(4)}px`,
    },
    headerCell: {
      fontWeight: 700,
      borderBottom: '1px dashed #4B4B4B',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
    lightRow: {
      backgroundColor: '#242424',
      borderRadius: 8,
    },
    cell: {
      borderBottom: 'none',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
    statusText: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    downloadButton: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 30,
        width: 30,
        boxSizing: 'border-box',
      },
    },
    downloadIcon: {
      width: 18,
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    downloadText: {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 441,
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

interface Props {
  budgetScoreChecks: GraphScore[];
  graphChecks: GraphCheck[];
  uuid: string;
  userUuid: string;
  sensorExclusions: SensorExclusion[];
  lastChecks: Check[];
  lang: string;
  currentAdType: string;
}

const BudgetChecksTable = (props: Props) => {
  const classes = useStyles();

  const {
    budgetScoreChecks,
    graphChecks,
    uuid,
    userUuid,
    sensorExclusions,
    lastChecks,
    lang,
    currentAdType,
  } = props;

  const statusText = (value: number) => {
    if (value === GOOD_BREAKPOINT) {
      return (
        <span className={classes.statusText}>
          <Trans>{GOOD_TEXT}</Trans>
        </span>
      );
    }

    if (value > NORMAL_BREAKPOINT && value <= ALMOST_GOOD_BREAKPOINT) {
      return (
        <span className={classes.statusText}>
          <Trans>{NORMAL_TEXT}</Trans>
        </span>
      );
    }

    if (value >= UNOPTIMAL_BREAKPOINT && value <= NORMAL_BREAKPOINT) {
      return (
        <span className={classes.statusText}>
          <Trans>{UNOPTIMAL_TEXT}</Trans>
        </span>
      );
    }

    return (
      <span className={classes.statusText}>
        <Trans>{BAD_TEXT}</Trans>
      </span>
    );
  };

  const renderLink = (linkDate: string) => {
    const allSensors = R.pipe(
      R.map((v: Check) => v.sensor.name),
      R.without(sensorExclusions.map((v: SensorExclusion) => v.sensorName)),
    )(lastChecks);

    const platforms = adTypeHelpers
      .platformsFromAdType(currentAdType)
      .join(',');

    const url = reportHelpers.reportLink(
      userUuid,
      uuid,
      linkDate,
      allSensors,
      platforms,
      'xlsx',
      lang,
    );

    return (
      <Box alignItems='center' display='flex'>
        <Box>
          <TransparentSmallButton
            className={classes.downloadButton}
            component={Link}
            href={url}
            target='_blank'
          >
            <img
              className={classes.downloadIcon}
              alt='Icon'
              src={downloadSrc}
            />
            <Typography className={classes.downloadText}>
              <Trans>Скачать отчёт</Trans>
            </Typography>
          </TransparentSmallButton>
        </Box>
      </Box>
    );
  };

  const graphChecksByDate = useMemo(() => {
    if (!graphChecks) return {};

    return R.groupBy(x => x.collectedOn, graphChecks);
  }, [graphChecks]);

  return (
    <Box mt={10}>
      <Typography variant='h2'>
        <Trans>Оценка эффективности и отчеты</Trans>
      </Typography>
      <Box mt={4}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} align='left'>
                  <Trans>Дата</Trans>
                </TableCell>
                <TableCell className={classes.headerCell} align='left'>
                  <Trans>Оценка</Trans>
                </TableCell>
                <TableCell className={classes.headerCell} align='left'>
                  <Trans>Замечания</Trans>
                </TableCell>
                <TableCell className={classes.headerCell} align='left'>
                  <Trans>Отчет</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetScoreChecks.map((item, index) => {
                const graphValues = graphChecksByDate[item.collectedOn];
                if (!graphValues) {
                  return null;
                }
                const graphData = graphValues[0];

                const warningsCount = graphData.new + graphData.old;
                return (
                  <TableRow
                    key={item.collectedOn}
                    className={clsx((index + 1) % 2 === 0 && classes.lightRow)}
                  >
                    <TableCell className={classes.cell}>
                      {format(parseISO(item.collectedOn), 'dd.MM')}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Box
                        display='flex'
                        alignItems='center'
                        gridColumnGap={16}
                      >
                        <BudgetScoreStatus
                          budgetScore={item.value}
                          isWithoutBackground
                        />

                        {statusText(item.value)}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {warningsCount}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {renderLink(item.collectedOn)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default BudgetChecksTable;
