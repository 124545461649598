/** @format */

import {Box, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans} from 'react-i18next';

import JuliaBot from 'components/managers/JuliaBot';
import Anna from 'components/managers/Anna';
import routerHelpers from 'helpers/routerHelpers';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    body: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: '27px',
    },
  }),
);

const Manager = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.body}>
        <Trans>
          Для продолжения работы с AdSensor напишите вашему персональному
          менеджеру
        </Trans>
      </Typography>

      <Box mt={8}>
        {routerHelpers.IsRuVersion() && (<JuliaBot />)}
        {!routerHelpers.IsRuVersion() && (<Anna />)}
      </Box>
    </>
  );
};

export default Manager;
