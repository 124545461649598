/** @format */

import {Trans} from 'react-i18next';
import {Typography} from '@material-ui/core';

import Modal from 'components/Modal';

interface Props {
  onClose: () => void;
}

const DemoModal = (props: Props) => {
  return (
    <Modal
      header={<Trans>Вы находитесь в демо</Trans>}
      showCloseIcon
      onClose={props.onClose}
    >
      <Typography variant='body1'>
        <Trans>
          Чтобы воспользоваться полным функционалом, обратитесь к менеджеру AdSensor
        </Trans>
      </Typography>
    </Modal>
  );
};

export default DemoModal;
