/** @format */

import {useEffect, useMemo} from 'react';

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {Box, CircularProgress, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {format} from 'date-fns';
import {gql, useQuery} from '@apollo/client';

import type {AdType, Campaign, CampaignGraphStat, Platform} from 'types';
import {CSVLink} from 'react-csv';

const GET_CURRENT_USER = gql`
  query GetCurrentUser($companyId: ID!, $startDate: Date!, $endDate: Date!) {
    currentUser {
      company(id: $companyId) {
        campaignGraphStats(startDate: $startDate, endDate: $endDate) {
          account
          campaignId
          platform
          extendedPlatform
          date
          spent
          impressions
          clicks
          cpc
          ctr
        }
        campaigns(startDate: $startDate, endDate: $endDate) {
          platform
          id
          name
        }
        affectedCampaigns(startDate: $startDate, endDate: $endDate) {
          campaignID
          date
          adType
          platform
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      alignItems: 'center',
      display: 'flex',
      height: 100,
      justifyContent: 'center',
      width: '100%',
    },
    csvLink: {
      color: theme.palette.text.primary,
    },
  }),
);

interface Props {
  adType: AdType;
  companyId: string;
  endDate: Date;
  platform: Platform;
  startDate: Date;
}

interface AffectedCampaign {
  campaignID: string;
  date: string;
  adType: AdType;
  platform: Platform;
}

const CampaignStats = (props: Props) => {
  const classes = useStyles();

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER, {
    variables: {
      companyId: props.companyId,
      startDate: format(props.startDate, 'yyyy-MM-dd'),
      endDate: format(props.endDate, 'yyyy-MM-dd'),
    },
  });

  useEffect(() => {
    refetch();
  }, [props.adType, refetch]);

  const campaignsByIdMap = useMemo(() => {
    if (!data) return {};
    const {campaigns} = data.currentUser.company;

    return R.reduce(
      (acc, v) => {
        acc[v.id] = v;
        return acc;
      },
      {} as {[key: string]: Campaign},
      campaigns,
    );
  }, [data]);

  const affectedCampaignsById = useMemo(() => {
    if (!data) return {};

    const {affectedCampaigns} = data.currentUser.company;

    return R.pipe(
      R.filter<AffectedCampaign>(v => v.adType === props.adType),
      R.groupBy<AffectedCampaign>(v => v.campaignID),
    )(affectedCampaigns);
  }, [props.adType, data]);

  if (loading) {
    return (
      <Box className={classes.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const generateCampaignGraphStatsCSV = () => {
    const {campaignGraphStats}: {campaignGraphStats: CampaignGraphStat[]} =
      data.currentUser.company;

    const header = [
      'Platform',
      'Date',
      'Account Name',
      'Campaign ID',
      'Campaign Name',
      'Spent',
      'Impressions',
      'Clicks',
      'CPC',
      'CTR',
      'Has issue'
    ];

    const hasIssue = (campaignId: string, date: string): boolean => {
      const campaigns = affectedCampaignsById[campaignId];
      if (campaigns == null) return false;

      return R.any<AffectedCampaign>(v => (v.date === date))(campaigns);
    };

    const rows = campaignGraphStats.map(v => [
      v.platform,
      v.date,
      v.account,
      v.campaignId,
      campaignsByIdMap[v.campaignId].name,
      v.spent,
      v.impressions,
      v.clicks,
      v.cpc,
      v.ctr,
      hasIssue(v.campaignId, v.date),
    ]);

    return R.concat([header], rows);
  };

  return (
    <>
      <Box mt={10}>
        <CSVLink
          className={classes.csvLink}
          data={generateCampaignGraphStatsCSV()}
          filename='Расход и метрики.csv'
          target='_blank'
        >
          <Typography variant='body1'>
            Скачать CSV с расходом и метриками
          </Typography>
        </CSVLink>
      </Box>
    </>
  );
};

export default CampaignStats;
