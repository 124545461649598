/** @format */

import {ROUTES} from 'constants/ROUTES';

import {Route, Routes, useLocation} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import {Location} from 'history';

import AuthLayout from 'layouts/AuthLayout';
import GuestWizardLayout from 'layouts/GuestWizardLayout';
import Help from 'pages/Wizard/Help';
import Modals from 'pages/Wizard/Modals';
import PrivateRoute from 'components/PrivateRoute';
import theme from 'theme';
import whiteTheme from 'white_theme';
import {Connect} from 'pages/Wizard/Connect';

import Finish from './Finish';
import Welcome from './Welcome';

interface LocationState {
  background: Location | undefined;
}

const Guest = () => {
  const location = useLocation<LocationState>();

  if (location.pathname.includes('welcome')) {
    return (
      <ThemeProvider theme={whiteTheme}>
        <AuthLayout>
          <Routes location={location}>
            <Route path={ROUTES.WELCOME_GUEST_UUID} element={<Welcome />} />
          </Routes>
        </AuthLayout>
      </ThemeProvider>
    );
  }

  const background = location.state && location.state.background;

  return (
    <ThemeProvider theme={theme}>
      <GuestWizardLayout>
        <Routes location={background || location}>
          <Route
            path={ROUTES.GUEST_CONNECT}
            element={
              <PrivateRoute>
                <Connect />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.GUEST_FINISH}
            element={
              <PrivateRoute>
                <Finish />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.GUEST_HELP}
            element={
              <PrivateRoute>
                <Help />
              </PrivateRoute>
            }
          />
        </Routes>

        {background && (
          <Routes>
            <Route
              path={`${ROUTES.GUEST_BEFORE_CONNECT}/*`}
              element={
                <PrivateRoute>
                  <Modals.BeforeConnect />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.GUEST_CONFIRM_DISCONNECT}/*`}
              element={
                <PrivateRoute>
                  <Modals.BeforeConnect />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.GUEST_SUBACCOUNTS}/*`}
              element={
                <PrivateRoute>
                  <Modals.Subaccounts />
                </PrivateRoute>
              }
            />
          </Routes>
        )}
      </GuestWizardLayout>
    </ThemeProvider>
  );
};

export default Guest;
