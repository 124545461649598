/** @format */

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Box, createStyles, Typography} from '@material-ui/core';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';

const useStyles = makeStyles(theme =>
  createStyles({
    subtitle: {
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    link: {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
      textDecoration: 'underline',
    },
  }),
);

const ResetPasswordSent = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClickBack = () => {
    navigate('/auth/sign-in-email');
  };

  return (
    <>
      <Box mt={4}>
        <Typography variant='h1'>
          <Trans>Восстановление пароля</Trans>
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography className={classes.subtitle}>
          <Trans>Новый пароль отправлен на вашу электронную почту.</Trans>
        </Typography>
      </Box>

      <Box mt={4}>
        <PrimaryLargeButton
          color='primary'
          variant='contained'
          component={RouterLink}
          to={'/auth/sign-in-email'}
          fullWidth
          endIcon={<ArrowForwardIcon />}
          onClick={handleClickBack}
        >
          <Trans>Перейти к авторизаци</Trans>
        </PrimaryLargeButton>
      </Box>
    </>
  );
};

export default ResetPasswordSent;
