/** @format */
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

interface Props {
  children: React.ReactChildren | React.ReactChild
}

const useStyles = makeStyles(_ => ({
  infoText: {
    color: '#8A877D',
    whiteSpace: 'pre-line',
  }
}));

const BoxPlateText = (props: Props) => {
  const styles = useStyles();
  return (
    <Typography className={styles.infoText} variant='body1'>
      {props.children}
    </Typography>
  );
};

export default BoxPlateText;
