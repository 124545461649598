/** @format */

import type {CheckboxProps} from '@material-ui/core/Checkbox';
import {Checkbox} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

// https://stackoverflow.com/a/57982412/3632318
const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      color: '#73A011',
      '&$checked': {
        color: '#73A011',
        '& .MuiIconButton-label': {
          position: 'relative',
          zIndex: 0,
        },
        '& .MuiIconButton-label:after': {
          backgroundColor: '#FFFFFF',
          content: '""',
          height: 15,
          left: 4,
          position: 'absolute',
          top: 4,
          width: 15,
          zIndex: -1,
        },
      },
    },
    checked: {},
  }),
);

const GreenCheckbox = (props: CheckboxProps) => {
  const classes = useStyles();
  return (
    <Checkbox
      classes={{root: classes.root, checked: classes.checked}}
      color='default'
      {...props}
    />
  );
};

export default GreenCheckbox;
