/** @format */

import {useState} from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Box, Link, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import {Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';

import EmailFieldFilled from 'components/inputs/EmailFieldFilled';
import OutlinedLargeButton from 'components/buttons/OutlinedLargeButton';
import PasswordFieldFilled from 'components/inputs/PasswordFieldFilled';
import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';
import routerHelpers from 'helpers/routerHelpers';
import {useAuthContext} from 'contexts/AuthContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signInWithEmail(email: $email, password: $password) {
      user {
        email
        has2fa
      }
      authToken {
        token
        type
        user {
          id
          uuid
          email
          roles
          type
          budget
          insertedAt
          profile {
            name
          }
          firstCompany {
            id
            uuid
            name
            tokens {
              id
              provider
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(_theme => ({
  link: {
    color: '#1380FF',
    fontSize: 16,
    fontWeight: '500',
    lineHeight: '19px',
  },
}));

const SignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const passwordReset = searchParams.get('passwordReset');

  const authContext = useAuthContext();
  const {mixpanel} = useMixpanelContext();

  const classes = useStyles();

  const [state, setState] = useState({email: '', password: ''});
  const [signInError, setSignInError] = useState('');

  const [signIn, {loading}] = useMutation(SIGN_IN);

  const handleChange = event => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleSubmit = async event => {
    mixpanel.track('click_sign_in_button');
    event.preventDefault();

    // https://stackoverflow.com/questions/59465864
    try {
      const {email, password} = state;
      const {data} = await signIn({
        // NumberFormat adds trailing spaces
        variables: {email: email.trim(), password},
      });

      // authToken can be not null in case of admin login
      if (data.signInWithEmail.user.has2fa && !data.signInWithEmail.authToken) {
        navigate('/auth/verify-code', location.state);
      } else {
        await authContext.signIn(data.signInWithEmail.authToken);
        const {from} = location.state || {from: {pathname: '/home/main'}};
        navigate(from, {replace: true});
      }
    } catch (e) {
      setSignInError(e.message);
    }
  };

  const handleClickResetPassword = () => {
    navigate('/auth/reset-password');
  };

  // Add `type='button'` to Link component (it uses button as a root element)
  // because `type='submit'` is used by default => pressing Enter inside any
  // form input makes this button pressed inside of actual submit button
  return (
    <>
      <Typography variant='h1'>
        <Trans>Авторизация</Trans>
      </Typography>
      <form onSubmit={handleSubmit}>
        {passwordReset && (
          <Box mt={6}>
            <Typography align='center' className={classes.subtitle}>
              <Trans>
                На ваш email было отправлено письмо с паролем для входа.
              </Trans>
            </Typography>
          </Box>
        )}

        <Box mt={9}>
          <EmailFieldFilled
            error={Boolean(signInError)}
            helperText={signInError}
            value={state.email}
            inputProps={{name: 'email'}}
            fullWidth
            onChange={handleChange}
          />
        </Box>

        <Box mt={3}>
          <PasswordFieldFilled
            value={state.password}
            error={Boolean(signInError)}
            inputProps={{name: 'password'}}
            onChange={handleChange}
            onResetPassword={handleClickResetPassword}
          />
        </Box>

        <Box
          mt={3}
          display='flex'
          flexWrap='wrap'
          justifyContent='space-between'
        >
          {routerHelpers.IsRuVersion() && (
            <Box>
              <Link to='/auth/sign-in' component={RouterLink}>
                <Typography className={classes.link}>
                  <Trans>Вход по номеру телефона</Trans>
                </Typography>
              </Link>
            </Box>
          )}
          <Box>
            <Link to='/auth/reset-password-email' component={RouterLink}>
              <Typography className={classes.link}>
                <Trans>Не помню пароль</Trans>
              </Typography>
            </Link>
          </Box>
        </Box>

        <Box mt={5}>
          <PrimaryLargeButton
            color='primary'
            disabled={loading || !state.email || !state.password}
            type='submit'
            variant='contained'
            fullWidth
            endIcon={<ArrowForwardIcon />}
          >
            <Trans>Войти</Trans>
          </PrimaryLargeButton>
        </Box>
        <Box mt={2}>
          <OutlinedLargeButton
            component={RouterLink}
            to='/auth/sign-up'
            fullWidth
          >
            <Trans>Регистрация</Trans>
          </OutlinedLargeButton>
        </Box>
      </form>
    </>
  );
};

export default SignIn;
