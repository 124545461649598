/** @format */

import {Routes, Route} from 'react-router-dom';

import BaseLayout from 'layouts/BaseLayout';
import PrivateRoute from 'components/PrivateRoute';

import {ROUTES} from 'constants/ROUTES';

import Stats from './Stats';

const Admin = () => {
  return (
    <BaseLayout>
      <Routes>
        <Route
          index
          path={ROUTES.ADMIN_STATS}
          element={
            <PrivateRoute>
              <Stats />
            </PrivateRoute>
          }
        />
      </Routes>
    </BaseLayout>
  );
};

export default Admin;
