/** @format */
import {ChangeEvent} from 'react';

import {Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import MediumTextField from 'components/inputs/MediumTextField';
import imageHelpers from 'helpers/imageHelpers';
import type {Provider} from 'types';

type Props = {
  provider: Provider;
  value?: number;
  onChange(provider: Provider, value: number): void;
};

const FormRow = (props: Props) => {
  const {t} = useTranslation();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    props.onChange(props.provider, Number(value));
  };

  return (
    <Box display='flex' mt={4}>
      <Box
        border='1px solid #DADADA'
        borderColor='#DADADA'
        borderRadius={24}
        height={50}
        mr={3}
        width={50}
      >
        <img
          alt='Logo'
          height='48'
          src={imageHelpers.providerIconSrc(props.provider, 64)}
          width='48'
        />
      </Box>
      <MediumTextField
        placeholder={t('Сумма в месяц', 'Сумма в месяц')}
        value={props.value || ''}
        type='number'
        aria-label={`mediaplan-form-row-${props.provider}`}
        onChange={onChange}
      />
    </Box>
  );
};

export default FormRow;
