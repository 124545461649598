/** @format */

import {useEffect, useState} from 'react';

import {clsx} from 'clsx';
import {
  Box,
  Grow,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CloseIcon from '@material-ui/icons/Close';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import {FormatAlignJustify} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      alignSelf: 'stretch',
      backgroundColor: theme.palette.action.disabledBackground,
      borderBottom: '1px solid #000000',
      display: 'flex',
      paddingBottom: (props: Props) => (props.iconName ? 0 : theme.spacing(3)),
      paddingTop: (props: Props) => (props.iconName ? 0 : theme.spacing(3)),
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(3),
        paddingRight: props => (props.iconName ? 0 : theme.spacing(10)),
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
    },
    containerNew: {
      alignSelf: 'stretch',
      background: '#202020',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    },
    containerNewYellow: {
      background: '#AA8258',
    },
    containerNewRed: {
      background: '#AE5658',
    },
    text: {
      fontWeight: 400,
      whiteSpace: 'pre-line',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        lineHeight: '14px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '20px',
        marginLeft: theme.spacing(2),
      },
    },
    icon: {
      [theme.breakpoints.down('xs')]: {
        alignSelf: 'end',
      },
    },
    closeIcon: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    closeButtonNew: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '46px',
      height: '46px',
      marginLeft: 'auto',
      borderRadius: '8px',
      border: '1px solid #FFF',
      [theme.breakpoints.down('md')]: {
        marginLeft: '14px',
      },
    },
    closeButtonDemo: {
      marginLeft: '16px',
    },
    contactButtonNew: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
      borderRadius: '8px',
      border: '1px solid #FFF',
      color: '#FFF',
      fontSize: ' 14px',
      fontWeight: 400,
      lineHeight: 'normal',
      [theme.breakpoints.down('md')]: {
        marginLeft: '14px',
      },
      [theme.breakpoints.down('xs')]: {
        flexGrow: 1,
        marginLeft: '0',
      },
    },
    contactIcon: {
      marginRight: '10px',
    },
    titleNew: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    textNew: {
      marginTop: '8px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    leftIconNew: {
      marginRight: '16px',
    },
    innerNew: {
      width: '936px',
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      padding: '16px 0',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        justifyContent: 'center',
        padding: '16px',
      },
    },
    innerDemo: {
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    demoButtonsContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '16px',
        justifyContent: 'flex-end',
      },
    },
  }),
);

interface Props {
  errorType?: 'is_demo' | 'sensor_incorrect';
  iconName?: string;
  title?: string | null;
  text: string;
  onClose: () => void;
}

const ClosableBanner = (props: Props) => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);

  const {isNewVersionEnabled} = useFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setTimeout(() => setOpen(true), 100);
  }, []);

  const renderIcon = () => {
    if (!props.iconName) return null;

    const iconSrc = isMobile
      ? new URL(`./images/${props.iconName}_mobile.svg`, import.meta.url)
      : new URL(`./images/${props.iconName}_desktop.svg`, import.meta.url);

    return <img alt='Icon' className={classes.icon} src={iconSrc.toString()} />;
  };

  // @@TODO hidden until Demo Account Flag is ready
  // const onContact = () => {};

  return isNewVersionEnabled ? (
    <Grow in={open}>
      <Box
        className={clsx(
          classes.containerNew,
          props.errorType === 'sensor_incorrect' && classes.containerNewYellow,
          props.errorType === 'is_demo' && classes.containerNewRed,
        )}
      >
        <Box
          className={clsx(
            classes.innerNew,
            props.errorType === 'is_demo' && classes.innerDemo,
          )}
        >
          <Box display={'flex'} alignItems={'center'}>
            {props.errorType !== 'is_demo' && (
              <Box>
                {props.errorType === 'sensor_incorrect' ? (
                  <ErrorOutlineIcon
                    className={classes.leftIconNew}
                    htmlColor='#FFFFFF'
                    fontSize='large'
                  />
                ) : (
                  <QueryBuilderIcon
                    className={classes.leftIconNew}
                    htmlColor='#FFFFFF'
                    fontSize='large'
                  />
                )}
              </Box>
            )}
            <Box>
              <Typography className={classes.titleNew}>
                {props.title}
              </Typography>
              <Typography className={classes.textNew}>{props.text}</Typography>
            </Box>
          </Box>

          {props.errorType === 'is_demo' ? (
            <Box className={classes.demoButtonsContainer}>
              <IconButton
                className={classes.contactButtonNew}
                // onClick={props.onContact}
              >
                <QuestionAnswerOutlinedIcon
                  className={classes.contactIcon}
                  fontSize='small'
                  htmlColor='#FFFFFF'
                />
                <Trans>Связаться</Trans>
              </IconButton>

              <IconButton
                className={clsx(
                  classes.closeButtonNew,
                  classes.closeButtonDemo,
                )}
                onClick={props.onClose}
              >
                <CloseIcon fontSize='small' htmlColor='#FFFFFF' />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              className={classes.closeButtonNew}
              onClick={props.onClose}
            >
              <CloseIcon fontSize='small' htmlColor='#FFFFFF' />
            </IconButton>
          )}
        </Box>
      </Box>
    </Grow>
  ) : (
    <Grow in={open}>
      <Box className={classes.container}>
        {renderIcon()}
        <Box py={1}>
          <Typography className={classes.text}>{props.text}</Typography>
        </Box>
        <IconButton className={classes.closeIcon} onClick={props.onClose}>
          <img
            alt='Close'
            src={new URL('./images/close.svg', import.meta.url).toString()}
          />
        </IconButton>
      </Box>
    </Grow>
  );
};

ClosableBanner.defaultProps = {
  iconName: null,
};

export default ClosableBanner;
