/** @format */

import {makeStyles} from '@material-ui/core/styles';

import BaseTextField from './BaseTextField';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 8,
    '&:hover fieldset': {
      borderColor: theme.palette.action.hover,
    },
    '& fieldset': {
      borderColor: theme.palette.action.active,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.primary,
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      fontWeight: '400',
      lineHeight: '16px',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.action.selected,
    },
  },
}));

const LegacyTextField = props => {
  const classes = useStyles();
  return <BaseTextField className={classes.root} {...props} />;
};

export default LegacyTextField;
