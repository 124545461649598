/** @format */
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

interface Props {
  children: React.ReactChildren | React.ReactChild
}

const useStyles = makeStyles(theme => ({
  infoTitle: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '23px',
  }
}));

const BoxPlateTitle = (props: Props) => {
  const styles = useStyles();
  return (
    <Typography className={styles.infoTitle}>
      {props.children}
    </Typography>
  );
};

export default BoxPlateTitle;
