/** @format */

import {useState} from 'react';

import * as R from 'ramda';
import {Box, Link, Typography} from '@material-ui/core';
import {format} from 'date-fns';
import {gql, useMutation, useQuery} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate, useLocation} from 'react-router-dom';

import {useMixpanelContext} from 'contexts/MixpanelContext';

import InformationDialog from 'components/dialogs/InformationDialog';
import Loading from 'components/loading/Loading';

import DailyPlans, {DAILY_PLANS} from './DailyPlans';
import OneTimePlans, {ONE_TIME_PLANS} from './OneTimePlans';
import RichClient from './RichClient';

const RICH_CLIENT_MONTH_SPENT = 5000000;

const GET_CURRENT_USER = gql`
  query GetCurrentUser($date: Date!) {
    currentUser {
      companies {
        stats(date: $date) {
          platform
          accounts
          campaigns
          ads
          monthSpent
        }
      }
      subscription {
        price
        beginDate
        endDate
        planId
      }
    }
  }
`;

const RENEW_PLAN = gql`
  mutation RenewPlan {
    renewPlan {
      billId
    }
  }
`;

const useStyles = makeStyles(_theme => ({
  link: {
    textDecoration: 'underline',
    verticalAlign: 'baseline',
  },
}));

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {date: format(new Date(), 'yyyy-MM-dd')},
  });
  const [renewPlan, {loading: renewPlanLoading}] = useMutation(RENEW_PLAN);

  if (loading || error) return null;
  if (renewPlanLoading) return <Loading />;

  const {companies, subscription} = data.currentUser;

  const totalStats = companies.reduce((acc, company) => {
    const stats = company.stats.find(v => v.platform === 'ALL');
    if (!stats) return acc;

    ['accounts', 'campaigns', 'ads', 'monthSpent'].forEach(v => {
      acc[v] = (acc[v] || 0) + stats[v];
    });

    return acc;
  }, {});

  const handleChangePlan = async plan => {
    try {
      mixpanel.people.increment('click_change_plan_button_count');
      mixpanel.track('click_change_plan_button', {
        plan_id: plan.id,
        plan_name: plan.name,
        plan_price: plan.price,
        plan_type: plan.type,
      });

      navigate({
        pathname: 'confirm-change-plan',
        search: `?planId=${plan.id}&planName=${plan.name}`,
        state: {background: location},
      });
    } catch (_e) {
      setShowErrorDialog(true);
    }
  };

  const handleRenewPlan = async () => {
    const allPlans = DAILY_PLANS.concat(ONE_TIME_PLANS);
    const currentPlan = allPlans.find(v => v.id === subscription.planId);

    try {
      mixpanel.people.increment('click_renew_plan_button_count');
      mixpanel.track('click_renew_plan_button', {
        plan_id: currentPlan.id,
        plan_name: currentPlan.name,
        plan_price: currentPlan.price,
        plan_type: currentPlan.type,
      });

      const {data: renewPlanData} = await renewPlan();
      const {billId} = renewPlanData.renewPlan;

      window.location.href = `https://yalper.adsensor.ru/bills/${billId}/make_payment`;
    } catch (_e) {
      setShowErrorDialog(true);
    }
  };

  const renderErrorDialog = () => {
    const handleClose = () => {
      setShowErrorDialog(false);
    };

    return (
      <InformationDialog
        open={showErrorDialog}
        onClose={handleClose}
        title='Ошибка при создании платежа'
        body='Не удалось создать платеж. Попробуйте еще раз'
      />
    );
  };

  const isRichClient =
    !R.isEmpty(totalStats) && totalStats.monthSpent >= RICH_CLIENT_MONTH_SPENT;

  const renderFooter = () => {
    const handleClick = () => {
      navigate('/home/settings/connect');
    };

    return (
      <Box mt={8}>
        <Typography display='inline' variant='body2'>
          {'Хотите платить меньше? Отключите проекты, которыми ' +
            'не пользуетесь'}
        </Typography>
        <Link className={classes.link} component='button' onClick={handleClick}>
          <Typography variant='body2'>Отключить</Typography>
        </Link>
      </Box>
    );
  };

  return (
    <>
      {isRichClient && <RichClient />}

      {!isRichClient && (
        <>
          <DailyPlans
            companies={companies}
            subscription={subscription}
            totalStats={totalStats}
            onChangePlan={handleChangePlan}
            onRenewPlan={handleRenewPlan}
          />

          <Box mt={16}>
            <OneTimePlans
              companies={companies}
              subscription={subscription}
              totalStats={totalStats}
              onChangePlan={handleChangePlan}
              onRenewPlan={handleRenewPlan}
            />
          </Box>

          {renderFooter()}
          {renderErrorDialog()}
        </>
      )}
    </>
  );
};

export default Payment;
