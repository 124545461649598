/** @format */
import {useEffect, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import {Box, LinearProgress ,Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {gql, useMutation} from '@apollo/client';

import ShareLink from 'components/ShareLink';
import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

interface mutationCreateGuest {
  id: number,
  uuid: string,
};

const CREATE_GUEST = gql`
  mutation CreateGuest($companyId: ID!) {
    createGuest(companyId: $companyId) {
      id
      uuid
    }
  }
`;

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    text: {
      color: '#A4A4A4',
    },
  }),
);

const InviteGuest = () => {
  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');

  const styles = useStyles();

  const {mixpanel} = useMixpanelContext();

  const [guestUUID, setGuestUUID] = useState('');
  const [createGuestError, setCreateGuestError] = useState('');

  const [createGuest, {data, loading}] = useMutation<{createGuest: mutationCreateGuest}>(
    CREATE_GUEST,
    {variables: {companyId}},
  );

  useEffect(() => {
    createGuest()
      .catch(e => setCreateGuestError((e as Error).message));
  }, []);

  useEffect(() => {
    if (data != null) {
      setGuestUUID(data.createGuest.uuid);
    }
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (createGuestError) {
    return <Alert severity='error'>{createGuestError}</Alert>;
  }

  const handleCopy = () => {
    mixpanel.track('click_copy_invite_guest_url_button');
  };

  const url = () => {
    return `${location.origin}/guest/welcome/${guestUUID}?companyId=${companyId}`;
  };

  return (
    <>
      <Box mb={8}>
        <Typography variant='h1'>
          <Trans>Если у вас нет доступов от кабинетов</Trans>
        </Typography>
      </Box>
      <Typography variant='body1' className={styles.text}>
        <Trans>
          Скопируйте и отправьте ссылку тому, кто может подключить кабинеты
          для вас. Это может быть ваш специалист, кто ведет рекламные кампании
          или представитель агентства, с кем вы работаете.
        </Trans>
      </Typography>
        <Box my={5}>
          <ShareLink url={url()} variant='DARK' onCopy={handleCopy} />
        </Box>
      <Typography variant='body1' className={styles.text}>
        <Trans>
          По ссылке специалист увидит интерфейс подключения кабинетов, отчёт он
          сможет получить только если вы настроите отправку уведомлений на email.
        </Trans>
      </Typography>
    </>
  );
};

export default InviteGuest;
