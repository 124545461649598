/** @format */

import {MouseEvent, useState} from 'react';

import type {GridSize} from '@material-ui/core/Grid/Grid';
import {Box, Grid, IconButton, Popper, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

import questionImgUrl from 'images/question.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      borderRadius: '50%',
      width: 8,
      height: 8,
    },
    bigDot: {
      borderRadius: 5,
      width: 15,
      height: 15,
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
      whiteSpace: 'pre-line',
    },
    tooltip: {
      backgroundColor: '#1A1B1B',
      borderRadius: 12,
      padding: theme.spacing(4),
    },
  }),
);

type Item = {
  text: string;
  tooltip: string | null;
  backgroundColor: string;
};

type Props = {
  items: Item[];
  smGridSize: GridSize;
  xsGridSize: GridSize;
  className?: string;
  isBigDots?: boolean;
};

const Legend = (props: Props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [tooltip, setTooltip] = useState<string | null>('');

  const renderItem = (item: Item, index: number) => {
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(!open);
      setTooltip(item.tooltip);
    };

    return (
      <Grid key={index} item xs={props.xsGridSize} sm={props.smGridSize}>
        <Box alignItems='center' display='flex'>
          <Box
            className={props.isBigDots ? classes.bigDot : classes.dot}
            style={{backgroundColor: item.backgroundColor}}
          />
          <Box ml={2}>
            <Typography className={classes.text}>{item.text}</Typography>
          </Box>
          {item.tooltip && (
            <Box ml={2}>
              <IconButton size='small' onClick={handleClick}>
                <img alt='About' height='12' src={questionImgUrl} width='12' />
              </IconButton>
            </Box>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <>
      <Popper
        anchorEl={anchorEl}
        modifiers={{offset: {enabled: true, offset: '0, 8'}}}
        open={open}
        placement='bottom'
        transition
      >
        <Box className={classes.tooltip}>
          <Typography className={classes.text}>{tooltip}</Typography>
        </Box>
      </Popper>

      <Grid className={props.className} container spacing={1}>
        {props.items.map((v, i) => renderItem(v, i))}
      </Grid>
    </>
  );
};

export default Legend;
