/** @format */

import clsx from 'clsx';
import {Box, Link, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Trans} from 'react-i18next';

import CustomInfo from './CustomInfo';
import imageHelpers from 'helpers/imageHelpers';
import reportHelpers from 'helpers/reportHelpers';
import styles from './styles';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const Account = props => {
  const {mixpanel} = useMixpanelContext();

  const sample = props.reportItemGroup[0];
  const showCustomInfo = sample.maxLocationField === 'accountId';

  const handleClick = () => {
    mixpanel.track('click_account_link', {sensor: props.sensorName});
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' justifyContent='space-between'>
        <Typography className={clsx(props.classes.text, props.classes.title)}>
          <Trans>Аккаунт</Trans>
        </Typography>

        <Link
          href={reportHelpers.accountLink(sample.platform, sample.accountId)}
          target='_blank'
          onClick={handleClick}
        >
          <Typography
            className={clsx(
              props.classes.text,
              props.classes.value,
              props.classes.underline,
            )}
            component='div'
          >
            <Box display='flex'>
              {sample.account}
              <Box display='flex' ml={2}>
                <img
                  alt='Link'
                  height={20}
                  src={imageHelpers.platformIconSrc(sample.platform, 20)}
                  width={20}
                />
              </Box>
            </Box>
          </Typography>
        </Link>
      </Box>

      {showCustomInfo && (
        <CustomInfo
          reportItemGroup={props.reportItemGroup}
          sensorName={props.sensorName}
        />
      )}
    </Box>
  );
};

export default withStyles(styles)(Account);
