/** @format */

import clsx from 'clsx';
import {Box, ListItem, ListItemText, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';

import {sensorName} from 'helpers/sensor_names';
import type {Check} from 'types';

const useStyles = makeStyles(theme =>
  createStyles({
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#202020',
      borderRadius: 8,
      cursor: 'pointer',
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      position: 'relative',
      paddingRight: theme.spacing(4),
      borderRight: '2px solid transparent',
      borderLeft: '2px solid transparent',
    },
    avatar: {
      minWidth: 16,
    },
    leftTextContainer: {
      minWidth: 'unset',
      maxWidth: '70%',
    },
    rightTextContainer: {
      flexGrow: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: theme.spacing(40),
      padding: theme.spacing(1.5, 0),
      borderRadius: '8px',
      border: '1px solid #8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.169px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    foundIconContainer: {
      flexGrow: 0,
      minWidth: 'unset',
    },
    rowRed: {
      borderColor: '#AE5658',
    },
    rowYellow: {
      borderColor: '#AA8258',
    },
    rowNeutral: {
      borderColor: '#8B8B8B',
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '26px',
      letterSpacing: '0.169px',
      paddingRight: theme.spacing(2),
    },
    textYellow: {
      color: '#AA8258',
    },
    textRed: {
      color: '#AE5658 !important',
    },
    text: {
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.169px',
    },
    borderRed: {
      borderColor: '#AE5658',
    },
    borderYellow: {
      borderColor: '#AA8258',
    },
    borderNeutral: {
      borderColor: '#8B8B8B',
    },
    backdrop: {
      backgroundColor: theme.custom_palette.backdrop.main,
      position: 'absolute',
      zIndex: 1,
    },
    icon: {
      width: '48px',
      height: '48px',
      borderRadius: '8px',
      background: '#2B2B2B',
      marginRight: theme.spacing(4),
    },
  }),
);

interface Props {
  check: Check;
  onClick: (check: Check) => void;
}

const Row = (props: Props) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  const textContainerClassName = clsx(
    props.check.new > 0 && classes.borderRed,
    props.check.new === 0 && classes.borderYellow,
    props.check.found === 0 && classes.borderNeutral,
  );

  const textClassName = clsx(
    props.check.new > 0 && classes.textRed,
    props.check.new === 0 && classes.textYellow,
    props.check.found === 0 && classes.text,
  );

  const foundText = (check: Check) => {
    return `${check.found}`;
  };

  return (
    <Box position='relative'>
      <ListItem
        className={clsx(
          classes.row,
          props.check.new > 0 && classes.rowRed,
          props.check.new === 0 && classes.rowYellow,
          props.check.found === 0 && classes.rowNeutral,
        )}
        onClick={handleClick}
      >
        <ListItemText
          className={classes.leftTextContainer}
          disableTypography
          primary={
            <Box display={'flex'} alignItems={'center'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                className={classes.icon}
              >
                {!props.check.sensor.isError ? (
                  <EmojiObjectsOutlinedIcon fontSize='small' htmlColor='#fff' />
                ) : (
                  <NotificationImportantOutlinedIcon
                    fontSize='small'
                    htmlColor='#fff'
                  />
                )}
              </Box>

              <Typography className={classes.title}>
                {t(sensorName(props.check.sensor.name))}
              </Typography>
            </Box>
          }
        />

        <ListItemText
          className={clsx(classes.rightTextContainer, textContainerClassName)}
          disableTypography
          primary={
            <Typography
              align='right'
              className={clsx(classes.text, textClassName)}
            >
              {foundText(props.check)}&nbsp;
            </Typography>
          }
          secondary={
            <Typography
              align='right'
              className={clsx(classes.text, textClassName)}
            >
              {Boolean(props.check.new) && `(+${props.check.new})`}
            </Typography>
          }
        />
      </ListItem>
    </Box>
  );
};

export default Row;
