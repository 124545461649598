/** @format */

import React, {Suspense} from 'react';

import ReactDOM from 'react-dom';

import * as R from 'ramda';
import * as Sentry from '@sentry/react';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-GB';
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import {BrowserRouter} from 'react-router-dom';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {SentryLink} from 'apollo-link-sentry';
import {createLink} from 'apollo-absinthe-upload-link';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {CookiesProvider} from 'react-cookie';
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';

import {
  AD_TYPE_KEY,
  COMPANY_KEY,
  CompanyProvider,
} from 'contexts/CompanyContext';
import {AuthProvider, AUTH_TOKEN_KEY} from 'contexts/AuthContext';
import {BannerProvider} from 'contexts/BannerContext';
import {MixpanelProvider} from 'contexts/MixpanelContext';
import {OnboardingProvider} from 'contexts/OnboardingContext';
import {ScoreProvider} from 'contexts/ScoreContext';
import {SidebarProvider} from 'contexts/SidebarContext';

import './i18n';
import App from './App';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://c205347cd9bc4c1e8f09df478e3db3f3@o112554.ingest.sentry.io/5774590',
  });
}

const authLink = setContext((_, {headers}) => {
  const authToken = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
  const currentCompany = JSON.parse(localStorage.getItem(COMPANY_KEY));
  const currentAdType = JSON.parse(localStorage.getItem(AD_TYPE_KEY));

  const token = authToken && authToken.token;
  const uuid = currentCompany && currentCompany.uuid;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Company-UUID': uuid || '',
      'Ad-Type': currentAdType || 'CONTEXT',
    },
  };
});

// https://www.apollographql.com/docs/react/data/error-handling/#network-errors
const errorLink = onError(data => {
  console.log('got error', data);
  const {graphQLErrors, networkError, operation} = data;
  console.log('operation', operation);
  console.log('networkError', networkError);
  console.log('graphqlErrors', graphQLErrors);

  if (graphQLErrors) {
    graphQLErrors.map(({message, _location, _path}) =>
      Sentry.captureMessage(message),
    );
  }
  if (networkError) {
    Sentry.captureException(networkError);
  }

  if (networkError) {
    Sentry.captureMessage(networkError.message, {
      tags: {errorKind: 'networkError'},
      contexts: operation.query,
    });
  }

  if (graphQLErrors) {
    // This error happens when:
    //
    // - auth token is deleted in database
    // - token expires
    if (R.any(v => v.message === 'unauthenticated')(graphQLErrors)) {
      // alert('Ваша сессия устарела');
      localStorage.removeItem(AUTH_TOKEN_KEY);
      window.location.reload();
      return;
    }

    graphQLErrors.forEach(v => {
      Sentry.captureMessage(v.message, {
        tags: {errorKind: 'graphqlError'},
        contexts: operation.query,
      });
    });
  }
});

const httpLink = createLink({
  // https://create-react-app.dev/docs/adding-custom-environment-variables/
  uri: new URL('api', import.meta.env.VITE_API_URI),
  // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  //
  // fetch() won’t send cross-origin cookies unless you set the credentials
  // init option. The spec changed the default credentials policy to same-origin
  credentials: 'include',
});

const sentryLink = new SentryLink();

const links = authLink.concat(sentryLink).concat(errorLink).concat(httpLink);

// https://www.apollographql.com/docs/react/networking/basic-http-networking/
const client = new ApolloClient({
  link: links,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      // https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
      fetchPolicy: 'no-cache',
    },
  },
});

console.log(`


 █████╗ ██████╗ ███████╗███████╗███╗   ██╗███████╗ ██████╗ ██████╗ 
██╔══██╗██╔══██╗██╔════╝██╔════╝████╗  ██║██╔════╝██╔═══██╗██╔══██╗
███████║██║  ██║███████╗█████╗  ██╔██╗ ██║███████╗██║   ██║██████╔╝
██╔══██║██║  ██║╚════██║██╔══╝  ██║╚██╗██║╚════██║██║   ██║██╔══██╗
██║  ██║██████╔╝███████║███████╗██║ ╚████║███████║╚██████╔╝██║  ██║
╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝


`);

(async () => {
  const authToken = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));

  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LD_CLIENT_ID,
    context: {
      kind: 'user',
      key: authToken?.user.uuid || '',
    },
  });

  const app = (
    <React.StrictMode>
      <BrowserRouter>
        <LDProvider>
          <MixpanelProvider>
            <CompanyProvider>
              <AuthProvider>
                <BannerProvider>
                  <OnboardingProvider>
                    <ApolloProvider client={client}>
                      <ScoreProvider>
                        <SidebarProvider>
                          <CookiesProvider>
                            <MuiPickersUtilsProvider
                              locale={enLocale}
                              utils={DateFnsUtils}
                            >
                              <Suspense fallback={<div>Loading...</div>}>
                                <App />
                              </Suspense>
                            </MuiPickersUtilsProvider>
                          </CookiesProvider>
                        </SidebarProvider>
                      </ScoreProvider>
                    </ApolloProvider>
                  </OnboardingProvider>
                </BannerProvider>
              </AuthProvider>
            </CompanyProvider>
          </MixpanelProvider>
        </LDProvider>
      </BrowserRouter>
    </React.StrictMode>
  );

  ReactDOM.render(app, document.getElementById('root'));
})();
