/** @format */

import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import imageHelpers from 'helpers/imageHelpers';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

const ConnectedProviders = props => {
  const classes = useStyles();

  return (
    <Box alignItems='center' display='flex'>
      <Box height={props.size} />

      {props.adTypes.includes('CONTEXT') && (
        <>
          {props.tokens.find(v => v.provider === 'YANDEX') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'YANDEX',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
          {props.tokens.find(v => v.provider === 'GOOGLE') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'GOOGLE',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
        </>
      )}

      {props.adTypes.includes('TARGET') && (
        <>
          {props.tokens.find(v => v.provider === 'VKONTAKTE') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'VKONTAKTE',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
          {props.tokens.find(v => v.provider === 'VKONTAKTE_ADS') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'VKONTAKTE_ADS',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
          {props.tokens.find(v => v.provider === 'FACEBOOK') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'FACEBOOK',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
        </>
      )}
      {props.adTypes.includes('STATS') && (
        <>
          {props.tokens.find(v => v.provider === 'METRIKA') && (
            <img
              alt='Icon'
              className={classes.icon}
              height={`${props.size}`}
              src={imageHelpers.providerIconSrc(
                'METRIKA',
                props.size,
                props.disabled,
              )}
              width={`${props.size}`}
            />
          )}
        </>
      )}
    </Box>
  );
};

ConnectedProviders.defaultProps = {
  disabled: false,
};

export default ConnectedProviders;
