/** @format */

import {useEffect, useState} from 'react';

import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans} from 'react-i18next';
import {Typography} from '@material-ui/core';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import ErrorBanner from './ErrorBanner';

const VALID_PATHS = ['/home/main/status'];

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      isPaymentError: isFlagEnabled(name: PAYMENT_ERROR)
    }
  }
`;

const HIDE_PAYMENT_ERROR = gql`
  mutation HidePaymentError {
    disableFlag(name: PAYMENT_ERROR)
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
    },
    link: {
      ...theme.custom_typography.link,
      color: theme.palette.text.primary,
    },
  }),
);

const PaymentErrorBanner = () => {
  const location = useLocation();
  const classes = useStyles();

  const [show, setShow] = useState(true);

  const skip = !VALID_PATHS.includes(location.pathname);

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {skip});
  const [hidePaymentError] = useMutation(HIDE_PAYMENT_ERROR);

  useEffect(() => {
    if (!data) return;

    const {isPaymentError} = data.currentUser;
    setShow(isPaymentError);
  }, [data]);

  if (loading || error) return null;

  if (skip) return null;
  if (!data.currentUser.isPaymentError || !show) return null;

  const handleClose = async () => {
    await hidePaymentError();
    setShow(false);
  };

  return (
    <ErrorBanner onClose={handleClose}>
      <Typography className={classes.text}>
        <Trans>
          Не удалось списать оплату и проверки по новым кабинетам не будут
          запущены. Попробуйте снова
        </Trans>
      </Typography>
    </ErrorBanner>
  );
};

export default PaymentErrorBanner;
