/** @format */

import {IconButton} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface Props {
  className?: string;
  onConnect: () => void;
}

const ConnectButtonNew = (props: Props) => {
  return (
    <IconButton className={props.className} onClick={props.onConnect}>
      <AddCircleIcon htmlColor='#677C41' fontSize='small' />
    </IconButton>
  );
};

export default ConnectButtonNew;
