/** @format */

import {Box, Link, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  link: {
    textDecoration: 'underline',
    verticalAlign: 'baseline',
  },
}));

const RichClient = () => {
  const classes = useStyles();

  return (
    <Box mt={6}>
      <Typography className={classes.text}>
        Наш менеджер скоро свяжется с вами.
      </Typography>

      <Box mt={2}>
        <Typography className={classes.text} display='inline'>
          {'Если у вас остались какие-то вопросы, связанные с оплатой, ' +
            'жмите '}
        </Typography>
        <Link
          className={classes.link}
          component='button'
          href='https://t.me/adsensor_amocrm_bot'
          target='_blank'
        >
          <Typography className={classes.text}>сюда</Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default RichClient;
