/** @format */

import {Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const PrimaryLargeButton = withStyles(theme => ({
  root: {
    borderRadius: 16,
    fontSize: 16,
    fontWeight: '500',
    height: 64,
    lineHeight: '20px',
    padding: '14px 24px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))(props => <Button fullWidth={false} {...props} />);

export default PrimaryLargeButton;
