/** @format */

import {Button} from '@material-ui/core';
import {Theme, createStyles, withStyles} from '@material-ui/core/styles';

type Props = {
  ml?: number
  onClick: () => void;
};

const OutlinedSmallButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #202020',
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
  }),
)((props: Props) => <Button fullWidth={false}{...props} />);

export default OutlinedSmallButton;
