/** @format */

import {Box, Grid, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';

import Modal from 'components/Modal';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {useSidebarContext} from 'contexts/SidebarContext';

const DISCONNECT_PROVIDER = gql`
  mutation DisconnectProvider($companyId: ID!, $provider: OauthProvider!) {
    disconnectProvider(companyId: $companyId, provider: $provider)
  }
`;

const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.secondary,
  },
}));

const ConfirmDisconnect = () => {
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');
  const provider = searchParams.get('provider');

  const backgroundPath = routerHelpers.useBackgroundPath();

  const {mixpanel} = useMixpanelContext();
  const {refreshSidebar} = useSidebarContext();

  const {t} = useTranslation();

  const classes = useStyles();

  const [disconnectProvider, {loading}] = useMutation(DISCONNECT_PROVIDER);

  const renderButtons = () => {
    const handleConfirm = async () => {
      const lcProvider = provider.toLowerCase();
      mixpanel.track(`click_confirm_disconnect_${lcProvider}_button`);

      await disconnectProvider({variables: {provider, companyId}});
      refreshSidebar();

      navigate(backgroundPath, {replace: true});
    };

    const handleCancel = () => {
      const lcProvider = provider.toLowerCase();
      mixpanel.track(`click_cancel_disconnect_${lcProvider}_button`);

      refreshSidebar();
      navigate(-1);
    };

    return (
      <Box mt={16}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={loading}
              fullWidth
              onClick={handleConfirm}
            >
              <Trans>Отключить</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item xs={12} sm={5}>
            <SecondaryMediumButton disabled={loading} onClick={handleCancel}>
              <Trans>Оставить</Trans>
            </SecondaryMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Modal
      header={t(
        'Вы уверены, что хотите отключить кабинет?',
        'Вы уверены, что хотите отключить кабинет?',
      )}
      showCloseIcon={false}
    >
      <Typography className={classes.body} variant='body1'>
        <Trans>
          Кабинет удалится и, чтобы подключить его снова, нужно будет заново
          вводить все данные авторизации.
        </Trans>
      </Typography>

      {renderButtons()}
    </Modal>
  );
};

export default ConfirmDisconnect;
