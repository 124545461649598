/** @format */

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iframe: {
    width: '600px',
    height: '360px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '240px',
    },
  },
}));

interface Props {
  videoId: string;
}

const RutubePlayer = (props: Props) => {
  const classes = useStyles();

  return (
    <iframe
      className={classes.iframe}
      src={`https://rutube.ru/play/embed/${props.videoId}`}
      allow='clipboard-write'
      // @ts-ignore
      webkitAllowFullScreen
      mozallowfullscreen
      allowFullScreen
    ></iframe>
  );
};

export default RutubePlayer;
