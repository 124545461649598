/** @format */

import {withStyles} from '@material-ui/core/styles';

import PrimarySmallButton from 'components/buttons/PrimarySmallButton';

const SecondarySmallButton = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: '#828282',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: '#DBDBDB',
    },
  },
}))(props => <PrimarySmallButton {...props} />);

export default SecondarySmallButton;
