/** @format */

import {useState} from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Box, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import {useLocation, useNavigate} from 'react-router-dom';

import ArrowBack from 'components/navigation/ArrowBack';
import PhoneNumberFieldFilled from 'components/inputs/PhoneNumberFieldFilled';
import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';

const RESET_PASSWORD = gql`
  mutation ResetPassword($phone: String!) {
    resetPassword(phone: $phone)
  }
`;

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '20px',
  },
  link: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '16px',
    textDecoration: 'underline',
  },
}));

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();

  const [phone, setPhone] = useState(null);
  const [resetPasswordError, setResetPasswordError] = useState('');

  const [resetPassword, {loading}] = useMutation(RESET_PASSWORD);

  const handleChangePhone = value => {
    setPhone(value);
  };

  const handleResetPassword = async () => {
    try {
      // NumberFormat adds trailing spaces
      await resetPassword({variables: {phone: phone.trim()}});
      navigate(
        {
          pathname: '/auth/sign-in',
          search: '?passwordReset=1',
        },
        location.state,
      );
    } catch (e) {
      setResetPasswordError(e.message);
    }
  };

  const handleClickBack = () => {
    navigate('/auth/sign-in');
  };

  return (
    <>
      <Box mb={4}>
        <ArrowBack onClick={handleClickBack}>
          <Trans>Авторизация</Trans>
        </ArrowBack>
      </Box>
      <Box mt={4}>
        <Typography variant='h1'>
          <Trans>Восстановление пароля</Trans>
        </Typography>
      </Box>

      <Box mt={3}>
        <Typography className={classes.subtitle}>
          <Trans>Отправим пароль на указанный номер телефона.</Trans>
        </Typography>
      </Box>

      <Box mt={5}>
        <PhoneNumberFieldFilled
          error={Boolean(resetPasswordError)}
          helperText={resetPasswordError}
          value={phone}
          onChange={handleChangePhone}
        />
      </Box>

      <Box mt={4}>
        <PrimaryLargeButton
          color='primary'
          disabled={loading || !phone}
          variant='contained'
          fullWidth
          endIcon={<ArrowForwardIcon />}
          onClick={handleResetPassword}
        >
          <Trans>Отправить</Trans>
        </PrimaryLargeButton>
      </Box>
    </>
  );
};

export default ResetPassword;
