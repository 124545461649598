/** @format */

import {Box, Typography} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

import ButtonPlate from 'components/plates/ButtonPlate';

import type {Specialist} from './index';

const useStyles = makeStyles(() => ({
  row: {
    padding: '16px',
    borderRadius: '8px',
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },
  rowTitle: {
    color: '#F1F1F1',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0.169px',
  },
  arrowWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#2B2B2B',
  },
}));

type Props = {
  specialist: Specialist;
};

const Row = (props: Props) => {
  const navigate = useNavigate();

  const classes = useStyles();

  const handleClick = () => {
    navigate({
      pathname: '/home/settings/notifications',
      search: `?specialistId=${props.specialist.id}`,
    });
  };

  return (
    <ButtonPlate onClick={handleClick}>
      <Box
        alignItems='center'
        display='flex'
        flexGrow={1}
        justifyContent='space-between'
        className={classes.row}
      >
        <Typography className={classes.rowTitle}>
          {props.specialist.name}
        </Typography>

        <Box className={classes.arrowWrapper}>
          <img
            alt='Arrow'
            height='16'
            src={new URL('./images/arrow_right.png', import.meta.url).href}
            width='16'
          />
        </Box>
      </Box>
    </ButtonPlate>
  );
};

export default Row;
