/** @format */

import {createTheme} from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    custom_palette: {
      success: {
        hover: string;
      };
      backdrop: {
        main: string;
      };
    };
    custom_typography: {
      link: Record<string, unknown>;
    };
  }

  interface ThemeOptions {
    custom_palette?: {
      success?: {
        hover?: string;
      };
      backdrop?: {
        main?: string;
      };
    };
    custom_typography?: {
      link?: Record<string, unknown>;
    };
  }
}

// https://stackoverflow.com/a/62453393/3632318
const baseTheme = createTheme({
  spacing: 4,
  // https://material-ui.com/customization/palette/#dark-mode
  palette: {
    type: 'dark',
    background: {
      // This sets <body> background color
      default: '#010202',
    },
    error: {
      // Error text
      main: '#EE4D50',
    },
    success: {
      main: '#749F23',
    },
    primary: {
      // Primary button background
      main: '#F7F8FA',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#141414',
    },
    action: {
      // Button border by default
      active: '#4D4E4E',
      // Button border on hover
      hover: '#808080',
      // Button border on focus
      selected: '#999A9A',
      // Disabled button text
      disabled: '#606161',
      // Disabled button background
      disabledBackground: '#202020',
    },
  },
  custom_palette: {
    success: {
      hover: '#648F13',
    },
    backdrop: {
      main: 'rgba(10, 10, 10, 0.9)',
    },
  },
  custom_typography: {
    // https://github.com/mui-org/material-ui/issues/22257#issuecomment-782557096
    link: {
      color: '#A6A6A6',
      fontSize: 14,
      fontWeight: '700',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#A6A6A6',
      },
    },
  },
});

const theme = createTheme(baseTheme, {
  typography: {
    h1: {
      fontSize: 28,
      fontWeight: '700',
      lineHeight: '32px',
      whiteSpace: 'pre-line',
    },
    h2: {
      fontSize: 18,
      fontWeight: '700',
      lineHeight: '22px',
      whiteSpace: 'pre-line',
    },
    body1: {
      fontSize: 16,
      fontWeight: '400',
      lineHeight: '20px',
      whiteSpace: 'pre-line',
    },
    body2: {
      fontSize: 14,
      fontWeight: '400',
      lineHeight: '16px',
      whiteSpace: 'pre-line',
    },
  },
  // https://material-ui.com/customization/components/#global-theme-override
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#444444',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiPickersDay: {
      day: {
        backgroundColor: '#FFFFFF',
        color: '#444444',
      },
      daySelected: {
        backgroundColor: '#444444',
        color: '#AAAAAA',
      },
      dayDisabled: {
        color: '#AAAAAA',
      },
      current: {
        backgroundColor: '#FF8888',
        color: '#444444',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        fontSize: 16,
        fontWeight: '500',
        lineHeight: '20px',
        textTransform: 'none',
      },
      containedPrimary: {
        color: baseTheme.palette.text.secondary,
        '&:hover': {
          backgroundColor: '#E6E6E6',
        },
      },
      containedSizeLarge: {
        fontSize: 16,
        padding: 22,
      },
      outlinedPrimary: {
        borderColor: baseTheme.palette.action.active,
        // palette.primary.main is used by default
        // (since outlined button is inverted contained one)
        color: baseTheme.palette.text.primary,
        '&:hover': {
          backgroundColor: baseTheme.palette.background.default,
          borderColor: baseTheme.palette.action.hover,
        },
      },
      outlinedSizeLarge: {
        fontSize: 16,
        padding: 22,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          color: baseTheme.palette.text.primary,
          opacity: 0.5,
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: baseTheme.palette.background.default,
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiListItemAvatar: {
      root: {
        lineHeight: 0,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
      paper: {
        backgroundColor: '#4A4A4A',
        borderRadius: 8,
        '&:hover': {
          backgroundColor: '#4A4A4A',
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingBottom: baseTheme.spacing(4),
        paddingLeft: baseTheme.spacing(6),
        paddingRight: baseTheme.spacing(6),
        paddingTop: baseTheme.spacing(4),
        '&:hover': {
          backgroundColor: '#393939',
        },
        '&.Mui-selected': {
          backgroundColor: baseTheme.palette.secondary.main,
          color: baseTheme.palette.text.secondary,
          '&:hover': {
            backgroundColor: '#F0F0F0',
          },
        },
      },
    },
    // Used in TextField
    MuiOutlinedInput: {
      // https://material-ui.com/components/text-fields/#customized-inputs
      root: {
        borderRadius: 8,
        fontSize: 14,
        fontWeight: '400',
        lineHeight: '16px',
        '& fieldset': {
          borderColor: baseTheme.palette.action.active,
        },
        '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: baseTheme.palette.action.hover,
        },
        '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: baseTheme.palette.action.selected,
          borderWidth: 1,
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      fullWidth: true,
      size: 'large',
    },
    MuiTextField: {
      fullWidth: true,
      required: true,
      variant: 'outlined',
    },
  },
});

export default theme;
