/** @format */

import {useEffect, useState} from 'react';

import {Box, Grow, IconButton} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      alignSelf: 'stretch',
      backgroundColor: '#B83857',
      display: 'flex',
      padding: theme.spacing(4),
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        paddingRight: theme.spacing(8),
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        paddingRight: theme.spacing(12),
      },
    },
    closeIcon: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  }),
);

// https://www.carlrippon.com/react-children-with-typescript/
type Props = {
  onClose: () => void;
  children: React.ReactNode;
};

const ErrorBanner = (props: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => setOpen(true), 100);
  }, []);

  return (
    <Grow in={open}>
      <Box className={classes.container}>
        {props.children}
        <IconButton className={classes.closeIcon} onClick={props.onClose}>
          <img
            alt='Close'
            src={new URL('./images/close.svg', import.meta.url).href}
          />
        </IconButton>
      </Box>
    </Grow>
  );
};

export default ErrorBanner;
