/** @format */

import {ChangeEvent, useEffect, useState} from 'react';

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {Box, Grid, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import MediumTextField from 'components/inputs/MediumTextField';
import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const GET_CORRECT_WORDS = gql`
  query GetCorrectWords {
    currentUser {
      currentCompany {
        correctWords
      }
    }
  }
`;

const SAVE_CORRECT_WORDS = gql`
  mutation SaveCorrectWords($correctWords: [String!]!) {
    saveCorrectWords(correctWords: $correctWords)
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      whiteSpace: 'pre-line',
    },
    footer: {
      alignItems: 'center',
      display: 'flex',
    },
    footerText: {
      color: '#808080',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
);

const CorrectWords = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const {t} = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const {loading, error, data} = useQuery(GET_CORRECT_WORDS);
  const [saveCorrectWords, {loading: saveCorrectWordsLoading}] =
    useMutation(SAVE_CORRECT_WORDS);

  useEffect(() => {
    if (!data) return;
    const {correctWords} = data.currentUser.currentCompany;
    setInputValue(correctWords.join('\n'));
  }, [data]);

  if (loading) {
    return (
      <ModalLoading
        title={t('Пожалуйста, подождите', 'Пожалуйста, подождите')}
      />
    );
  }
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const renderInput = () => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    };

    return (
      <Box mt={8}>
        <MediumTextField
          placeholder={t('Добавьте новое слово', 'Добавьте новое слово')}
          value={inputValue}
          myProps={{placeholderFocused: ''}}
          multiline
          rows={10}
          onChange={handleChange}
        />
      </Box>
    );
  };

  const renderButton = () => {
    const handleSave = async () => {
      const words = R.pipe(
        R.split('\n'),
        R.map(v => v.trim()),
        R.filter(v => !R.isEmpty(v)),
        R.uniq,
      )(inputValue);

      await saveCorrectWords({variables: {correctWords: words}});
      navigate(-1);
    };

    return (
      <Box mt={16}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={saveCorrectWordsLoading}
              fullWidth
              onClick={handleSave}
            >
              <Trans>Сохранить</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item className={classes.footer} xs={12} sm={8}>
            <Typography className={classes.footerText}>
              <Trans>
                Добавленные и удаленные слова будут учтены при следующей
                проверке — завтра в 10:00
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <ModalPage header='Пользовательский словарь'>
      <Typography className={classes.text}>
        <Trans>
          Вы можете указать правильное написание слов, добавить названия товаров
          или брендов.
          <br />
          <br />
          Каждое слово должно начинаться с новой строки.
        </Trans>
      </Typography>

      {renderInput()}
      {renderButton()}
    </ModalPage>
  );
};

export default CorrectWords;
