/** @format */
import {useEffect, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import {Box, LinearProgress ,Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {gql, useQuery} from '@apollo/client';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import LegacyTextField from 'components/inputs/LegacyTextField';
import PhoneNumberField from 'components/inputs/PhoneNumberField';

interface currentUser {
  currentUser: {
    phone: string;
    profile?: {
      id: number;
      name?: string;
    }
  }
}

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      phone
      profile {
        id
        name
      }
    }
  }
`;

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    text: {
      color: '#A4A4A4',
    },
  }),
);

const GetHelp = () => {
  const styles = useStyles();
  const [state, setState] = useState({phone: '', name: ''});
  const [sentSuccess, setSentSuccess] = useState(false);

  // const {mixpanel} = useMixpanelContext();
  const {t} = useTranslation();

  const {loading, error, data} = useQuery<currentUser>(GET_CURRENT_USER);

  useEffect(() => {
    if (data != null) {
      const {phone, profile} = data.currentUser;

      const name = profile && profile.name ? profile.name : '';
      setState({phone, name})
    }
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) {
    return <Alert severity='error'>{error}</Alert>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleChangePhone = (value: string) => {
    setState({...state, phone: value});
  };

  const handleClick = async () => {
    await fetch(
      'https://hook.integromat.com/rl2fvk8y0ohs7t5jnvdccqmxa03f1jis',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      }
    )
    setSentSuccess(true);
  };

  return (
    <>
      <Box mb={8}>
        <Typography variant='h1'>
          <Trans>Запрос консультации</Trans>
        </Typography>
      </Box>
      {sentSuccess && (
        <>
          <Typography variant='body1'>
            <Trans>
              Заявка на консультацию отправлена
            </Trans>
          </Typography>
          <Typography variant='body1' className={styles.text}>
            <Trans>
              Мы свяжемся с вами в ближайшее время!
            </Trans>
          </Typography>
        </>
      )}
      {!sentSuccess && (
        <>
          <Typography variant='body1' className={styles.text}>
            <Trans>
              Оставьте заявку, наш менеджер свяжется с вами и поможет
              начать пользоваться сервисом
            </Trans>
          </Typography>
          <Box my={6}>
            <LegacyTextField
              inputProps={{name: 'name'}}
              myProps={{placeholderFocused: ''}}
              placeholder={t('Как вас зовут', 'Как вас зовут')}
              value={state.name}
              onChange={handleChange}
            />
          </Box>
          <Box mb={6}>
            <PhoneNumberField
              value={state.phone}
              onChange={handleChangePhone}
            />
          </Box>
          <PrimaryMediumButton
            disabled={loading || !state.phone || ! state.name}
            onClick={handleClick}
          >
            <Trans>Отправить</Trans>
          </PrimaryMediumButton>
        </>
      )}
    </>
  );
};

export default GetHelp;
