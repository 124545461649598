/** @format */
import {createStyles} from '@material-ui/core/styles';

const styles = theme => createStyles({
  underline: {
    textDecoration: 'underline',
    textDecorationColor: 'rgba(255, 255, 255, 0.3)',
  },
  text: {
    fontSize: 14,
    fontWeight: '400',
  },
  smallText: {
    fontSize: 12,
    fontWeight: '400',
  },
  title: {
    color: '#C4C4C4',
  },
  value: {
    color: theme.palette.text.primary,
  },
});

export default styles;
