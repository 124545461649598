/** @format */

import {Routes, Route} from 'react-router-dom';

import {ROUTES} from 'constants/ROUTES';

import PrivateRoute from 'components/PrivateRoute';

import MainLayout from 'layouts/MainLayout';

import Buttons from './Buttons';
import Dashboard from './Dashboard';
import Inputs from './Inputs';
import Managers from './Managers';
import Menus from './Menus';
import Selects from './Selects';
import Translations from './Translations';

const Dev = () => {
  return (
    <MainLayout>
      <Routes>
        <Route
          path={ROUTES.DEV_TRANSLATIONS}
          element={
            <PrivateRoute>
              <Translations />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEV_MANAGERS}
          element={
            <PrivateRoute>
              <Managers />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEV_INPUTS}
          element={
            <PrivateRoute>
              <Inputs />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEV_BUTTONS}
          element={
            <PrivateRoute>
              <Buttons />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEV_DASHBOARD}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEV_SELECTS}
          element={
            <PrivateRoute>
              <Selects />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEV_MENUS}
          element={
            <PrivateRoute>
              <Menus />
            </PrivateRoute>
          }
        />
      </Routes>
    </MainLayout>
  );
};

export default Dev;
