/** @format */

import {useEffect, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import {Box, LinearProgress, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {useFlags} from 'launchdarkly-react-client-sdk';

import Modal from 'components/Modal';
import ShareLink from 'components/ShareLink';
import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const CREATE_GUEST = gql`
  mutation CreateGuest($companyId: ID!) {
    createGuest(companyId: $companyId) {
      id
      uuid
    }
  }
`;

type Props = {
  onClose: () => void;
};

interface mutationCreateGuest {
  id: number;
  uuid: string;
}

const InviteGuest = (props: Props) => {
  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');

  const {isNewVersionEnabled} = useFlags();

  const {mixpanel} = useMixpanelContext();
  const {t} = useTranslation();

  const [guestUUID, setGuestUUID] = useState('');
  const [createGuestError, setCreateGuestError] = useState('');

  const [createGuest, {data, loading}] = useMutation<{
    createGuest: mutationCreateGuest;
  }>(CREATE_GUEST, {variables: {companyId}});

  useEffect(() => {
    createGuest().catch(e => setCreateGuestError((e as Error).message));
  }, [createGuest]);

  useEffect(() => {
    if (data != null) {
      setGuestUUID(data.createGuest.uuid);
    }
  }, [data]);

  if (loading && !isNewVersionEnabled) {
    return <LinearProgress style={{flex: 1}} />;
  }

  if (createGuestError) {
    return <Alert severity='error'>{createGuestError}</Alert>;
  }

  const handleCopy = () => {
    mixpanel.track('click_copy_invite_guest_url_button');
  };

  const url = () => {
    return `${location.origin}/guest/welcome/${guestUUID}?companyId=${companyId}`;
  };

  return (
    <Modal
      header={t(
        'Если у вас нет доступов от кабинетов',
        'Если у вас нет доступов от кабинетов',
      )}
      onClose={props.onClose}
    >
      <Typography variant='body1'>
        <Trans>
          Скопируйте и отправьте ссылку тому, кто может подключить кабинеты для
          вас. Это может быть ваш специалист, кто ведет рекламные кампании или
          представитель агентства, с кем вы работаете.
        </Trans>
      </Typography>
      <Box my={5}>
        <ShareLink url={url()} variant='LIGHT' onCopy={handleCopy} />
      </Box>
      <Typography variant='body1'>
        <Trans>
          По ссылке специалист увидит интерфейс подключения кабинетов, отчёт он
          сможет получить только если вы настроите отправку уведомлений на
          email.
        </Trans>
      </Typography>
    </Modal>
  );
};

export default InviteGuest;
