/** @format */

import {useState} from 'react';

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {Box, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import {format, parseISO} from 'date-fns';

import BoxPlate from 'components/plates/BoxPlate';
import Legend from './Legend';

const BRIGHT_RED = '#EE4D50';
const LIGHT_RED = '#D9999A';
const LIGHT_GREEN = '#B3C291';
const BRIGHT_GREEN = '#749F23';
const BRIGHT_ORANGE = '#F8A935';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      alignSelf: 'flex-start',
      backgroundColor: '#1A1A1A',
      borderRadius: 5,
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      fontWeight: 400,
      height: 56,
      justifyContent: 'space-between',
      lineHeight: '20px',
      padding: '8px 16px',
      width: 128,
    },
    tooltipValue: {
      color: '#A27AFC',
    },
  }),
);

type ANOMALY =
  | 'NONE'
  | 'HIGH_DECREASE'
  | 'LOW_DECREASE'
  | 'LOW_INCREASE'
  | 'HIGH_INCREASE';

type Item = {
  dateValue: string;
  yValue: number;
};

type ItemWithAnomaly = Item & {anomaly: ANOMALY};

type YAxisProps = {
  domain?: number[];
  ticks?: number[];
  type?: 'number' | 'category';
};

type Props = {
  data: Item[];
  formatYValue: (value: number) => string;
  // inverted if HIGH_INCREASE == good like for clicks
  inverted?: boolean;
  showAnomalies: boolean;
  variant: 'SLIM' | 'NORMAL';
  yAxisProps?: YAxisProps;
  planValue?: number;
};

const DateGraph = (props: Props) => {
  const {inverted = false} = props;

  const {t} = useTranslation();

  const theme = useTheme();
  const [focusedBar, setFocusedBar] = useState(null);

  const anomaly = (value: number, average: number): ANOMALY => {
    if (!props.showAnomalies) return 'NONE';

    if (value <= average * 0.5) return 'HIGH_DECREASE';
    if (value <= average * 0.7) return 'LOW_DECREASE';
    if (value >= average * 2) return 'HIGH_INCREASE';
    if (value >= average * 1.3) return 'LOW_INCREASE';

    return 'NONE';
  };

  const data = R.pipe(
    R.groupBy<Item, string>(v => {
      const date = parseISO(v.dateValue);
      return format(date, 'MM');
    }),
    R.values,
    R.map(group => {
      const averageYValue = R.pipe(
        R.map<Item, number>(v => v.yValue),
        R.sum,
        R.divide(R.__, group.length),
      )(group);

      return group.map(v => ({
        ...v,
        anomaly: anomaly(v.yValue, averageYValue),
      }));
    }),
    R.flatten,
    R.sortBy<Item>(v => v.dateValue),
  )(props.data);

  // BarChart crashs when data == []
  if (data.length === 0) {
    return (
      <Box mt={8}>
        <Alert severity='error'>
          <Trans>Нет данных для построения графика</Trans>
        </Alert>
      </Box>
    );
  }

  const barChartProps = () => {
    if (props.variant === 'SLIM') {
      return {barGap: 12};
    }

    if (props.variant === 'NORMAL') {
      return {barGap: 8};
    }

    return {};
  };

  const barProps = () => {
    if (props.variant === 'SLIM') {
      return {barSize: 5, radius: [8, 8, 8, 8] as any};
    }

    if (props.variant === 'NORMAL') {
      return {radius: [4, 4, 4, 4] as any};
    }

    return {};
  };

  // https://github.com/recharts/recharts/issues/1285#issuecomment-530494511
  const handleMouseMove = (state: any) => {
    if (state.isTooltipActive) {
      setFocusedBar(state.activeTooltipIndex);
    } else {
      setFocusedBar(null);
    }
  };

  const formatDateValue = (dateValue: string) => {
    const date = parseISO(dateValue);
    return format(date, 'dd.MM');
  };

  const cellFill = (item: ItemWithAnomaly, index: number) => {
    if (focusedBar === index) return '#6263E8';

    if (inverted) {
      if (item.anomaly === 'HIGH_DECREASE') return BRIGHT_RED;
      if (item.anomaly === 'LOW_DECREASE') return LIGHT_RED;
      if (item.anomaly === 'LOW_INCREASE') return LIGHT_GREEN;
      if (item.anomaly === 'HIGH_INCREASE') return BRIGHT_ORANGE;
    } else {
      if (item.anomaly === 'HIGH_DECREASE') return BRIGHT_ORANGE;
      if (item.anomaly === 'LOW_DECREASE') return LIGHT_GREEN;
      if (item.anomaly === 'LOW_INCREASE') return LIGHT_RED;
      if (item.anomaly === 'HIGH_INCREASE') return BRIGHT_RED;
    }

    if (props.variant === 'SLIM') return theme.palette.secondary.main;
    if (props.variant === 'NORMAL') return theme.palette.action.active;

    return theme.palette.secondary.main;
  };

  const renderLegend = () => {
    if (!props.showAnomalies) return null;

    const items = [
      {
        text: t('значительное превышение', 'значительное превышение'),
        tooltip: t(
          'Значительное превышение показателя \n(в 2 раза больше среднемесячного значения)',
          'Значительное превышение показателя \n(в 2 раза больше среднемесячного значения)',
        ),
        backgroundColor: inverted ? BRIGHT_ORANGE : BRIGHT_RED,
      },
      {
        text: t('незначительное превышение', 'незначительное превышение'),
        tooltip: t(
          'Незначительное превышение показателя \n(на 30% больше среднемесячного значения)',
          'Незначительное превышение показателя \n(на 30% больше среднемесячного значения)',
        ),
        backgroundColor: inverted ? LIGHT_GREEN : LIGHT_RED,
      },
      {
        text: t('незначительное снижение', 'незначительное снижение'),
        tooltip: t(
          'Незначительное снижение показателя \n(на 30% меньше среднемесячного значения)',
          'Незначительное снижение показателя \n(на 30% меньше среднемесячного значения)',
        ),
        backgroundColor: inverted ? LIGHT_RED : LIGHT_GREEN,
      },
      {
        text: t('значительное снижение', 'значительное снижение'),
        tooltip: t(
          'Значительное снижение показателя \n(в 2 раза меньше среднемесячного значения)',
          'Значительное снижение показателя \n(в 2 раза меньше среднемесячного значения)',
        ),
        backgroundColor: inverted ? BRIGHT_RED : BRIGHT_ORANGE,
      },
    ];
    if (props.planValue != null) {
      items.push(
        {
          text: t('план расхода', 'план расхода'),
          tooltip: t('Траты по медиаплану', 'Траты по медиаплану'),
          backgroundColor: BRIGHT_GREEN,
        },
      )
    }

    return (
      <Box mt={3}>
        <Legend items={items} smGridSize={6} xsGridSize={12} />
      </Box>
    );
  };

  return (
    <>
      <BoxPlate>
        <Box height={200} pb={3} pr={3} pt={3}>
          <ResponsiveContainer height='100%'>
            <BarChart
              data={data}
              onMouseMove={handleMouseMove}
              {...barChartProps()}
            >
              <XAxis
                axisLine={false}
                dataKey='dateValue'
                interval='preserveStartEnd'
                tickLine={false}
                tick={<CustomXTick formatDateValue={formatDateValue} />}
              />
              <YAxis
                axisLine={false}
                dataKey='yValue'
                tick={<CustomYTick formatYValue={props.formatYValue} />}
                tickLine={false}
                {...props.yAxisProps}
              />

              <CartesianGrid stroke='#4D4D4D' vertical={false} />

              <Tooltip
                content={
                  <CustomTooltip
                    formatDateValue={formatDateValue}
                    formatYValue={props.formatYValue}
                  />
                }
                cursor={false}
              />

              <Bar dataKey='yValue' {...barProps()}>
                {data.map((v: ItemWithAnomaly, index: number) => {
                  const fill = cellFill(v, index);
                  return <Cell key={index} fill={fill} />;
                })}
              </Bar>
              {props.planValue && (
                <ReferenceLine
                  y={props.planValue}
                  stroke='#739F23'
                  strokeDasharray='6 6'
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </BoxPlate>

      {renderLegend()}
    </>
  );
};

const CustomXTick = (props: any) => {
  return (
    <text
      dx={-10}
      dy={10}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.formatDateValue(props.payload.value)}
    </text>
  );
};

const CustomYTick = (props: any) => {
  return (
    <text
      dx={-50}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.formatYValue(props.payload.value)}
    </text>
  );
};

// https://stackoverflow.com/questions/65913461
const CustomTooltip = (props: any) => {
  const classes = useStyles();

  if (!props.active) return null;
  if (!props.payload) return null;
  if (!props.payload[0]) return null;

  const {payload} = props.payload[0];

  return (
    <Box className={classes.tooltip}>
      <Typography className={classes.tooltipValue}>
        {props.formatYValue(payload.yValue)}
      </Typography>
      <Typography>{props.formatDateValue(payload.dateValue)}</Typography>
    </Box>
  );
};

export default DateGraph;
