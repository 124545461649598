/** @format */

import {Box, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';

import ButtonPlate from 'components/plates/ButtonPlate';

type Props = {
  description: string;
  found: number;
  new: number;
  title: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.primary,
      fontSize: 18,
      fontWeight: 500,
    },
    description: {
      color: '#8C8D8D',
      fontSize: 14,
      fontWeight: 400,
    },
    rightContainer: {
      minWidth: theme.spacing(26),
    },
    successText: {
      color: theme.palette.success.main,
      fontSize: 14,
      fontWeight: 400,
    },
    foundText: {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
    },
    newText: {
      color: theme.palette.error.main,
      fontSize: 14,
      fontWeight: 400,
    },
  }),
);

const Row = (props: Props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <ButtonPlate onClick={props.onClick}>
      <Box display='flex' flexGrow={1} mr={4} justifyContent='space-between'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
        >
          <Typography className={classes.title}>{props.title}</Typography>
          <Box mt={2}>
            <Typography className={classes.description}>
              {props.description}
            </Typography>
          </Box>
        </Box>

        <Box
          alignItems='flex-end'
          className={classes.rightContainer}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
        >
          {props.found === 0 ? (
            <Typography className={classes.successText}>
              <Trans>Рекомендаций нет</Trans>
            </Typography>
          ) : (
            <Box>
              <Typography align='right' className={classes.foundText}>
                {t('plurals.warnings', {count: props.found})}
              </Typography>
              {props.new > 0 && (
                <Typography align='right' className={classes.newText}>
                  {t('plurals.new_warnings', {count: props.new})}
                </Typography>
              )}
            </Box>
          )}

          <Box mt={4}>
            <img
              alt='Arrow right'
              height='11'
              src={new URL('./images/arrow_right.png', import.meta.url).href}
              width='6'
            />
          </Box>
        </Box>
      </Box>
    </ButtonPlate>
  );
};

export default Row;
