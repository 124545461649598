/** @format */

import * as R from 'ramda';
import {clsx} from 'clsx';
import {Box, Grid, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {withStyles} from '@material-ui/core/styles';

import BoxPlate from 'components/plates/BoxPlate';
import reportHelpers, {
  ADS_SECTION,
  AD_GROUPS_SECTION,
  CAMPAIGNS_SECTION,
  CAMPAIGN_FIELD,
  KEYWORDS_SECTION,
  OBJECT_HOST_FIELD,
  OBJECT_LINK_FIELD,
  SITELINKS_SECTION,
} from 'helpers/reportHelpers';

import Account from './Account';
import Ad from './Ad';
import AdGroup from './AdGroup';
import Campaign from './Campaign';
import Keyword from './Keyword';
import ObjectLink from './ObjectLink';
import ObjectHost from './ObjectHost';
import ShowMore from './ShowMore';
import Sitelink from './Sitelink';
import styles from './styles';

interface Props {
  sensorName: string;
  reportItems: any[];
  isError: boolean;
}

const Card = (props: Props) => {
  const {t} = useTranslation();

  const renderTitle = (title: string) => (
    <Typography className={clsx(props.classes.text, props.classes.title)}>
      {title}
    </Typography>
  );

  const renderGroupField = () => {
    const groupField = reportHelpers.groupField(props.sensorName);

    return (
      <>
        {groupField === CAMPAIGN_FIELD && (
          <Box mt={4}>
            {renderTitle(t('Кампания', 'Кампания'))}
            <Box mt={2}>
              <Campaign
                reportItemGroup={props.reportItems}
                sensorName={props.sensorName}
              />
            </Box>
          </Box>
        )}

        {groupField === OBJECT_LINK_FIELD && (
          <Box mt={4}>
            <ObjectLink
              reportItem={props.reportItems[0]}
              sensorName={props.sensorName}
            />
          </Box>
        )}
        {groupField === OBJECT_HOST_FIELD && (
          <Box mt={4}>
            <ObjectHost
              reportItem={props.reportItems[0]}
              sensorName={props.sensorName}
            />
          </Box>
        )}
      </>
    );
  };

  const renderLocationSections = () => {
    const locationSections = reportHelpers.locationSections(props.sensorName);

    const reportItemGroups = locationField => {
      return R.pipe(
        R.groupBy(R.prop(locationField)),
        R.omit([null]),
        R.values,
      )(props.reportItems);
    };

    return (
      <>
        {locationSections.includes(CAMPAIGNS_SECTION) && (
          <ShowMore
            isError={props.isError}
            render={(vs, comment: string) => (
              <Campaign
                comment={comment}
                reportItemGroup={vs}
                sensorName={props.sensorName}
              />
            )}
            reportItemGroups={reportItemGroups('campaignId')}
            sensorName={props.sensorName}
            title={t('Кампании', 'Кампании')}
          />
        )}

        {locationSections.includes(AD_GROUPS_SECTION) && (
          <ShowMore
            isError={props.isError}
            render={vs => (
              <AdGroup reportItemGroup={vs} sensorName={props.sensorName} />
            )}
            reportItemGroups={reportItemGroups('adGroupId')}
            title={t('Группы объявлений', 'Группы объявлений')}
          />
        )}

        {locationSections.includes(ADS_SECTION) && (
          <ShowMore
            isError={props.isError}
            render={vs => (
              <Ad reportItemGroup={vs} sensorName={props.sensorName} />
            )}
            reportItemGroups={reportItemGroups('adId')}
            sensorName={props.sensorName}
            title={t('Объявления', 'Объявления')}
          />
        )}

        {locationSections.includes(SITELINKS_SECTION) && (
          <ShowMore
            isError={props.isError}
            render={vs => (
              <Sitelink reportItemGroup={vs} sensorName={props.sensorName} />
            )}
            reportItemGroups={reportItemGroups('sitelinkId')}
            sensorName={props.sensorName}
            title={t('Быстрые ссылки', 'Быстрые ссылки')}
          />
        )}

        {locationSections.includes(KEYWORDS_SECTION) && (
          <ShowMore
            isError={props.isError}
            render={vs => (
              <Keyword reportItemGroup={vs} sensorName={props.sensorName} />
            )}
            reportItemGroups={reportItemGroups('keywordId')}
            sensorName={props.sensorName}
            title={t('Ключевые фразы', 'Ключевые фразы')}
          />
        )}
      </>
    );
  };

  return (
    <Grid item xs={12}>
      <BoxPlate>
        <Account
          reportItemGroup={props.reportItems}
          sensorName={props.sensorName}
        />
        {renderGroupField()}
        {renderLocationSections()}
      </BoxPlate>
    </Grid>
  );
};

export default withStyles(styles)(Card);
