/** @format */

import {ChangeEvent} from 'react';
import clsx from 'clsx';

import {Box, MenuItem, Select} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

import Hint from 'components/Hint';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      flexGrow: 1,
      '& .MuiSelect-root': {
        backgroundColor: theme.palette.secondary.main,
        border: '1px solid #DADADA',
        borderRadius: 8,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(3),
      },
      '& .MuiSelect-select': {
        paddingRight: theme.spacing(8),
      },
      '& .MuiSelect-icon': {
        right: 6,
      },
    },
    placeholder: {
      '& .MuiSelect-select': {
        color: '#9C9C9C',
      },
    },
    menuItem: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: '#F0F0F0',
      },
      '&.Mui-selected': {
        backgroundColor: '#E0E0E0',
      },
    },
  }),
);

type Option = {
  value: string;
  text: string;
};

type Props = {
  disabled?: boolean;
  hint?: string;
  placeholder: string;
  options: Option[];
  value: string;
  onSelect: (value: string) => void;
};

const SecondarySelectWithLabel = (props: Props) => {
  const {disabled = false} = props;
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string);
  };

  const cls = [classes.select];
  if (props.value === '') {
    cls.push(classes.placeholder);
  }

  return (
    <>
      <Box display='flex'>
        <Select
          className={clsx(cls)}
          disableUnderline
          disabled={disabled}
          displayEmpty
          value={props.value}
          onChange={handleChange}
        >
          <MenuItem value='' disabled>
            {props.placeholder}
          </MenuItem>
          {props.options.map((v, i) => (
            <MenuItem key={i} className={classes.menuItem} value={v.value}>
              {v.text}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {props.hint && <Hint text={props.hint} />}
    </>
  );
};

export default SecondarySelectWithLabel;
