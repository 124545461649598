/** @format */

import {Avatar} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: '#1E3513',
      height: 44,
      width: 44,
    },
  }),
);

const ConnectedIcon = () => {
  const classes = useStyles();

  return (
    <Avatar className={classes.avatar}>
      <img
        alt='Connected'
        height='11'
        src={new URL('./images/checkmark.png', import.meta.url).href}
        width='14'
      />
    </Avatar>
  );
};

export default ConnectedIcon;
