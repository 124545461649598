/** @format */
import {Box, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';

const Translations = () => {
  const {t} = useTranslation();
  const accounts = 1;

  const dashSign = '!!!';

  return (
    <Box>
      <Box>
        <Typography>Plural</Typography>
        <Trans i18nKey='plurals.accounts' count={accounts} />
        <Typography>{t('plurals.accounts', {count: accounts})}</Typography>
      </Box>
      <Box>
        <Typography>interpolation</Typography>
        <Trans>{{dashSign}} Удалить проект</Trans>
        <Typography>
          {t(
            '{{dashSign}}  Удалить проект',
            '{{dashSign}}  Удалить проект',
            {dashSign},
          )}
        </Typography>
      </Box>
      <Box>
        <Trans>
          Ошибка получения доступа к рекламному кабинету.
          <br />
          <br />
          Вероятно, вы нажали кнопку &quot;Отмена&quot; в момент, когда
          AdSensor попросил доступ. Подключите рекламный аккаунт ещё раз и
          разрешите доступ приложению AdSensor.
        </Trans>
      </Box>
    </Box>
  );
};

export default Translations;
