/** @format */
import {Box} from '@material-ui/core';

import JuliaBot from 'components/managers/JuliaBot';

const Managers = () => {

  return (
    <Box>
      <JuliaBot />
    </Box>
  );
};

export default Managers;

