/** @format */

import {clsx} from 'clsx';
import {Box, Link, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import {NotificationChannelType} from 'types';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: 'normal',
    lineHeight: '23px',
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '18px',
    whiteSpace: 'pre-line',
  },
  email: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'underline',
    verticalAlign: 'baseline',
  },
}));

interface notification {
  channelType: NotificationChannelType;
  emailChannel?: {
    email: string;
  };
}

interface Props {
  notifications: notification[];
}

const Emails = (props: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const emailNotifications = props.notifications.filter(
    v => v.channelType === 'EMAIL',
  );

  const handleClick = () => {
    navigate('/home/settings/specialists', {replace: true});
  };

  const renderNotifications = () => {
    return (
      <>
        <Typography className={classes.text}>
          <Trans>
            Кроме того, отчёты в этих форматах ежедневно отправляются на почту:
          </Trans>
        </Typography>

        <Box mt={2}>
          {emailNotifications.map((v, i) => (
            <Typography key={i} className={clsx(classes.text, classes.email)}>
              {v.emailChannel?.email}
            </Typography>
          ))}
        </Box>

        <Box mt={2}>
          <Typography className={classes.text} display='inline'>
            <Trans>
              Поменять почту можно в{' '}
              <Link
                className={classes.link}
                component='button'
                onClick={handleClick}
              >
                <Typography
                  className={clsx(classes.text, classes.link)}
                  display='inline'
                >
                  Настройках
                </Typography>
              </Link>
            </Trans>
          </Typography>
        </Box>
      </>
    );
  };

  const renderNoNotifications = () => {
    return (
      <>
        <Typography className={classes.title}>
          <Trans>Или настройте автоматическую отправку</Trans>
        </Typography>

        <Box mt={1}>
          <Typography className={classes.text}>
            <Trans>
              Добавьте почту исполнителя, он будет получать отчёты ежедневно
            </Trans>
          </Typography>
        </Box>

        <Box mt={5}>
          <PrimaryMediumButton onClick={handleClick}>
            <Trans>Настроить</Trans>
          </PrimaryMediumButton>
        </Box>
      </>
    );
  };

  return (
    <Box mt={22}>
      {emailNotifications.length > 0 && renderNotifications()}
      {emailNotifications.length === 0 && renderNoNotifications()}
    </Box>
  );
};

export default Emails;
