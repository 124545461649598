/** @format */

import Alert from '@material-ui/lab/Alert';
import {Box, LinearProgress, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {add, format, parseISO} from 'date-fns';
import {gql, useQuery} from '@apollo/client';
// prettier-ignore
import {ru} from 'date-fns/locale';

import BackHeader from 'components/BackHeader';
import ConnectedProviders from 'components/icons/ConnectedProviders';

import numberHelpers from 'helpers/numberHelpers';

import NoSubscription from './NoSubscription';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      tokensCount
      companies {
        name
        tokens {
          provider
        }
      }
      subscription {
        activeSubscription
        endDate
        accounts
      }
      currentAccountsPriceInfo {
        price
        count
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '20px',
    },
    totalPrice: {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '22px',
    },
    companiesContainer: {
      display: 'flex',
      marginTop: theme.spacing(14),
      overflowX: 'scroll',
    },
    companyName: {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
  }),
);

type Token = {
  provider: 'YANDEX' | 'GOOGLE' | 'FACEBOOK' | 'VKONTAKTE';
};

type Company = {
  name: string;
  tokens: Token[];
};

const Subscription = () => {
  const classes = useStyles();

  const {loading, error, data} = useQuery(GET_CURRENT_USER);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {tokensCount, companies, subscription} = data.currentUser;
  const {price, count} = data.currentUser.currentAccountsPriceInfo;

  if (!subscription.activeSubscription) {
    return <NoSubscription />;
  }

  const renderRow = (title: string, value: string | number | null) => {
    return (
      <Box display='flex' flexGrow={1} justifyContent='space-between' mt={2}>
        <Typography variant='body1'>{title}</Typography>
        <Typography className={classes.value}>{value}</Typography>
      </Box>
    );
  };

  const endDate = parseISO(subscription.endDate);
  const nextPaymentDate = add(endDate, {days: 1});
  const formattedNextPaymentDate = format(nextPaymentDate, 'd MMMM', {
    locale: ru,
  });

  return (
    <>
      <Box mb={10}>
        <BackHeader />
      </Box>
      <Typography variant='h1'>{'Информация о подписке'}</Typography>

      <Box mt={6}>
        {renderRow('Дата следующего списания', formattedNextPaymentDate)}
        {renderRow('Количество оплаченных кабинетов', subscription.accounts)}
        {renderRow('Количество подключенных кабинетов', tokensCount)}
      </Box>

      <Box mt={6}>
        {renderRow(
          'Стоимость проверки кабинета за месяц',
          numberHelpers.formatRUB(price / count),
        )}
      </Box>

      <Box display='flex' flexGrow={1} justifyContent='space-between' mt={3}>
        <Typography className={classes.value}>Итого:</Typography>
        <Typography className={classes.totalPrice}>
          {numberHelpers.formatRUB(price)}
        </Typography>
      </Box>

      <Box className={classes.companiesContainer}>
        {companies
          .filter((v: Company) => v.tokens.length > 0)
          .map((v: Company, i: number) => (
            <Box
              key={i}
              display='flex'
              flexDirection='column'
              ml={i === 0 ? 0 : 8}
            >
              <Typography className={classes.companyName}>{v.name}</Typography>
              <Box mt={2}>
                <ConnectedProviders
                  adTypes={['CONTEXT', 'TARGET']}
                  size={40}
                  tokens={v.tokens}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default Subscription;
