/** @format */

import {Grid, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate, useLocation} from 'react-router-dom';

import Modal from 'components/Modal';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import connectHelpers from 'helpers/connectHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: '400',
    lineHeight: '23px',
  },
  body: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line',
  },
}));

const BeforeConnect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = routerHelpers.useSearchParams();
  const companyId = searchParams.get('companyId');
  const provider = searchParams.get('provider');

  const {t} = useTranslation();

  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();

  const renderWarning = () => {
    const providerName = t(`provider_names.${provider}`);

    return (
      <Typography className={classes.body} variant='body1'>
        <Trans>
          После клика на кнопку “Продолжить” вы перейдете на страницу{' '}
          {{providerName}}, где нужно будет ввести адрес электронной почты, на
          который открыт доступ к рекламным кампаниям, и пароль.
          <br />
          <br />
          Если у вас нет этого адреса электронной почты, вы можете запросить его
          у вашего специалиста по маркетингу или агентства. Уровень доступов:
          «на чтение» и выше.
        </Trans>
      </Typography>
    );
  };

  const renderButtons = () => {
    const getState = () => {
      const {pathname, search} = location.state.background;
      const path = `${pathname}${search}`;
      const url = new URL(path, window.location.href);
      url.searchParams.append('confirm', provider);

      const returnUrl = url.toString();
      const state = {companyId, returnUrl};

      return window.btoa(JSON.stringify(state));
    };

    const handleContinue = () => {
      mixpanel.track('click_wizard_before_connect_continue_button', {provider});

      const authUrl = connectHelpers.authUrl(provider, getState());
      if (!authUrl) return;

      window.location.href = authUrl.href;
    };

    const handleCancel = async () => {
      mixpanel.track('click_wizard_before_connect_cancel_button', {provider});
      navigate(-1);
    };

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <PrimaryMediumButton fullWidth onClick={handleContinue}>
            <Trans>Продолжить</Trans>
          </PrimaryMediumButton>
        </Grid>

        <Grid item xs={12} sm={5}>
          <SecondaryMediumButton fullWidth onClick={handleCancel}>
            <Trans>Отмена</Trans>
          </SecondaryMediumButton>
        </Grid>
      </Grid>
    );
  };

  const pageTitle = () => {
    if (provider === 'METRIKA') {
      return t(
        'Подключение кабинета Яндекс.Метрики',
        'Подключение кабинета Яндекс.Метрики',
      );
    }
    return t(
      'Подключение рекламного кабинета',
      'Подключение рекламного кабинета',
    );
  };

  return (
    <Modal header={pageTitle()} showCloseIcon={false} footer={renderButtons()}>
      {renderWarning()}
    </Modal>
  );
};

export default BeforeConnect;
