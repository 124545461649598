/** @format */

import {ChangeEvent, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import {Box, LinearProgress, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';

import BackHeader from 'components/BackHeader';
import MediumTextField from 'components/inputs/MediumTextField';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import QrCode from 'react-qr-code';

const CODE_LENGTH = 6;

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      phone
      has2fa
      secret
    }
  }
`;

const ENABLE_2FA = gql`
  mutation Enable2FA($secret: String!, $code: String!) {
    enable2fa(secret: $secret, code: $code) {
      id
    }
  }
`;

const DISABLE_2FA = gql`
  mutation Disable2FA {
    disable2fa {
      id
    }
  }
`;

const TwoFactorAuth = () => {
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const {t} = useTranslation();

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER);
  const [enable2Fa, {loading: enable2FaLoading}] = useMutation(ENABLE_2FA);
  const [disable2Fa, {loading: disable2FaLoading}] = useMutation(DISABLE_2FA);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {phone, has2fa, secret} = data.currentUser;

  const renderQrCode = () => {
    // https://hexdocs.pm/nimble_totp/NimbleTOTP.html#otpauth_uri/3
    const label = `AdSensor:${phone}`;
    const query = `secret=${secret}&issuer=AdSensor`;
    const otpauthUri = `otpauth://totp/${label}?${query}`;

    // qr code should have "quit zone"
    // https://qrworld.wordpress.com/2011/08/09/the-quiet-zone/
    return (
      <Box display='block' p={2} bgcolor="white">
        <QrCode value={otpauthUri} />
      </Box>
    );
  };

  const renderCodeInput = () => {
    if (has2fa) return null;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCode(event.target.value);
    };

    return (
      <MediumTextField
        error={Boolean(codeError)}
        helperText={codeError}
        inputProps={{maxLength: CODE_LENGTH}}
        placeholder={t('Код аутентификации', 'Код аутентификации')}
        value={code}
        onChange={handleChange}
      />
    );
  };

  const renderButton = () => {
    const handleDisable = async () => {
      await disable2Fa();
      refetch();
    };

    const handleEnable = async () => {
      try {
        await enable2Fa({variables: {secret, code}});
        refetch();
      } catch (e) {
        setCodeError((e as Error).message);
      }
    };

    if (has2fa) {
      return (
        <PrimaryMediumButton
          disabled={disable2FaLoading}
          onClick={handleDisable}
        >
          <Trans>Отключить</Trans>
        </PrimaryMediumButton>
      );
    }

    return (
      <Box mt={4}>
        <PrimaryMediumButton
          disabled={enable2FaLoading || !code || code.length !== CODE_LENGTH}
          onClick={handleEnable}
        >
          <Trans>Включить</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  return (
    <>
      <Box mb={10}>
        <BackHeader />
      </Box>
      <Typography variant='h1'>
        <Trans>Безопасность</Trans>
      </Typography>

      <Box mt={6}>
        {has2fa ? (
          <Typography variant='body1'>
            <Trans>У вас подключена двухфакторная аутентификация.</Trans>
          </Typography>
        ) : (
          <Typography variant='body1'>
            <Trans>
              У вас отключена двухфакторная аутентификация.
              <br />
              <br />
              Для подключения двухфакторной аутентификации отсканируйте QR код с
              помощью вашего приложения для двухфакторной аутентификации и
              введите код аутентификации в поле ввода.
            </Trans>
          </Typography>
        )}
      </Box>

      <Box display='flex' justifyContent='center' mt={10}>
        {renderQrCode()}
      </Box>

      <Box mt={10}>
        {renderCodeInput()}
        {renderButton()}
      </Box>
    </>
  );
};

export default TwoFactorAuth;
