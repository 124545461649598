/** @format */

import clsx from 'clsx';
import {Radio, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  icon: {
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    height: 24,
    paddingTop: 2,
    width: 24,
  },
  iconDark: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderColor: theme.palette.action.active,
  },
  iconLight: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: '#E0E0E0',
  },
  checkedIcon: {
    backgroundColor: theme.palette.success.main,
    borderWidth: 0,
    '&:before': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '50%',
      content: '""',
      display: 'block',
      height: 12,
      margin: 6,
      width: 12,
    },
  },
  iconSquare: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(2),
    border: '1px solid #2B2B2B',
    background: 'transparent',
    'input:hover ~ &': {
      backgroundColor: '#4D4D4D',
    },
  },
}));

// https://material-ui.com/components/radio-buttons/#customized-radios
const GreenRadio = props => {
  const classes = useStyles();
  const {iconVariant, variant, ...rest} = props;

  const iconStyle = () => {
    if (variant === 'DARK') {
      return clsx(classes.icon, classes.iconDark);
    }

    if (variant === 'SQUARE') {
      return clsx(classes.icon, classes.iconSquare);
    }

    if (variant === 'LIGHT') {
      return clsx(classes.icon, classes.iconLight);
    }

    return null;
  };

  const checkedIcon = () => {
    if (variant === 'SQUARE') {
      return (
        <Box className={classes.iconSquare}>
          <CheckIcon fontSize='small' htmlColor='#FFF' />
        </Box>
      );
    }

    if (iconVariant === 'circle') {
      return <span className={clsx(classes.icon, classes.checkedIcon)} />;
    }

    if (iconVariant === 'check') {
      return (
        <span className={iconStyle()} position='relative'>
          <img
            alt='Icon'
            height='12'
            src={new URL('./images/check.png', import.meta.url)}
            width='12'
          />
        </span>
      );
    }

    return null;
  };

  return (
    <Radio
      checkedIcon={checkedIcon()}
      className={classes.root}
      icon={<span className={iconStyle()} />}
      {...rest}
    />
  );
};

GreenRadio.defaultProps = {
  iconVariant: 'circle',
  variant: 'DARK',
};

export default GreenRadio;
