/** @format */

import * as R from 'ramda';

const MAPPING = {
  YANDEX: 'CONTEXT',
  GOOGLE: 'CONTEXT',
  FACEBOOK: 'TARGET',
  VKONTAKTE: 'TARGET',
  VKONTAKTE_ADS: 'TARGET',
  METRIKA: 'CONTEXT',
};

const ALL_PLATFORMS = [
  'GOOGLE',
  'FACEBOOK',
  'YANDEX',
  'VKONTAKTE',
  'VKONTAKTE_ADS',
  'METRIKA',
];

const RuPlatforms = ['YANDEX', 'VKONTAKTE', 'VKONTAKTE_ADS'];

const platformsFromAdType = adType => {
  return Object.entries(MAPPING)
    .filter(v => v[1] === adType)
    .map(v => v[0]);
};

const filterAdTypeFromPlatforms = (tokens, adType) => {
  return R.pipe(
    R.map(v => ({
      ...v,
      adType: MAPPING[v.provider],
    })),
    R.filter(v => v.adType === adType),
  )(tokens);
};

const initialAdType = tokens => {
  const provider = Object.keys(MAPPING).find(v =>
    userProviders(tokens).includes(v),
  );

  // Return null (not undefined) - this value will be saved
  // to local storage and parsed in CompanyProvider
  return MAPPING[provider] || null;
};

const isAdTypeConnected = (tokens, adType) => {
  return userProviders(tokens).some(v =>
    platformsFromAdType(adType).includes(v),
  );
};

const filterPlatformsByTokens = (tokens, platforms) =>
  platforms.filter(v => tokens.find(token => token.provider === v));

const userProviders = tokens => {
  return R.pipe(
    R.filter(v => v && !v.lastError),
    R.map(v => v.provider),
  )(tokens);
};

const allAdTypes = () => {
  return ['CONTEXT', 'TARGET'];
};

const rejectRuPlatforms = platforms => {
  return platforms.filter(v => !RuPlatforms.includes(v));
};

export default {
  ALL_PLATFORMS,
  allAdTypes,
  filterPlatformsByTokens,
  filterAdTypeFromPlatforms,
  initialAdType,
  isAdTypeConnected,
  platformsFromAdType,
  rejectRuPlatforms,
};
