/** @format */

import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {gql, useMutation} from '@apollo/client';

import Alert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Box, Hidden, IconButton, Link} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import logoImg from 'images/logo__white.svg';

import NativeMenuNew from 'components/menus/NativeMenuNew';

import {useAuthContext} from 'contexts/AuthContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  menuHeader: {
    background: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    width: '100vw',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  menu: {
    display: 'flex',
    width: '100%',
    marginRight: 0,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100vw',
    },
  },
  menuIcon: {
    fontSize: '16px',
  },
}));

const SIGN_OUT = gql`
  mutation SignOut {
    signOut {
      id
    }
  }
`;

interface Props {
  onToggleDrawer?: () => void;
}

const TopMenu = (props: Props) => {
  const classes = useStyles();

  const {pathname} = useLocation();
  const {t} = useTranslation();

  const authContext = useAuthContext();
  const {mixpanel} = useMixpanelContext();

  const [signOut, {error: signOutError}] = useMutation(SIGN_OUT);

  if (signOutError) {
    return <Alert severity='error'>{signOutError.message}</Alert>;
  }

  const handleLogout = async () => {
    mixpanel.track('click_sign_out_button');

    try {
      await signOut();
      await authContext.signOut();
      // NOTE: temporary replace to explicit page reload for avoiding switch theme bug
      // history.push('/');
      location.replace('/');
    } catch (e) {
      console.error(e);
    }
  };

  const rightItems = [
    {
      path: '',
      icon: <AppsIcon className={classes.menuIcon} />,
      name: 'PROJECTS',
      text: t('Проекты', 'Проекты'),
    },
    {
      path: '/home/settings',
      icon: <SettingsOutlinedIcon className={classes.menuIcon} />,
      name: 'SETTINGS',
      text: t('Настройки', 'Настройки'),
    },
    {
      path: '/home/settings/profile',
      icon: <AccountCircleOutlinedIcon className={classes.menuIcon} />,
      name: 'ACCOUNT',
      text: t('Аккаунт', 'Аккаунт'),
    },
    {
      path: '',
      icon: <ExitToAppIcon className={classes.menuIcon} />,
      name: 'EXIT',
      onClick: handleLogout,
      text: t('Выход', 'Выход'),
    },
  ];

  const activeItem = rightItems.find(v => pathname === v.path) || rightItems[0];

  return (
    <Box>
      {props.onToggleDrawer && (
        <Box className={classes.menuHeader}>
          <Hidden mdUp>
            <Box
              alignItems='center'
              display='flex'
              justifyContent='space-between'
              mb={7}
            >
              <Link href='/'>
                <img
                  alt='AdSensor Logo'
                  height='26'
                  src={logoImg}
                  width='115'
                />
              </Link>
              <IconButton
                color='inherit'
                edge='start'
                onClick={props.onToggleDrawer}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Hidden>
        </Box>
      )}

      <Box className={classes.menuHeader}>
        <Container maxWidth='md'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            {!props.onToggleDrawer && (
              <Link href='/'>
                <img
                  alt='AdSensor Logo'
                  height='26'
                  src={logoImg}
                  width='115'
                />
              </Link>
            )}

            <NativeMenuNew
              className={classes.menu}
              activeItem={activeItem}
              rightItems={rightItems}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default TopMenu;
