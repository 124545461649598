/** @format */

import {ChangeEvent, useEffect, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import {Box, Grid, Typography} from '@material-ui/core';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import MediumTextField from 'components/inputs/MediumTextField';
import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import routerHelpers from 'helpers/routerHelpers';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($id: ID) {
    currentUser {
      specialist(id: $id) {
        id
        name
        email
        notifications {
          id
        }
      }
    }
  }
`;

export const CREATE_SPECIALIST = gql`
  mutation CreateSpecialist($name: String!, $email: String) {
    createSpecialist(name: $name, email: $email) {
      id
    }
  }
`;

export const UPDATE_SPECIALIST = gql`
  mutation UpdateSpecialist($id: ID!, $name: String!, $email: String) {
    updateSpecialist(id: $id, name: $name, email: $email) {
      id
    }
  }
`;

const DELETE_SPECIALIST = gql`
  mutation DeleteSpecialist($id: ID!) {
    deleteSpecialist(id: $id)
  }
`;

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    hint: {
      color: '#8C8D8D',
      lineHeight: '18px',
    },
  }),
);

export const SpecialistForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const searchParams = routerHelpers.useSearchParams();
  const id = searchParams.get('id');

  const {t} = useTranslation();

  const [state, setState] = useState({name: '', email: ''});
  const [saveSpecialistError, setSaveSpecialistError] = useState('');

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    variables: {id},
  });
  const [createSpecialist, {loading: createSpecialistLoading}] =
    useMutation(CREATE_SPECIALIST);
  const [updateSpecialist, {loading: updateSpecialistLoading}] =
    useMutation(UPDATE_SPECIALIST);
  const [deleteSpecialist, {loading: deleteSpecialistLoading}] =
    useMutation(DELETE_SPECIALIST);

  useEffect(() => {
    if (!data) return;
    if (!data.currentUser.specialist) return;

    setState({
      name: data.currentUser.specialist.name,
      email: data.currentUser.specialist.email,
    });
  }, [data]);

  if (loading) return <ModalLoading title='Пожалуйста, подождите' />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {specialist} = data.currentUser;

  const renderForm = () => {
    const handleChange = (
      event: ChangeEvent<{name: string; value: string}>,
    ) => {
      const {name, value} = event.target;
      setState({...state, [name]: value});
    };

    return (
      <Box mt={6}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={10}>
            <MediumTextField
              error={Boolean(saveSpecialistError)}
              placeholder={t('Имя специалиста', 'Имя специалиста')}
              value={state.name}
              inputProps={{name: 'name'}}
              myProps={{placeholderFocused: ''}}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            <MediumTextField
              error={Boolean(saveSpecialistError)}
              placeholder={t('E-mail', 'E-mail')}
              value={state.email}
              inputProps={{name: 'email'}}
              myProps={{placeholderFocused: ''}}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant='h2'>
              <Trans>Уведомления в Telegram</Trans>
            </Typography>
            <Box mt={3}>
              <Typography variant='body2' className={classes.hint}>
                <Trans>
                  Ссылка для подлючения бота будет доступна в настройках
                  уведомлений для пользователя после его создания.
                </Trans>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderButton = () => {
    const disabled =
      !state.name ||
      createSpecialistLoading ||
      updateSpecialistLoading ||
      deleteSpecialistLoading;

    const handleSave = async () => {
      try {
        if (specialist) {
          await updateSpecialist({
            variables: {
              id: specialist.id,
              name: state.name,
              email: state.email,
            },
          });
        } else {
          await createSpecialist({
            variables: {name: state.name, email: state.email},
          });
        }

        navigate(-1);
      } catch (e) {
        setSaveSpecialistError((e as Error).message);
      }
    };

    const handleDelete = async () => {
      await deleteSpecialist({variables: {id: specialist.id}});
      navigate('/home/settings/specialists', {replace: true});
    };

    return (
      <Box mt={10}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <PrimaryMediumButton disabled={disabled} onClick={handleSave}>
              <Trans>Сохранить</Trans>
            </PrimaryMediumButton>
          </Grid>

          {specialist && specialist.notifications.length === 0 && (
            <Grid item xs={12} sm={6}>
              <SecondaryMediumButton disabled={disabled} onClick={handleDelete}>
                <Trans>Удалить</Trans>
              </SecondaryMediumButton>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  const header = () => {
    if (specialist) {
      return t('Редактирование специалиста', 'Редактирование специалиста');
    }

    return t('Создание специалиста', 'Создание специалиста');
  };

  return (
    <ModalPage header={header()}>
      {renderForm()}
      {renderButton()}
    </ModalPage>
  );
};
