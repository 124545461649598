/** @format */

import {useState} from 'react';

import clsx from 'clsx';
import {Box, Link, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {withStyles} from '@material-ui/core/styles';

import Hr from 'components/SidebarMenu/Hr';
import styles from './styles';

const MAX_REPORT_ITEMS = 2;

const useStyles = makeStyles(theme => ({
  linkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.success.main,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: '17px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.success.main,
    },
  },
}));

const ShowMore = props => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  const {t} = useTranslation();
  if (props.reportItemGroups.length === 0) return null;

  const reportItemGroups = showAll
    ? props.reportItemGroups
    : props.reportItemGroups.slice(0, MAX_REPORT_ITEMS);

  const moreCount = props.reportItemGroups.length - MAX_REPORT_ITEMS;
  const showMoreLink = !showAll && moreCount > 0;

  const handleShowMore = () => setShowAll(true);
  const handleShowLess = () => setShowAll(false);

  // https://reactjs.org/docs/render-props.html
  //
  // https://stackoverflow.com/a/43882888/3632318
  // Add display='flex' to outer Box to avoid margin collapsing
  return (
    <Box mt={4}>
      <Typography className={clsx(props.classes.text, props.classes.title)}>
        {props.title}
      </Typography>

      <Box display='flex' flexDirection='column'>
        {reportItemGroups.map((vs, i) => {
          const comment = props.isError
            ? t('plurals.errors', {count: vs.length})
            : t('plurals.warnings', {count: vs.length});

          return (
            <Box
              key={i}
              display='flex'
              flexDirection='column'
              mt={i === 0 ? 2 : 0}
            >
              <Box>{props.render(vs, comment)}</Box>
              {i < reportItemGroups.length - 1 && <Hr />}

              {i === MAX_REPORT_ITEMS - 1 && showMoreLink && (
                <Link
                  className={classes.linkContainer}
                  component='button'
                  onClick={handleShowMore}
                >
                  <Typography className={classes.link}>
                    <Trans>Ещё {{moreCount}}</Trans>
                  </Typography>
                </Link>
              )}

              {i === reportItemGroups.length - 1 && showAll && (
                <Link
                  className={classes.linkContainer}
                  component='button'
                  onClick={handleShowLess}
                >
                  <Box ml={2}>
                    <Typography className={classes.link}>
                      <Trans>Свернуть</Trans>
                    </Typography>
                  </Box>
                </Link>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ShowMore);
