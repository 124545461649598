/** @format */

import {ChangeEvent} from 'react';

import {MenuItem, Select} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // Disable hover
    menuItem: {
      '&:hover': {
        // theme.js: MuiMenu.paper.backgroundColor
        backgroundColor: '#4A4A4A',
      },
      '&.Mui-selected': {
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  }),
);

type Option = {
  value: string;
  text: string;
};

type Props = {
  options: Option[];
  value: string;
  onSelect: (id: string) => void;
};

const PrimarySelect = (props: Props) => {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string);
  };

  return (
    <Select disableUnderline value={props.value} onChange={handleChange}>
      {props.options.map((v, i) => (
        <MenuItem key={i} className={classes.menuItem} value={v.value}>
          {v.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PrimarySelect;
