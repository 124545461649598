/** @format */

import clsx from 'clsx';
import {Box, ButtonBase, Grid} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  item: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: '17px',
  },
  activeItem: {
    color: theme.palette.success.main,
  },
}));

const NativeMenu = props => {
  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();

  const handleClick = name => () => {
    const lcName = name.toLowerCase();
    mixpanel.people.increment(`click_${lcName}_top_menu_count`);
    mixpanel.track(`click_${lcName}_top_menu`);
  };

  const renderItems = (items, alignRight = false) => (
    <Grid
      container
      justifyContent={alignRight ? 'flex-end' : 'flex-start'}
      spacing={8}
      className={props.className}
    >
      {items.map((v, i) => {
        const isActiveItem = props.activeItem
          ? props.activeItem.name === v.name
          : false;

        const className = clsx(classes.item, {
          [classes.activeItem]: isActiveItem,
        });

        return (
          <Grid key={i} item>
            <ButtonBase
              className={className}
              component={RouterLink}
              disableRipple
              to={v.path}
              onClick={handleClick(v.name)}
            >
              {v.text}
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <Box display='flex' justifyContent='space-between' width={'100%'}>
      {props.leftItems && renderItems(props.leftItems)}
      {props.rightItems && renderItems(props.rightItems, true)}
    </Box>
  );
};

export default NativeMenu;
