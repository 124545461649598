/** @format */

import {Link as RouterLink} from 'react-router-dom';
import {ListItem, ListItemText} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {useMixpanelContext} from 'contexts/MixpanelContext';

type Props = {
  link: string;
  mixpanelEventName: string;
  children: React.ReactNode;
};

const useStyles = makeStyles(theme => ({
  listItem: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 8,
    color: 'white',
    height: 56,
    marginBottom: theme.spacing(4),
  },
}));

const Row = (props: Props) => {
  const classes = useStyles();

  const {mixpanel} = useMixpanelContext();

  const handleClick = () => {
    mixpanel.people.increment(`${props.mixpanelEventName}_count`);
    mixpanel.track(props.mixpanelEventName);
  };

  return (
    <ListItem
      className={classes.listItem}
      component={RouterLink}
      to={props.link}
      onClick={handleClick}
    >
      <ListItemText primaryTypographyProps={{variant: 'body2'}}>
        {props.children}
      </ListItemText>
      <img
        alt='Arrow'
        src={new URL('./images/arrow_right.svg', import.meta.url)}
      />
    </ListItem>
  );
};

export default Row;
