/** @format */
import {useEffect, useState, ChangeEvent} from 'react';

import {CircularProgress, IconButton, InputAdornment, Typography} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import {useTranslation} from 'react-i18next';

import {AuthTokenType} from 'types';
import LegacyTextField from 'components/inputs/LegacyTextField';

interface Props {
  companyName: string
  authTokenType: AuthTokenType
  edited: boolean
  loading: boolean
  onSave(name: string): void
}

export const CompanyName = (props: Props) => {
  const [companyNameValue, setCompanyNameValue] = useState(props.companyName);

  const {t} = useTranslation();

  useEffect(() => {
    setCompanyNameValue(props.companyName);
  }, [props.companyName]);

  if (props.authTokenType === 'GUEST') {
    return (
      <Typography variant='h2'>{props.companyName}</Typography>
    );
  }

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    setCompanyNameValue(event.target.value as string);
  };

  const handleBlur = async () => {
    if (props.companyName === companyNameValue) return;

    props.onSave(companyNameValue);
  };

  const renderEndAdornment = () => {
    if (props.loading) {
      return (
        <InputAdornment position='end'>
          <CircularProgress />
        </InputAdornment>
      );
    }
    if (props.edited) {
      return (
        <InputAdornment position='end'>
          <IconButton edge='end' aria-label='name saved'>
            <DoneIcon htmlColor='green'/>
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <LegacyTextField
      placeholder={t('Имя проекта', 'Имя проекта')}
      inputProps={{
        'aria-label': 'company-name-input',
        'readOnly': props.loading,
      }}
      value={companyNameValue}
      InputProps={{
        endAdornment: renderEndAdornment()
      }}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
