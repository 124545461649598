/** @format */

import {ChangeEvent} from 'react';

import clsx from 'clsx';
import {Box, MenuItem, Select} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      flexGrow: 1,
      width: (props: Props) => props.width,
      '& .MuiSelect-root': {
        backgroundColor: theme.palette.secondary.main,
        border: '1px solid #DADADA',
        borderRadius: 8,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(3),
      },
      '& .MuiSelect-select': {
        paddingRight: theme.spacing(8),
      },
      '& .MuiSelect-icon': {
        right: 6,
      },
    },
    menuItem: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: '#F0F0F0',
      },
      '&.Mui-selected': {
        backgroundColor: '#E0E0E0',
      },
    },
    icon: {
      position: 'absolute',
      left: '16px',
      top: '14px',
      bottom: 0,
      margin: 'auto',
      height: '20px',
    },
  }),
);

type Option = {
  value: string;
  text: string;
};

type Props = {
  disabled?: boolean;
  options: Option[];
  selectClassName?: string;
  icon?: React.ReactNode;
  value: string;
  placeholder?: string | null;
  width?: number;
  onSelect: (value: string) => void;
  'aria-label'?: string;
};

const SecondarySelect = (props: Props) => {
  const {disabled = false} = props;
  const classes = useStyles(props);

  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    props.onSelect(event.target.value as string);
  };

  return (
    <Box display='flex' position='relative'>
      {props.icon && <Box className={classes.icon}>{props.icon} </Box>}
      <Select
        className={clsx(classes.select, props.selectClassName)}
        disableUnderline
        disabled={disabled}
        value={props.value}
        placeholder={props.placeholder || undefined}
        aria-label={props['aria-label']}
        onChange={handleChange}
      >
        {props.options.map((v, i) => (
          <MenuItem key={i} className={classes.menuItem} value={v.value}>
            {v.text}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SecondarySelect;
