/** @format */

import {withStyles} from '@material-ui/core/styles';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const TransparentMediumButton = withStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(props => <PrimaryMediumButton {...props} />);

export default TransparentMediumButton;
