/** @format */

import {
  Backdrop,
  Box,
  ButtonBase,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate, useLocation} from 'react-router-dom';

import constants from 'helpers/constants';
import exclamationImgUrl from 'images/exclamation.png';
import {sensorName} from 'helpers/sensor_names';

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: 8,
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    paddingRight: theme.spacing(4),
    position: 'relative',
  },
  avatar: {
    minWidth: 16,
  },
  badge: {
    backgroundColor: '#8F8F8F',
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: theme.spacing(2),
  },
  title: {
    color: '#8F8F8F',
    fontSize: 18,
    fontWeight: '400',
    lineHeight: '21px',
    paddingRight: theme.spacing(2),
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '17px',
  },
  backdrop: {
    backgroundColor: theme.custom_palette.backdrop.main,
    position: 'absolute',
    zIndex: 1,
  },
}));

const NeedRegionsRow = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();

  const {t} = useTranslation();

  const handleClick = () => {
    props.onClick(props.check);
  };

  const handleClickRegions = event => {
    event.stopPropagation();

    navigate({
      pathname: '/home/main/regions',
      state: {background: location},
    });
  };

  return (
    <Box position='relative'>
      <ListItem className={classes.row} onClick={handleClick}>
        <Box className={classes.avatar} />
        <Box className={classes.badge}>{constants.ZWSP}</Box>

        <ListItemText
          disableTypography
          primary={
            <Typography className={classes.title}>
              {t(sensorName(props.check.sensor.name))}
            </Typography>
          }
        />

        <ListItemText
          disableTypography
          primary={
            <ButtonBase onClick={handleClickRegions}>
              <Box alignItems='center' display='flex'>
                <Typography align='right' className={classes.text}>
                  <Trans>Дайте нам больше данных</Trans>
                </Typography>

                <Box ml={3}>
                  <img
                    alt='Regions'
                    height='19'
                    src={exclamationImgUrl}
                    width='19'
                  />
                </Box>
              </Box>
            </ButtonBase>
          }
        />
      </ListItem>

      <Backdrop className={classes.backdrop} open={props.blurRow} />
    </Box>
  );
};

export default NeedRegionsRow;
