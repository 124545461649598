/** @format */

import {Button} from '@material-ui/core';
import {Theme, createStyles, withStyles} from '@material-ui/core/styles';

// https://javascript.tutorialink.com/add-type-for-onclick-property-which-is-in-props-in-react-typescript/
type Props = {
  disabled?: boolean;
  onClick?: () => void;
};

const PrimarySmallButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.success.main,
      borderRadius: 8,
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 700,
      height: 38,
      lineHeight: '18px',
      padding: '8px 16px',
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '&:disabled': {
        backgroundColor: '#4D4D4D',
        color: '#DBDBDB',
        fontWeight: 'normal',
      },
      '&:hover': {
        backgroundColor: theme.custom_palette.success.hover,
      },
    },
  }),
)((props: Props) => <Button fullWidth={false} {...props} />);

export default PrimarySmallButton;
