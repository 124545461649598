/** @format */
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

interface Props {
  children: React.ReactChild
  onClick: () => void
}

const useStyles = makeStyles(_theme => ({
  button: {
    cursor: 'pointer'
  },
  buttonText: {
    color: '#949494',
  },
}));

const ArrowBack = (props: Props) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.button}
      display='flex'
      alignItems='center'
      onClick={props.onClick}
    >
      <ArrowBackIosIcon />
      <Typography className={classes.buttonText}>
        {props.children}
      </Typography>
    </Box>
  );
};

export default ArrowBack;
