/** @format */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.secondary,
  },
}));

const InformationDialog = props => {
  const classes = useStyles();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle className={classes.title}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          disableElevation
          fullWidth={false}
          size='medium'
          variant='contained'
          onClick={props.onClose}
        >
          <Trans>Закрыть</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InformationDialog;
