/** @format */

import {useEffect, useState} from 'react';

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {Box, Grid, IconButton, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';

import AutocompletePicker from 'components/pickers/AutocompletePicker';
import ModalPage from 'components/ModalPage';
import ModalLoading from 'components/loading/ModalLoading';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const GET_REGIONS = gql`
  query GetRegions {
    currentUser {
      currentCompany {
        regions {
          id
          name
        }
      }
    }
    allRegions {
      id
      name
    }
  }
`;

const SAVE_REGIONS = gql`
  mutation SaveRegions($regionIds: [Int!]!) {
    saveRegions(regionIds: $regionIds)
  }
`;

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '20px',
  },
  tagText: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '22px',
  },
  removeIcon: {
    padding: theme.spacing(1),
  },
}));

const Regions = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const {t} = useTranslation();

  const [selectedRegions, setSelectedRegions] = useState([]);

  const {loading, error, data} = useQuery(GET_REGIONS);
  const [saveRegions, {loading: saveRegionsLoading}] =
    useMutation(SAVE_REGIONS);

  useEffect(() => {
    if (!data) return;
    setSelectedRegions(data.currentUser.currentCompany.regions);
  }, [data]);

  if (loading) {
    return (
      <ModalLoading
        title={t('Пожалуйста, подождите', 'Пожалуйста, подождите')}
      />
    );
  }
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const regionsByIdMap = data.allRegions.reduce((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {});

  const renderPicker = () => {
    const handleClose = newSelectedRegions => {
      setSelectedRegions(newSelectedRegions);
    };

    return (
      <Box mt={8}>
        <AutocompletePicker
          idField='id'
          labelField='name'
          options={data.allRegions}
          placeholder={t(
            'Выберите регионы, в которых работаете',
            'Выберите регионы, в которых работаете',
          )}
          placeholderFocused={t(
            'Введите название региона',
            'Введите название региона',
          )}
          value={selectedRegions}
          onClose={handleClose}
        />
      </Box>
    );
  };

  const renderTag = region => {
    const handleRemove = () => {
      const newSelectedRegions = selectedRegions.filter(
        v => v.id !== region.id,
      );
      setSelectedRegions(newSelectedRegions);
    };

    return (
      <Box key={region.id} alignItems='center' display='flex' mr={4}>
        <Typography className={classes.tagText}>
          {regionsByIdMap[region.id].name}
        </Typography>
        <IconButton className={classes.removeIcon} onClick={handleRemove}>
          <img
            alt='Remove'
            height={18}
            src={new URL('./images/remove.png', import.meta.url)}
            width={18}
          />
        </IconButton>
      </Box>
    );
  };

  const renderButton = () => {
    const handleSave = async () => {
      const regionIds = selectedRegions.map(v => v.id);
      await saveRegions({variables: {regionIds}});
      navigate(-1);
    };

    return (
      <Box mt={16}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={saveRegionsLoading}
              fullWidth
              onClick={handleSave}
            >
              <Trans>Сохранить</Trans>
            </PrimaryMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const sortedSelectedRegions = R.sortBy(R.prop('id'), selectedRegions);

  return (
    <ModalPage
      header={t(
        'Мы сможем проверить кампании по большему количеству критериев,\nесли вы сообщите нам, в каких регионах вы запускаете рекламу',
        'Мы сможем проверить кампании по большему количеству критериев,\nесли вы сообщите нам, в каких регионах вы запускаете рекламу',
      )}
    >
      <Typography className={classes.text}>
        <Trans>Можете выбрать несколько регионов</Trans>
      </Typography>

      {renderPicker()}

      <Box display='flex' flexWrap='wrap' mt={4}>
        {sortedSelectedRegions.map(v => renderTag(v))}
      </Box>

      {renderButton()}
    </ModalPage>
  );
};

export default Regions;
