/** @format */

import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
    whiteSpace: 'pre-line',
  },
}));

const Error = props => {
  const classes = useStyles();

  return (
    <Box mt={5}>
      <Typography className={classes.body}>{props.text}</Typography>
    </Box>
  );
};

export default Error;
