/** @format */

import {makeStyles} from '@material-ui/core/styles';

import BaseTextField from './BaseTextField';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 8,
    '&:hover fieldset': {
      borderColor: theme.palette.action.hover,
    },
    '& fieldset': {
      borderColor: '#DADADA',
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text.secondary,
      height: 'unset',
    },
    '& .MuiInputBase-root': {
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: '20px',
    },
    '& input.MuiOutlinedInput-input': {
      padding: '14px 16px',
    },
  },
}));

const MediumTextField = props => {
  const classes = useStyles();
  return <BaseTextField className={classes.root} {...props} />;
};

export default MediumTextField;
