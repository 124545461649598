/** @format */

import {CSSProperties, forwardRef} from 'react';

import clsx from 'clsx';
import {Box, ButtonBase} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-start',
      backgroundColor: theme.palette.action.disabledBackground,
      borderRadius: 8,
      display: 'flex',
      padding: theme.spacing(4),
    },
  }),
);

type Props = {
  containerStyle?: CSSProperties;
  containerClassName?: string;
  onClick: () => void;
  children: React.ReactNode;
}

// https://github.com/facebook/react/issues/16653#issuecomment-564423981
const ButtonPlate = forwardRef<HTMLElement, Props>((props, ref) => {
  const {onClick = null} = props;
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Box {...{ref}}>
      <ButtonBase
        className={clsx(classes.container, props.containerClassName)}
        component='a'
        style={props.containerStyle}
        onClick={handleClick}
      >
        {props.children}
      </ButtonBase>
    </Box>
  );
});

ButtonPlate.displayName = 'ButtonPlate';

export default ButtonPlate;
