/** @format */
import {useState} from 'react';

import {Box, Typography} from '@material-ui/core';

import PrimarySelect from 'components/selects/PrimarySelect';
import SecondarySelect from 'components/selects/SecondarySelect';
import SecondarySelectWithLabel from 'components/selects/SecondarySelectWithLabel';

const Buttons = () => {
  const [variant, setVariant] = useState('');

  const handleSelect = (id: string) => {
    setVariant(id);
    console.log('click', id);
  };

  const options = [
    {value: 'variant1', text: 'variant 1'},
    {value: 'variant2', text: 'variant 2'}
  ];

  return (
    <Box>
      <Box mt={4}>
        <Typography>PrimarySelect</Typography>
        <PrimarySelect
          options={options}
          value={variant}
          onSelect={handleSelect}
        >
          Click me
        </PrimarySelect>
      </Box>
      <Box mt={4}>
        <Typography>SecondarySelect</Typography>
        <SecondarySelect
          options={options}
          value={variant}
          onSelect={handleSelect}
        >
          Click me
        </SecondarySelect>
      </Box>
      <Box mt={4}>
        <Typography>SecondarySelectWithLabel</Typography>
        <SecondarySelectWithLabel
          options={options}
          value={variant}
          placeholder={'placeholder'}
          onSelect={handleSelect}
        >
          Click me
        </SecondarySelectWithLabel>
      </Box>
    </Box>
  );
};

export default Buttons;
