/** @format */
import {Box, Typography, useMediaQuery} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTheme} from '@material-ui/core/styles';

import ConnectedProviders from 'components/icons/ConnectedProviders';
import OutlinedSmallButton from 'components/buttons/OutlinedSmallButton';
import PrimarySelect from 'components/selects/PrimarySelect';
import shareImgUrl from 'images/share.png';
import {AdType, Provider, UserType} from 'types';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

interface token {
  provider: Provider;
}

interface Props {
  name: string;
  userType: UserType;
  isClientAccess: boolean;
  tokens: token[];
}

const Header = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {mixpanel} = useMixpanelContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {currentAdType, switchAdType} = useCompanyContext();

  const {t} = useTranslation();

  const handleClickShowShareModal = () => {
    mixpanel.track('open_issue_share_page', {sensor: props.name});

    navigate('/home/main/share', {
      state: {background: location},
    });
  };

  const renderTitle = () => (
    <Box display='flex' justifyContent='space-between'>
      <Box mr={4}>
        <Typography variant='h1'>{props.name}</Typography>
      </Box>
      {!props.isClientAccess && props.userType === 'AGENCY' && (
        <OutlinedSmallButton ml={2} onClick={handleClickShowShareModal}>
          <Box alignItems='center' display='flex'>
            {!isMobile && (
              <Box mr={2}>
                <Trans>Общий доступ</Trans>
              </Box>
            )}
            <Box>
              <img alt='Share' height='20' src={shareImgUrl} width='20' />
            </Box>
          </Box>
        </OutlinedSmallButton>
      )}
    </Box>
  );

  if (!isMobile) {
    return renderTitle();
  }

  const handleSelectAdType = (value: string) => {
    switchAdType(value as AdType);
    navigate('/home/main', {replace: true});
  };

  const menuOptions = [
    {value: 'CONTEXT', text: t('Контекст', 'Контекст')},
    {value: 'TARGET', text: t('Таргет', 'Таргет')},
  ];

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box display='flex' flexDirection='column'>
        {renderTitle()}
        <Box mt={4}>
          <PrimarySelect
            options={menuOptions}
            value={currentAdType}
            onSelect={handleSelectAdType}
          />
        </Box>
      </Box>
      <ConnectedProviders
        adTypes={[currentAdType]}
        size={40}
        tokens={props.tokens}
      />
    </Box>
  );
};

export default Header;
