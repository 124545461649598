/** @format */
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

type Props = {
  primary: string;
  secondary: string;
  onDelete?: () => void;
};

const UserListItem = (props: Props) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText primary={props.primary} secondary={props.secondary} />
      {props.onDelete && (
        <ListItemSecondaryAction>
          <IconButton edge='end' aria-label='delete' onClick={props.onDelete}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default UserListItem;
