/** @format */

import DateGraph from './DateGraph';
import numberHelpers from 'helpers/numberHelpers';
import type {PlatformGraphStat} from 'types';

type Props = {
  platformGraphStats: PlatformGraphStat[];
};

const SpendGraph = (props: Props) => {
  const formatYValue = (value: number) => numberHelpers.formatRUB(value);

  const graphData = props.platformGraphStats.map(v => ({
    dateValue: v.date,
    yValue: v.spent,
  }));

  return (
    <DateGraph
      data={graphData}
      formatYValue={formatYValue}
      noAnomalyAreaText='Зона предельной нормы среднего расхода'
      noAnomalyBarText='Средний расход бюджета'
      showAnomalies
    />
  );
};

export default SpendGraph;
