/** @format */

import {Tabs, TabsTypeMap} from '@material-ui/core';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';
import {withStyles} from '@material-ui/core/styles';

const StyledTabs = withStyles(theme => ({
  root: {
    backgroundColor: '#2B2B2B',
    borderRadius: '6px 6px 0 0',
    height: 45,
    minHeight: 45,
  },
  flexContainer: {
    borderBottom: '1px solid #4D4D4D',
  },
  indicator: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    height: 1,
    '& > span': {
      backgroundColor: theme.palette.success.main,
      width: '100%',
    },
  },
}))(props => (
  <Tabs TabIndicatorProps={{children: <span />}} {...props} />
)) as OverridableComponent<TabsTypeMap>;

export default StyledTabs;
