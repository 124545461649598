/** @format */

import {ChangeEvent, useEffect, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import * as R from 'ramda';
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate, useLocation} from 'react-router-dom';

import BackHeader from 'components/BackHeader';
import MediumTextField from 'components/inputs/MediumTextField';
import Modal from 'components/Modal';
import NativeTabs from 'components/tabs/NativeTabs';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import PrimaryMenu from 'components/menus/PrimaryMenu';
import SecondarySmallButton from 'components/buttons/SecondarySmallButton';
import Title from 'components/Title';
import adTypeHelpers from 'helpers/adTypeHelpers';
import removeImage from 'images/remove_white.png';
import routerHelpers from 'helpers/routerHelpers';
import {Exception, ExceptionFieldset, Operator} from 'types';
import {fieldName} from 'helpers/field_names';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import {ExceptionForm} from './ExceptionForm';

type region = {
  id: number;
  name: string;
};
type geoGroup = {
  name: string;
  geos: {
    id: number;
    name: string;
    parentName: string;
  }[];
};
type getCurrentUser = {
  currentUser: {
    currentCompany: {
      id: number;
      badWords: string[];
      correctWords: string[];
      geoGroups: geoGroup[];
      isClientAccess: boolean;
      regions: {
        id: number;
        name: string;
      }[];
      stopWords: string[];
      exceptions: Exception[];
      exceptionFieldsets: ExceptionFieldset[];
      adDoubleSettings: {
        isMobileDouble: boolean | null;
      };
      utmCoherencySettings: {
        skipParams: string[];
      };
    };
  };
};

type Tab = {
  path: string;
  name: string;
  text: string;
};

const MAX_BAD_WORDS = 3;
const MAX_STOP_WORDS = 3;
const SETTINGS_TAB = 'settings';
const FILTERS_TAB = 'filters';
const TABS: Tab[] = [
  {path: '#', name: SETTINGS_TAB, text: 'Основные'},
  {path: '#', name: FILTERS_TAB, text: 'Исключения'},
];
const SENSORS_WITH_SETTINGS = [
  'as-geo',
  'as-bad-keys',
  'as-typo',
  'as-geo-name-mismatch',
  'as-ad-stop-words',
  'as-ad-double',
  'as-utm-coherency',
];
const UTMS = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondatyText: {
      color: '#A1A1A1',
    },
    sensorSettingsText: {
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: '22px',
    },
    link: {
      textDecoration: 'underline',
      verticalAlign: 'baseline',
    },
    moreLessLink: {
      color: theme.palette.success.main,
      fontWeight: 600,
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.success.main,
      },
    },
    removeIcon: {
      padding: theme.spacing(1),
    },
    checkbox: {
      color: 'rgba(255, 255, 255, 0.7)',
    },
    exceptionValue: {
      lineBreak: 'anywhere',
    },
  }),
);

export const GET_CURRENT_USER = gql`
  query GetCurrentUser($sensorName: String!, $platforms: [YalperPlatform!]) {
    currentUser {
      currentCompany {
        id
        badWords(sensorName: $sensorName)
        correctWords(sensorName: $sensorName)
        geoGroups {
          name
          geos {
            id
            name
            parentName
          }
        }
        isClientAccess
        regions {
          id
          name
        }
        stopWords(sensorName: $sensorName)
        exceptions(sensorName: $sensorName, platforms: $platforms) {
          id
          platform
          items {
            field
            operator
            value
          }
        }
        exceptionFieldsets(sensorName: $sensorName) {
          platform
          fields
        }
        adDoubleSettings(sensorName: $sensorName) {
          isMobileDouble
        }
        utmCoherencySettings(sensorName: $sensorName) {
          skipParams
        }
      }
    }
  }
`;

const SAVE_GEO_GROUPS = gql`
  mutation SaveGeoGroups($geoGroups: [YalperGeoGroupInput!]!) {
    saveGeoGroups(geoGroups: $geoGroups)
  }
`;

export const CREATE_EXCEPTION = gql`
  mutation CreateException(
    $companyId: ID!
    $sensorName: String!
    $platform: YalperPlatform!
    $items: [YalperExceptionItemInput!]!
  ) {
    createException(
      companyId: $companyId
      sensorName: $sensorName
      platform: $platform
      items: $items
    )
  }
`;

const DELETE_EXCEPTION = gql`
  mutation DeleteException($id: ID!) {
    deleteException(id: $id)
  }
`;

const SAVE_AD_DOUBLE_SETTINGS = gql`
  mutation SaveAdDoubleSettings($settings: YalperAdDoubleSettingsInput!) {
    saveAdDoubleSettings(settings: $settings)
  }
`;

export const SAVE_UTM_COHERENCY_SETTINGS = gql`
  mutation SaveUtmCoherencySettings(
    $settings: YalperUtmCoherencySettingsInput!
  ) {
    saveUtmCoherencySettings(settings: $settings)
  }
`;

export const ErrorSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = routerHelpers.useSearchParams();
  const sensorName = searchParams.get('name');
  const humanName = searchParams.get('humanName');

  const hasSettings = () => {
    if (!sensorName) return false;

    return SENSORS_WITH_SETTINGS.includes(sensorName);
  };

  const {t} = useTranslation();

  const [showAllBadWords, setShowAllBadWords] = useState(false);
  const [showAllStopWords, setShowAllStopWords] = useState(false);
  const [activeTab, setActiveTab] = useState(
    hasSettings() ? SETTINGS_TAB : FILTERS_TAB,
  );
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedField, setSelectedField] = useState('');
  const [isMobileDouble, setIsModineDouble] = useState(false);
  const [skipParamsUtmCoherency, setSkipParamsUtmCoherency] = useState(
    [] as string[],
  );

  const {currentAdType} = useCompanyContext();
  const {mixpanel} = useMixpanelContext();

  const platforms = adTypeHelpers.platformsFromAdType(currentAdType);

  const {loading, error, data, refetch} = useQuery<getCurrentUser>(
    GET_CURRENT_USER,
    {
      skip: !sensorName,
      variables: {sensorName: sensorName, platforms: platforms},
    },
  );
  const [saveGeoGroups, {loading: saveGeoGroupsLoading}] =
    useMutation(SAVE_GEO_GROUPS);
  const [createException] = useMutation(CREATE_EXCEPTION);
  const [deleteException] = useMutation(DELETE_EXCEPTION);
  const [saveAdDoubleSettings, {loading: saveAdDoubleSettingsLoading}] =
    useMutation(SAVE_AD_DOUBLE_SETTINGS);
  const [saveUtmCoherencySettings, {loading: saveUtmCoherencySettingsLoading}] =
    useMutation(SAVE_UTM_COHERENCY_SETTINGS);

  useEffect(() => {
    if (!data) {
      return;
    }
    const {adDoubleSettings, utmCoherencySettings} =
      data.currentUser.currentCompany;
    setIsModineDouble(adDoubleSettings.isMobileDouble || false);
    setSkipParamsUtmCoherency(utmCoherencySettings.skipParams);
  }, [data]);

  routerHelpers.useCallbackOnReturn(refetch);
  const classes = useStyles();

  if (!sensorName) return <Alert severity='error'>Не указан сенсор</Alert>;
  if (loading || !data || saveGeoGroupsLoading) {
    return <LinearProgress style={{flex: 1}} />;
  }
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {
    id: companyId,
    isClientAccess,
    regions,
    badWords,
    correctWords,
    geoGroups,
    stopWords,
    exceptions,
    exceptionFieldsets,
  } = data.currentUser.currentCompany;

  const renderRegions = () => {
    if (sensorName !== 'as-geo') return null;

    const regionNames = regions.map((v: region) => v.name).join(', ');

    const handleClick = () => {
      navigate('/home/main/regions', {
        state: {background: location},
      });
    };

    return (
      <Box alignItems='flex-start' display='flex' flexDirection='column' mt={4}>
        {regionNames && (
          <Box mb={1}>
            <Typography display='inline' className={classes.sensorSettingsText}>
              <Trans>Ваши регионы: {{regionNames}}</Trans>
            </Typography>
          </Box>
        )}

        <SecondarySmallButton onClick={handleClick}>
          <Typography className={classes.sensorSettingsText}>
            <Trans>Настроить Гео</Trans>
          </Typography>
        </SecondarySmallButton>
      </Box>
    );
  };

  const renderBadWords = () => {
    if (sensorName !== 'as-bad-keys') return null;

    const sortedBadWords = showAllBadWords
      ? badWords.sort()
      : badWords.sort().slice(0, MAX_BAD_WORDS);

    const moreCount = badWords.length - MAX_BAD_WORDS;
    const showMoreLink = !showAllBadWords && moreCount > 0;
    const showLessLink = showAllBadWords && moreCount > MAX_BAD_WORDS;

    const handleShowMore = () => setShowAllBadWords(true);
    const handleShowLess = () => setShowAllBadWords(false);

    const handleConfigure = () => {
      navigate('/home/main/bad-words', {
        state: {background: location},
      });
    };

    const badWordsText = sortedBadWords.join(', ');
    return (
      <Box alignItems='flex-start' display='flex' flexDirection='column' mt={4}>
        {sortedBadWords.length > 0 && (
          <Box mb={1}>
            <Typography display='inline' className={classes.sensorSettingsText}>
              <Trans>Ваш чёрный список слов: {{badWordsText}}</Trans>
            </Typography>

            {showMoreLink && (
              <Link
                className={classes.moreLessLink}
                component='button'
                onClick={handleShowMore}
              >
                <Box ml={2}>
                  <Typography>
                    <Trans>Ещё {{moreCount}}</Trans>
                  </Typography>
                </Box>
              </Link>
            )}

            {showLessLink && (
              <Link
                className={classes.moreLessLink}
                component='button'
                onClick={handleShowLess}
              >
                <Box ml={2}>
                  <Typography>
                    <Trans>Свернуть</Trans>
                  </Typography>
                </Box>
              </Link>
            )}
          </Box>
        )}

        <SecondarySmallButton onClick={handleConfigure}>
          <Typography className={classes.sensorSettingsText}>
            <Trans>Настроить чёрные списки</Trans>
          </Typography>
        </SecondarySmallButton>
      </Box>
    );
  };

  const renderCorrectWords = () => {
    if (sensorName !== 'as-typo') return null;

    const handleConfigure = () => {
      navigate('/home/main/correct-words', {
        state: {background: location},
      });
    };
    const wordsCount = t('plurals.words', {count: correctWords.length});

    return (
      <Box alignItems='flex-start' display='flex' flexDirection='column' mt={4}>
        {correctWords && (
          <Box mb={1}>
            <Typography display='inline' className={classes.sensorSettingsText}>
              <Trans>Ваш словарь: {{wordsCount}}</Trans>
            </Typography>
          </Box>
        )}

        <SecondarySmallButton onClick={handleConfigure}>
          <Typography className={classes.sensorSettingsText}>
            <Trans>Настроить свой словарь</Trans>
          </Typography>
        </SecondarySmallButton>
      </Box>
    );
  };

  const renderGeoGroup = (geoGroup: geoGroup) => {
    const handleRemove = async () => {
      const newGeoGroups = geoGroups
        .filter(
          v =>
            v.name !== geoGroup.name ||
            JSON.stringify(v.geos) != JSON.stringify(geoGroup.geos),
        )
        .map(v => ({
          name: v.name,
          geos: v.geos.map(vv => ({
            id: vv.id,
            name: vv.name,
            parentName: vv.parentName,
          })),
        }));
      await saveGeoGroups({variables: {geoGroups: newGeoGroups}});
      await refetch();
    };

    return (
      <>
        <Grid item xs={11} sm={4}>
          {geoGroup.name}
        </Grid>
        <Grid item xs={11} sm={7}>
          <Hidden smDown>
            <Typography>{geoGroup.geos.map(v => v.name).join(', ')}</Typography>
          </Hidden>
          <Hidden smUp>
            <Typography className={classes.secondatyText}>
              {geoGroup.geos.map(v => v.name).join(', ')}
            </Typography>
          </Hidden>
        </Grid>
        <Grid item xs={1} sm={1}>
          <IconButton className={classes.removeIcon} onClick={handleRemove}>
            <img alt='Remove' height={18} src={removeImage} width={18} />
          </IconButton>
        </Grid>
      </>
    );
  };

  const renderGeoNameMismatch = () => {
    if (sensorName !== 'as-geo-name-mismatch') return null;

    const handleAdd = () => {
      navigate(
        {
          pathname: '/home/main/geo-groups',
          search: `?${searchParams.toString()}`,
        },
        {
          state: {background: location},
        },
      );
    };

    return (
      <>
        {geoGroups.length == 0 && (
          <Typography className={classes.secondatyText}>
            <Trans>
              Вы можете настроить более точное срабатывание для данного сенсора.
            </Trans>
          </Typography>
        )}
        {geoGroups.length > 0 && (
          <Grid container spacing={3}>
            <Hidden smDown>
              <Grid item xs={4}>
                <Typography className={classes.secondatyText}>
                  <Trans>Фраза</Trans>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.secondatyText}>
                  <Trans>Связанные регионы</Trans>
                </Typography>
              </Grid>
            </Hidden>
            {geoGroups.map(v => renderGeoGroup(v))}
          </Grid>
        )}
        <Box mt={8}>
          <PrimaryMediumButton onClick={handleAdd}>
            <Trans>Добавить</Trans>
          </PrimaryMediumButton>
        </Box>
      </>
    );
  };

  const renderStopWords = () => {
    if (sensorName !== 'as-ad-stop-words') return null;

    const sortedStopWords = showAllStopWords
      ? stopWords.sort()
      : stopWords.sort().slice(0, MAX_STOP_WORDS);

    const moreCount = stopWords.length - MAX_STOP_WORDS;
    const showMoreLink = !showAllStopWords && moreCount > 0;
    const showLessLink = showAllStopWords && moreCount > MAX_STOP_WORDS;

    const handleShowMore = () => setShowAllStopWords(true);
    const handleShowLess = () => setShowAllStopWords(false);

    const handleClickConfigure = () => {
      navigate('/home/main/stop-words', {
        state: {background: location},
      });
    };

    const stopWordsText = sortedStopWords.join(', ');
    return (
      <Box alignItems='flex-start' display='flex' flexDirection='column' mt={4}>
        {sortedStopWords.length > 0 && (
          <Box mb={1}>
            <Typography display='inline' className={classes.sensorSettingsText}>
              <Trans>Ваш список стоп-слов: {{stopWordsText}}</Trans>
            </Typography>

            {showMoreLink && (
              <Link
                className={classes.moreLessLink}
                component='button'
                onClick={handleShowMore}
              >
                <Box ml={2}>
                  <Typography>
                    <Trans>Ещё {{moreCount}}</Trans>
                  </Typography>
                </Box>
              </Link>
            )}

            {showLessLink && (
              <Link
                className={classes.moreLessLink}
                component='button'
                onClick={handleShowLess}
              >
                <Box ml={2}>
                  <Typography>
                    <Trans>Свернуть</Trans>
                  </Typography>
                </Box>
              </Link>
            )}
          </Box>
        )}

        <SecondarySmallButton onClick={handleClickConfigure}>
          <Typography className={classes.sensorSettingsText}>
            <Trans>Настроить стоп-слова</Trans>
          </Typography>
        </SecondarySmallButton>
      </Box>
    );
  };

  const renderAdDoubleSettings = () => {
    if (sensorName !== 'as-ad-double') return null;

    const handleClickCheckbox = () => {
      setIsModineDouble(!isMobileDouble);
    };

    const handleSave = async () => {
      await saveAdDoubleSettings({variables: {settings: {isMobileDouble}}});
    };

    return (
      <Box alignItems='flex-start' display='flex' flexDirection='column' mt={4}>
        <Box
          display='flex'
          alignItems='center'
          mb={4}
          onClick={handleClickCheckbox}
        >
          <Checkbox className={classes.checkbox} checked={isMobileDouble} />
          <Typography>Считать дублями тексты мобильных объявлений</Typography>
        </Box>
        <PrimaryMediumButton
          disabled={saveAdDoubleSettingsLoading}
          onClick={handleSave}
        >
          <Trans>Сохранить</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  const renderUtmCoherencySettings = () => {
    if (sensorName !== 'as-utm-coherency') return null;

    const handleSave = async () => {
      const skipParams = skipParamsUtmCoherency.filter(v => v.length > 0);
      await saveUtmCoherencySettings({variables: {settings: {skipParams}}});
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const lines = event.target.value.toLowerCase().split('\n');
      setSkipParamsUtmCoherency(lines);
    };

    const handleClickChip = (label: string) => {
      const existingElement = skipParamsUtmCoherency.find(v => v === label);
      if (!existingElement) {
        const currentFilledValues = skipParamsUtmCoherency.filter(
          v => v.length > 0,
        );
        setSkipParamsUtmCoherency([...currentFilledValues, label]);
      }
    };

    const inputValue = skipParamsUtmCoherency.join('\n');

    return (
      <Box alignItems='flex-start' display='flex' flexDirection='column' mt={4}>
        <Box width='100%' mb={1}>
          <Box mb={4}>
            <Typography>Исключить UTM-метки из проверки</Typography>
          </Box>
          <MediumTextField
            placeholder={t('Добавьте UTM-метку', 'Добавьте UTM-метку')}
            value={inputValue}
            myProps={{placeholderFocused: ''}}
            multiline
            minRows={10}
            data-testid='textarea-as-utm-coherency'
            onChange={handleChange}
          />
        </Box>
        <Box mt={1} mb={4} display='flex' gridGap={8}>
          {UTMS.map((s, i) => (
            <Chip
              key={i}
              label={s}
              size='small'
              variant='outlined'
              data-testid={`chip-${s}`}
              onClick={() => handleClickChip(s)}
            />
          ))}
        </Box>

        <PrimaryMediumButton
          disabled={saveUtmCoherencySettingsLoading}
          data-testid='save-as-utm-coherency'
          onClick={handleSave}
        >
          <Trans>Сохранить</Trans>
        </PrimaryMediumButton>
      </Box>
    );
  };

  const renderExceptions = () => {
    const fields = R.pipe(
      R.filter((v: ExceptionFieldset) => platforms.includes(v.platform)),
      R.map(v => v.fields),
      R.flatten,
      R.uniq,
    )(exceptionFieldsets);

    const items = fields.map(v => ({id: v, text: t(fieldName(v))}));

    const rows = R.pipe(
      R.groupBy((v: Exception) => R.pipe(R.values, R.join('|'))(v.items[0])),
    )(exceptions);

    const handleSelect = (id: string) => {
      setSelectedField(id);
      setShowFilterModal(true);
    };

    const handleClose = () => {
      setShowFilterModal(false);
    };

    const handleSave = async (
      field: string,
      operator: Operator,
      value: string,
    ) => {
      const selectedPlatforms = R.pipe(
        R.map((v: ExceptionFieldset) => v.platform),
        R.uniq,
        R.filter((v: string) => platforms.includes(v)),
      )(exceptionFieldsets);

      await Promise.all(
        selectedPlatforms.map(async v => {
          await createException({
            variables: {
              companyId,
              sensorName,
              platform: v,
              items: [{field, operator, value}],
            },
          });
        }),
      );
      mixpanel.track('click_save_exception', {
        sensorName,
        platforms: selectedPlatforms,
      });
      refetch();
      setShowFilterModal(false);
    };

    const handleRemove = async (ids: string[]) => {
      await Promise.all(
        ids.map(async id => {
          await deleteException({variables: {id}});
        }),
      );
      refetch();
    };

    return (
      <>
        {exceptions.length == 0 && (
          <Typography className={classes.secondatyText}>
            <Trans>
              С помощью функционала исключений вы можете исключить срабатывание
              сенсора по определенным параметрам.
              <br />
              <br />
              Например, если вы хотите, чтобы сенсор не учитывал кампании с
              расходом менее 5000 руб., нужно выбрать “Расход”, а затем “Меньше”
              и указать нужное вам значение.
              <br />
              <br />
              По каждому сенсору вы можете видеть уникальный набор атрибутов,
              свойственный именно ему. По каким-то сенсорам выводится
              возможность выбрать количество кликов, по каким-то – название
              цели, а для других – расход или название группы.
              <br />
              <br />
              Мы рекомендуем настроить исключения для всех сенсоров, по которым
              вы видите неактуальные для вас срабатывания, чтобы сделать отчет
              максимально полезным.
            </Trans>
          </Typography>
        )}
        {exceptions.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={4}>
                  <Typography className={classes.secondatyText}>
                    <Trans>Свойство</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <Typography className={classes.secondatyText}>
                    <Trans>Условие</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Typography className={classes.secondatyText}>
                    <Trans>Значение</Trans>
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} />
              </Grid>
            </Grid>

            {Object.keys(rows).map((key, i) => {
              const row = rows[key];
              if (row) {
                const item = row[0]?.items[0];
                const ids = row.map(v => v.id);

                if (ids) {
                  return (
                    <Grid item xs={12} sm={12} key={i}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4}>
                          <Typography>{t(fieldName(item.field))}</Typography>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <Typography>
                            {t(`operators.${item.operator}`)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                          <Typography className={classes.exceptionValue}>
                            {item.value}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                          <IconButton
                            className={classes.removeIcon}
                            onClick={() => handleRemove(ids)}
                          >
                            <img
                              alt='Remove filter'
                              height={18}
                              src={removeImage}
                              width={18}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } else {
                  return <></>;
                }
              }
            })}
          </Grid>
        )}
        <Box mt={15}>
          <PrimaryMenu
            text={t('Добавить условие')}
            items={items}
            onSelect={handleSelect}
          />
        </Box>
        {showFilterModal && (
          <Modal header={t('Исключать по условию')} onClose={handleClose}>
            <ExceptionForm field={selectedField} onSave={handleSave} />
          </Modal>
        )}
      </>
    );
  };

  const handleChangeTab = (value: string) => {
    setActiveTab(value);
  };

  const tabs = () => {
    const tabs = TABS.map(v => ({...v, text: t(v.text)}));
    if (hasSettings()) {
      return tabs;
    }
    return tabs.filter(v => v.name !== SETTINGS_TAB);
  };

  return (
    <>
      <Box>
        <BackHeader />
      </Box>
      <Box mt={8}>
        <Title>
          <Trans>Настройки сенсора</Trans>
        </Title>
      </Box>
      <Box mt={4} mb={4}>
        <Typography className={classes.secondatyText}>{humanName}</Typography>
      </Box>
      <NativeTabs
        activeTabName={activeTab}
        tabs={tabs()}
        variant='DARK'
        onChange={handleChangeTab}
      />
      {!isClientAccess && activeTab == SETTINGS_TAB && (
        <Box mt={8}>
          {renderRegions()}
          {renderBadWords()}
          {renderCorrectWords()}
          {renderGeoNameMismatch()}
          {renderStopWords()}
          {renderAdDoubleSettings()}
          {renderUtmCoherencySettings()}
        </Box>
      )}
      {!isClientAccess && activeTab == FILTERS_TAB && (
        <Box mt={8}>{renderExceptions()}</Box>
      )}
    </>
  );
};
