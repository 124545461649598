/** @format */

import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import BoxPlate from 'components/plates/BoxPlate';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import numberHelpers from 'helpers/numberHelpers';
import paymentHelpers from 'helpers/paymentHelpers';

const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.down('xs')]: {
      height: 236,
    },
    [theme.breakpoints.up('sm')]: {
      height: 256,
    },
  },
  checksText: {
    color: '#BCBCBC',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  companiesText: {
    color: '#CCCCCC',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '20px',
  },
  button: {
    fontSize: 14,
    fontWeight: '700',
    lineHeight: '20px',
  },
}));

const Plan = props => {
  const classes = useStyles();

  const isCurrent = props.subscription.planId === props.plan.id;

  const isAvailable = () => {
    const allPlanIds = props.allPlans.map(v => v.id);

    // User has current plan of another type
    if (
      props.subscription.planId &&
      !allPlanIds.includes(props.subscription.planId)
    ) {
      return false;
    }

    return props.companiesCount <= props.plan.maxCompanies;
  };

  const shouldRenew =
    paymentHelpers.paySoon(props.subscription) ||
    paymentHelpers.payNow(props.subscription);

  const handleClick = () => {
    if (isCurrent && shouldRenew) {
      props.onRenewPlan();
      return;
    }

    props.onChangePlan(props.plan);
  };

  const renderChecksText = () => {
    if (props.plan.type === 'daily') {
      return (
        <Box mt={3}>
          <Typography className={classes.checksText} variant='body2'>
            {'30 проверок'}
          </Typography>
        </Box>
      );
    }

    if (props.plan.type === 'one-time') {
      return (
        <Box mt={3}>
          <Typography className={classes.checksText} variant='body2'>
            {'1 проверка'}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  const buttonText = () => {
    if (isCurrent && shouldRenew) return 'Продлить';
    if (isCurrent) return 'Твой тариф';
    if (!isAvailable()) return 'Тариф недоступен';

    return 'Выбрать и оплатить';
  };

  return (
    <BoxPlate>
      <Box
        className={classes.content}
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={1}
          justifyContent='flex-start'
        >
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h2'>{props.plan.name}</Typography>
            <Typography variant='h2'>
              {numberHelpers.formatRUB(props.plan.priceText)}
            </Typography>
          </Box>

          {renderChecksText()}

          <Box minHeight={32} mt={3}>
            <Typography variant='body2'>{props.plan.description}</Typography>
          </Box>

          <Box alignItems='flex-start' display='flex' mt={6}>
            <Box mr={2}>
              <img
                alt='Checkmark'
                height='8'
                src={new URL('./images/checkmark.png', import.meta.url)}
                width='7'
              />
            </Box>
            <Typography className={classes.companiesText}>
              {props.plan.companiesText}
            </Typography>
          </Box>
        </Box>

        <PrimaryMediumButton
          className={classes.button}
          disabled={(isCurrent && !shouldRenew) || !isAvailable()}
          fullWidth
          onClick={handleClick}
        >
          {buttonText()}
        </PrimaryMediumButton>
      </Box>
    </BoxPlate>
  );
};

export default Plan;
