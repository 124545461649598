/** @format */
import clsx from 'clsx';

import {Box, Grid, Link, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import TransparentMediumButton from 'components/buttons/TransparentMediumButton';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  body: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
    whiteSpace: 'pre-line',
  },
  underline: {
    textDecoration: 'underline',
  },
}));

const InvalidToken = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const {mixpanel} = useMixpanelContext();

  const renderButtons = () => {
    const handleReconnect = () => {
      navigate('/home/settings/connect');
    };

    return (
      <Box mt={6}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <PrimaryMediumButton fullWidth onClick={handleReconnect}>
              <Trans>Переподключить кабинет</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TransparentMediumButton
              fullWidth
              href='https://t.me/adsensor_amocrm_bot'
              target='_blank'
            >
              <Trans>Написать в поддержку</Trans>
            </TransparentMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleClickLink = () => {
    mixpanel.track('open_instruction_reconnect_token', {});
  };

  return (
    <Box mt={5}>
      <Box alignItems='center' display='flex'>
        <img
          alt='Finger'
          height='29'
          src={new URL('./images/finger.png', import.meta.url)}
          width='22'
        />
        <Box ml={2}>
          <Typography className={classes.title}>
            <Trans>Проблема с доступом к кабинету</Trans>
          </Typography>
        </Box>
      </Box>

      <Box mt={3}>
        <Typography className={classes.body}>
          <Trans>
            Возникла проблема с доступом к вашему рекламному кабинету, и
            AdSensor больше не может делать проверки.
            <br />
            Это могло произойти по нескольким причинам:
            <br />
            — Вы отозвали доступ AdSensor к вашему рекламному кабинету в Yandex,
            Google и т.д.
            <br />
            — Истек срок жизни токена
            <br />
            — Вы или ваши коллеги поменяли пароль в Yandex, Google и т.д.
            <br />
            <br />
            Как это исправить читайте&nbsp;
            <Link
              href='https://adsensor.ru/instructions#rec520666473'
              className={clsx(classes.body, classes.underline)}
              target='_blank'
              onClick={handleClickLink}
            >
              тут
            </Link>
          </Trans>
        </Typography>
      </Box>

      {renderButtons()}
    </Box>
  );
};

export default InvalidToken;
