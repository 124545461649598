/** @format */

import {Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const PrimaryMediumButton = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.success.main,
    borderRadius: 8,
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: '700',
    height: 48,
    lineHeight: '20px',
    padding: '14px 24px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:disabled': {
      backgroundColor: '#4D4D4D',
      color: '#DBDBDB',
      fontWeight: 'normal',
    },
    '&:hover': {
      backgroundColor: theme.custom_palette.success.hover,
    },
  },
}))(props => <Button fullWidth={false} {...props} />);

export default PrimaryMediumButton;
