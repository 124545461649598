/** @format */

import clsx from 'clsx';
import {Box, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';

import ButtonPlate from 'components/plates/ButtonPlate';

type Props = {
  description: string;
  found: number;
  new: number;
  title: string;
  icon: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.primary,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.169px',
    },
    description: {
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
    },
    rightContainer: {
      minWidth: theme.spacing(26),
    },
    successText: {
      color: '#8B8B8B',
      fontSize: 14,
      fontWeight: 400,
    },
    recomendationsContainer: {
      maxWidth: '160px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1.5, 4),
      borderRadius: '8px',
      border: '1px solid #AE5658',
      color: '#AE5658',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    recomendationsContainerSuccess: {
      maxWidth: '160px',
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'center',
      padding: theme.spacing(1.5, 4),
      color: '#8B8B8B',
      borderRadius: '8px',
      border: '1px solid #8B8B8B',
    },
    foundText: {
      color: '#AE5658',
      fontSize: 14,
      fontWeight: 400,
    },
    newText: {
      color: '#AE5658',
      fontSize: 14,
      fontWeight: 400,
    },
    row: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(4),
      borderRadius: '8px',
      background: '#202020',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      borderRight: '2px solid #AE5658',
      borderLeft: '2px solid #AE5658',
      '&:first-of-type': {
        marginTop: 0,
      },
      '& .MuiButtonBase-root': {
        padding: 0,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 0,
        backgroundColor: 'transparent',
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'wrap',
        },
      },
    },
    yellowBorder: {
      borderRightColor: '#AA8258',
      borderLeftColor: '#AA8258',
    },
    neutralBorder: {
      borderRightColor: '#8B8B8B',
      borderLeftColor: '#8B8B8B',
    },
    rowIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 66,
      height: 66,
      marginRight: theme.spacing(4),
      borderRadius: '8px',
      background: '#2B2B2B',
    },
    textContainer: {
      maxWidth: '82%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
    },
    recomendationsContainerYellow: {
      borderColor: '#AA8258',
      color: '#AA8258',
    },
    foundTextYellow: {
      color: '#AA8258',
    },
    recomendationsWrapper: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: theme.spacing(4),
      },
    },
  }),
);

const Row = (props: Props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Box
      display='flex'
      alignItems={'center'}
      justifyContent='space-between'
      className={clsx(
        classes.row,
        props.found === 0 && classes.neutralBorder,
        props.new === 0 && classes.yellowBorder,
      )}
      mb={4}
    >
      <ButtonPlate onClick={props.onClick}>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
          <Box className={classes.rowIcon}>
            <img
              alt='category icon'
              src={new URL(props.icon, import.meta.url).href}
              width='24'
              height='24'
            />
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            className={classes.textContainer}
          >
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography className={classes.description}>
              {props.description}
            </Typography>
          </Box>
        </Box>

        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          className={classes.recomendationsWrapper}
        >
          {props.found === 0 ? (
            <Box className={classes.recomendationsContainerSuccess}>
              <Typography className={classes.successText}>
                <Trans>Рекомендаций нет</Trans>
              </Typography>
            </Box>
          ) : (
            <Box
              className={clsx(
                classes.recomendationsContainer,
                !props.new && classes.recomendationsContainerYellow,
              )}
            >
              <Typography
                className={clsx(
                  classes.foundText,
                  !props.new && classes.foundTextYellow,
                )}
              >
                {props.found}&nbsp;
              </Typography>
              {props.new > 0 && (
                <Typography className={classes.newText}>
                  ({t('plurals.new_warnings', {count: props.new})})
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </ButtonPlate>
    </Box>
  );
};

export default Row;
