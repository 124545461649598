/** @format */

import {useState, useEffect} from 'react';

import Alert from '@material-ui/lab/Alert';
import {
  InputAdornment,
  Box,
  Button,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {makeStyles} from '@material-ui/core/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SmartphoneOutlinedIcon from '@material-ui/icons/SmartphoneOutlined';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import QrCode from 'react-qr-code';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';

import clsx from 'clsx';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import Breadcrumbs from 'components/Breadcrumbs';
import BackHeader from 'components/BackHeader';
import EmailField from 'components/inputs/EmailField';
import LegacyTextField from 'components/inputs/LegacyTextField';
import PasswordField from 'components/inputs/PasswordField';
import PhoneNumberField from 'components/inputs/PhoneNumberField';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    marginBottom: theme.spacing(7),
  },
  passwordButton: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  successMessage: {
    color: theme.palette.success.main,
    fontSize: 14,
    marginTop: theme.spacing(1),
  },

  headerTitle: {
    fontSize: '28px',
    fontWeight: 700,
  },
  rowNew: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: '#202020',
    '& .MuiTextField-root': {
      marginTop: 0,
    },
  },
  label: {
    marginBottom: theme.spacing(4),
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.131px',
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(4),
    borderRadius: '8px',
    background: 'transparent',
  },
  passwordButtonNew: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: '8px',
    background: 'transparent',
    borderColor: '#4D4E4E',
    '& .MuiButton-label div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  hiddenPasswordField: {
    marginLeft: theme.spacing(4),
    color: '#8B8B8B',
    fontSize: '14px',
    letterSpacing: '0.131px',
  },
  securityTitle: {
    fontSize: '16px',
    margin: 0,
    fontWeight: 700,
    letterSpacing: '0.131px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  securityDescription: {
    margin: 0,
    marginTop: theme.spacing(4),
    color: '#8B8B8B',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.131px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  twoFATextField: {
    flexGrow: '1',
    marginTop: '16px !important',
    '&:placeholder': {
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.131px',
    },
  },
  twoFAIcon: {
    top: '28px',
  },
  twoFAButton: {
    marginTop: theme.spacing(8),
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.131px',
    '&:disabled': {
      background: '#2B2B2B',
      color: '#8B8B8B',
    },
    '&:enabled': {
      background: '#8B8B8B',
    },
  },
  twoFAButtonWithError: {
    marginTop: theme.spacing(2),
  },
  twoFAContainer: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputIcon: {
    marginRight: theme.spacing(2),
  },
}));

const CODE_LENGTH = 6;

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      phone
      email
      profile {
        id
        name
      }
      phone
      has2fa
      secret
    }
  }
`;

const CREATE_PROFILE = gql`
  mutation CreateProfile($name: String!) {
    createProfile(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($name: String!) {
    updateProfile(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`;

const ENABLE_2FA = gql`
  mutation Enable2FA($secret: String!, $code: String!) {
    enable2fa(secret: $secret, code: $code) {
      id
    }
  }
`;

const DISABLE_2FA = gql`
  mutation Disable2FA {
    disable2fa {
      id
    }
  }
`;

const breadcrumbs = [
  {
    href: '/home/settings',
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '',
    title: <Trans>Настройки аккаунта</Trans>,
  },
];

const Profile = () => {
  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();

  const {t} = useTranslation();

  const {isNewVersionEnabled} = useFlags();

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const [state, setState] = useState({});

  const [passwordFormVisible, setPasswordFormVisible] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const {loading, error, data, refetch} = useQuery(GET_CURRENT_USER);
  const [createProfile, {error: createProfileError}] =
    useMutation(CREATE_PROFILE);
  const [updateProfile, {error: updateProfileError}] =
    useMutation(UPDATE_PROFILE);
  const [updatePassword, {error: updatePasswordError}] =
    useMutation(UPDATE_PASSWORD);

  const [enable2Fa, {loading: enable2FaLoading}] = useMutation(ENABLE_2FA);
  const [disable2Fa, {loading: disable2FaLoading}] = useMutation(DISABLE_2FA);

  useEffect(() => {
    if (!data) return;

    const {profile} = data.currentUser;
    setState({name: (profile && profile.name) || ''});
  }, [data]);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const handleChange = event => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleBlurName = async () => {
    mixpanel.track('click_edit_name');
    const {profile} = data.currentUser;

    if (profile) {
      await updateProfile({variables: {name: state.name}});
    } else {
      await createProfile({variables: {name: state.name}});
    }

    mixpanel.people.set({$name: state.name, name: state.name});
    mixpanel.register({name: state.name});

    await refetch();
  };

  const changePassword = async () => {
    mixpanel.track('click_edit_password');

    await updatePassword({
      variables: {
        currentPassword: state.currentPassword,
        newPassword: state.password,
      },
    });

    setPasswordFormVisible(false);
    setPasswordUpdated(true);
  };

  const handleBlurPassword = () => {
    if (!state.password || !state.passwordConfirmation) {
      setNewPasswordError(null);
      return;
    }
    if (state.password !== state.passwordConfirmation) {
      setNewPasswordError(
        t(
          'Не совпадает новый пароль и подтверждение пароля',
          'Не совпадает новый пароль и подтверждение пароля',
        ),
      );
      return;
    } else {
      setNewPasswordError(null);
    }
    if (!state.currentPassword) return;

    changePassword().catch(console.log);
  };

  const saveProfileError = createProfileError || updateProfileError;

  const {phone, has2fa, secret} = data.currentUser;

  const renderQrCode = () => {
    // https://hexdocs.pm/nimble_totp/NimbleTOTP.html#otpauth_uri/3
    const label = `AdSensor:${phone}`;
    const query = `secret=${secret}&issuer=AdSensor`;
    const otpauthUri = `otpauth://totp/${label}?${query}`;

    // qr code should have "quit zone"
    // https://qrworld.wordpress.com/2011/08/09/the-quiet-zone/
    return (
      <Box
        display='flex'
        justifyContent='center'
        margin={'0 auto'}
        bgcolor='white'
      >
        <QrCode value={otpauthUri} />
      </Box>
    );
  };

  const renderCodeInput = () => {
    if (has2fa) return null;

    const handleChange = event => {
      setCode(event.target.value);
    };

    const handleDisable = async () => {
      await disable2Fa();
      refetch();
    };

    const handleEnable = async () => {
      try {
        await enable2Fa({variables: {secret, code}});
        refetch();
      } catch (e) {
        setCodeError(
          e.message === 'invalid_code' ? t('Неправильный код') : e.message,
        );
      }
    };

    return (
      <Box className={classes.twoFAContainer} mr={4}>
        <Box flexGrow={1} mr={4}>
          <label htmlFor='2fa' className={classes.label}>
            <Trans>Код аутентификации</Trans>
          </label>

          <LegacyTextField
            className={classes.twoFATextField}
            iconClassName={classes.twoFAIcon}
            error={Boolean(codeError)}
            helperText={codeError}
            inputProps={{name: '2fa', maxLength: CODE_LENGTH}}
            placeholder={'••••••••••••'}
            value={code}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='end'>
                  <VpnKeyOutlinedIcon
                    className={classes.inputIcon}
                    htmlColor='#8B8B8B'
                    fontSize='small'
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box>
          {has2fa && (
            <PrimaryMediumButton
              className={clsx(
                classes.twoFAButton,
                codeError && classes.twoFAButtonWithError,
              )}
              disabled={disable2FaLoading}
              onClick={handleDisable}
            >
              <Trans>Отключить</Trans>
            </PrimaryMediumButton>
          )}

          {!has2fa && (
            <PrimaryMediumButton
              className={clsx(
                classes.twoFAButton,
                codeError && classes.twoFAButtonWithError,
              )}
              disabled={
                enable2FaLoading || !code || code.length !== CODE_LENGTH
              }
              onClick={handleEnable}
            >
              <Trans>Включить</Trans>
            </PrimaryMediumButton>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      {isNewVersionEnabled ? (
        <Box display='flex' flexDirection={'column'} width={'100%'}>
          <Box mt={12}>
            <Breadcrumbs items={breadcrumbs} />
          </Box>
          <Box mt={14}>
            <Box>
              <Typography className={classes.headerTitle}>
                <Trans>Профиль пользователя</Trans>
              </Typography>
            </Box>

            <Box mt={6}>
              <Box className={classes.rowNew}>
                <label htmlFor='name' className={classes.label}>
                  <Trans>Имя пользователя</Trans>
                </label>

                <LegacyTextField
                  className={classes.textField}
                  error={saveProfileError}
                  helperText={
                    saveProfileError == null ? '' : saveProfileError.message
                  }
                  inputProps={{name: 'name'}}
                  myProps={{placeholderFocused: ''}}
                  placeholder={t('Имя', 'Имя')}
                  value={state.name}
                  onBlur={handleBlurName}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='end'>
                        <PersonOutlineIcon
                          className={classes.inputIcon}
                          htmlColor='#8B8B8B'
                          fontSize='small'
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {data.currentUser.phone && (
                <Box className={classes.rowNew} mt={2}>
                  <label htmlFor='name' className={classes.label}>
                    <Trans>Номер телефона</Trans>
                  </label>

                  <PhoneNumberField
                    className={classes.textField}
                    value={data.currentUser.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='end'>
                          <SmartphoneOutlinedIcon
                            className={classes.inputIcon}
                            htmlColor='#8B8B8B'
                            fontSize='small'
                          />
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                </Box>
              )}

              {!passwordFormVisible && (
                <Box className={classes.rowNew} mt={2}>
                  <label htmlFor='name' className={classes.label}>
                    <Trans>Пароль</Trans>
                  </label>

                  <Button
                    className={classes.passwordButtonNew}
                    color='secondary'
                    variant='outlined'
                    onClick={() => setPasswordFormVisible(!passwordFormVisible)}
                  >
                    <Box>
                      <HttpsOutlinedIcon htmlColor='#8B8B8B' fontSize='small' />
                      <span className={classes.hiddenPasswordField}>
                        ••••••••••••
                      </span>
                    </Box>

                    {!passwordUpdated && (
                      <img
                        src={new URL('./images/edit.svg', import.meta.url)}
                        alt='Profile'
                      />
                    )}
                    {passwordUpdated && (
                      <img
                        src={new URL('./images/done.svg', import.meta.url)}
                        alt='Done'
                      />
                    )}
                  </Button>
                  {passwordUpdated && (
                    <Box className={classes.successMessage}>
                      <Trans>Пароль успешно изменен</Trans>
                    </Box>
                  )}
                </Box>
              )}
              {passwordFormVisible && (
                <>
                  <Box className={classes.rowNew} mt={2}>
                    <PasswordField
                      className={classes.textField}
                      error={Boolean(updatePasswordError)}
                      helperText={
                        updatePasswordError ? updatePasswordError.message : ''
                      }
                      inputProps={{name: 'currentPassword'}}
                      myProps={{
                        placeholderFocused: t(
                          'Текущий пароль',
                          'Текущий пароль',
                        ),
                      }}
                      placeholder={t('Текущий пароль', 'Текущий пароль')}
                      value={state.currentPassword}
                      onBlur={handleBlurPassword}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end'>
                            <HttpsOutlinedIcon
                              className={classes.inputIcon}
                              htmlColor='#8B8B8B'
                              fontSize='small'
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className={classes.rowNew} mt={2}>
                    <PasswordField
                      className={classes.textField}
                      error={Boolean(newPasswordError)}
                      inputProps={{name: 'password'}}
                      myProps={{placeholderFocused: ''}}
                      placeholder={t('Новый пароль', 'Новый пароль')}
                      value={state.password}
                      onBlur={handleBlurPassword}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end'>
                            <HttpsOutlinedIcon
                              className={classes.inputIcon}
                              htmlColor='#8B8B8B'
                              fontSize='small'
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className={classes.rowNew} mt={2}>
                    <PasswordField
                      className={classes.textField}
                      error={Boolean(newPasswordError)}
                      helperText={newPasswordError}
                      inputProps={{name: 'passwordConfirmation'}}
                      myProps={{placeholderFocused: ''}}
                      placeholder={t('Повторите пароль', 'Повторите пароль')}
                      value={state.passwordConfirmation}
                      onBlur={handleBlurPassword}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end'>
                            <HttpsOutlinedIcon
                              className={classes.inputIcon}
                              htmlColor='#8B8B8B'
                              fontSize='small'
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>

            <Box mt={14}>
              <Typography className={classes.headerTitle}>
                <Trans>Безопасность</Trans>
              </Typography>
            </Box>

            <Box mt={6}>
              <Box className={classes.rowNew}>
                <p className={classes.securityTitle}>
                  <Trans>У вас отключена двухфакторная аутентификация</Trans>
                </p>
                <p className={classes.securityDescription}>
                  <Trans>
                    Для подключения двухфакторной аутентификации отсканируйте QR
                    код с помощью вашего приложения для двухфакторной
                    аутентификации и введите код аутентификации в поле ввода.
                  </Trans>
                </p>
              </Box>

              <Box className={classes.rowNew} mt={2}>
                {renderQrCode()}
              </Box>

              <Box className={clsx(classes.rowNew)} mt={2} mb={6}>
                {renderCodeInput()}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box width={288}>
          <Box mb={10}>
            <BackHeader />
          </Box>

          <Box className={classes.row}>
            <LegacyTextField
              error={saveProfileError}
              helperText={
                saveProfileError == null ? '' : saveProfileError.message
              }
              inputProps={{name: 'name'}}
              myProps={{placeholderFocused: ''}}
              placeholder={t('Имя', 'Имя')}
              value={state.name}
              onBlur={handleBlurName}
              onChange={handleChange}
            />
          </Box>

          {data.currentUser.phone && (
            <Box className={classes.row}>
              <PhoneNumberField value={data.currentUser.phone} disabled />
            </Box>
          )}
          {data.currentUser.email && (
            <Box className={classes.row}>
              <EmailField value={data.currentUser.email} disabled />
            </Box>
          )}

          {!passwordFormVisible && (
            <Box className={classes.row}>
              <Button
                className={classes.passwordButton}
                color='secondary'
                variant='outlined'
                onClick={() => setPasswordFormVisible(!passwordFormVisible)}
              >
                <Trans>Пароль</Trans>
                {!passwordUpdated && (
                  <img
                    src={new URL('./images/edit.svg', import.meta.url)}
                    alt='Profile'
                  />
                )}
                {passwordUpdated && (
                  <img
                    src={new URL('./images/done.svg', import.meta.url)}
                    alt='Done'
                  />
                )}
              </Button>
              {passwordUpdated && (
                <Box className={classes.successMessage}>
                  <Trans>Пароль успешно изменен</Trans>
                </Box>
              )}
            </Box>
          )}
          {passwordFormVisible && (
            <>
              <Box className={classes.row}>
                <PasswordField
                  error={Boolean(updatePasswordError)}
                  helperText={
                    updatePasswordError ? updatePasswordError.message : ''
                  }
                  inputProps={{name: 'currentPassword'}}
                  myProps={{
                    placeholderFocused: t('Текущий пароль', 'Текущий пароль'),
                  }}
                  placeholder={t('Текущий пароль', 'Текущий пароль')}
                  value={state.currentPassword}
                  onBlur={handleBlurPassword}
                  onChange={handleChange}
                />
              </Box>
              <Box className={classes.row}>
                <PasswordField
                  error={Boolean(newPasswordError)}
                  inputProps={{name: 'password'}}
                  myProps={{placeholderFocused: ''}}
                  placeholder={t('Новый пароль', 'Новый пароль')}
                  value={state.password}
                  onBlur={handleBlurPassword}
                  onChange={handleChange}
                />
              </Box>
              <Box className={classes.row}>
                <PasswordField
                  error={Boolean(newPasswordError)}
                  helperText={newPasswordError}
                  inputProps={{name: 'passwordConfirmation'}}
                  myProps={{placeholderFocused: ''}}
                  placeholder={t('Повторите пароль', 'Повторите пароль')}
                  value={state.passwordConfirmation}
                  onBlur={handleBlurPassword}
                  onChange={handleChange}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Profile;
