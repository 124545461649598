/** @format */

import {Tab, TabProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

interface Props extends TabProps {
  variant: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    color: '#858585',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    minWidth: 'unset',
    opacity: 1,
    padding: 0,
    paddingRight: 0,
    maxWidth: '100%',
    flexGrow: 1,
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: '#363636',
      color: (props: Props) =>
        props.variant === 'DARK' ? theme.palette.text.primary : '#000000',
    },
  },
}));

const StyledTab = (props: Props) => {
  const classes = useStyles(props);
  return <Tab classes={{root: classes.root}} {...props} />;
};

export default StyledTab;
