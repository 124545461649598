/** @format */
import {useMemo} from 'react';

import * as R from 'ramda';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  CircularProgress,
  Hidden,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from '@material-ui/core/styles';

import logoImg from 'images/logo__white.svg';

import Hr from 'components/SidebarMenu/Hr';

import adTypeHelpers from 'helpers/adTypeHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {AdType} from 'types';

import NotConnectedPlate from './NotConnectedPlate';
import ConnectedPlate from './ConnectedPlate';

interface companyInterface {
  id: string;
  name: string;
  tokens: any;
}

interface Props {
  currentCompany: companyInterface;
  currentAdType: AdType;
  companies: companyInterface[];
  onToggleDrawer: () => void;
}

const useStyles = makeStyles(theme => ({
  companyName: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

const Sidebar = (props: Props) => {
  const navigate = useNavigate();

  const {switchCompany, switchAdType} = useCompanyContext();

  const classes = useStyles();

  const sortedCompanies = useMemo(() => {
    return R.sortBy(R.prop('name'), props.companies);
  }, [props.companies]);

  if (props.companies.length === 0) {
    return (
      <Box
        alignItems='center'
        display='flex'
        flexGrow={1}
        justifyContent='center'
      >
        <CircularProgress size={20} variant='indeterminate' />
      </Box>
    );
  }

  const renderHeader = () => {
    return (
      <>
        <Hidden mdUp>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            mt={3}
          >
            <Link href='/'>
              <img alt='AdSensor Logo' height='30' src={logoImg} width='136' />
            </Link>
            <IconButton
              color='inherit'
              edge='start'
              onClick={props.onToggleDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Hidden>

        <Hidden xsDown>
          <Box display='flex' justifyContent='center' mt={5}>
            <Link href='/'>
              <img alt='AdSensor Logo' height='30' src={logoImg} width='136' />
            </Link>
          </Box>
        </Hidden>
      </>
    );
  };

  const renderCompanies = () => {
    const handleClickPlate = (company: companyInterface, adType: AdType) => {
      switchCompany(company);
      switchAdType(adType);

      props.onToggleDrawer();
      navigate('/home/main', {replace: true});
    };

    return (
      <Box mt={10}>
        {sortedCompanies.map((v, i) => {
          const isContextConnected = adTypeHelpers.isAdTypeConnected(
            v.tokens,
            'CONTEXT',
          );
          const isTargetConnected = adTypeHelpers.isAdTypeConnected(
            v.tokens,
            'TARGET',
          );
          const notConnected = !isContextConnected && !isTargetConnected;

          return (
            <Box key={i}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography className={classes.companyName}>
                  {v.name}
                </Typography>
                <IconButton
                  size='small'
                  component={RouterLink}
                  to={`/home/settings/connect/company?companyId=${v.id}`}
                >
                  <EditIcon />
                </IconButton>
              </Box>
              {isContextConnected && (
                <Box mt={2}>
                  <ConnectedPlate
                    company={v}
                    adType='CONTEXT'
                    currentCompany={props.currentCompany}
                    currentAdType={props.currentAdType}
                    onClick={() => handleClickPlate(v, 'CONTEXT')}
                  />
                </Box>
              )}
              {isTargetConnected && (
                <Box mt={2}>
                  <ConnectedPlate
                    company={v}
                    adType='TARGET'
                    currentCompany={props.currentCompany}
                    currentAdType={props.currentAdType}
                    onClick={() => handleClickPlate(v, 'TARGET')}
                  />
                </Box>
              )}
              {notConnected && (
                <Box mt={2}>
                  <NotConnectedPlate
                    company={v}
                    currentCompany={props.currentCompany}
                    onClick={() => handleClickPlate(v, 'CONTEXT')}
                  />
                </Box>
              )}
              {i + 1 !== sortedCompanies.length && (
                <Box mt={4} mb={0}>
                  <Hr />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box display='flex' flexDirection='column' pl={3} pr={3} pb={5}>
      {renderHeader()}
      {renderCompanies()}
    </Box>
  );
};

export default Sidebar;
