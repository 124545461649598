/** @format */
import {ChangeEvent} from 'react';

import {Theme, useMediaQuery} from '@material-ui/core';
import {ErrorOutline} from '@material-ui/icons';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';

import {Platform} from 'types';

import StyledTab from './StyledTab';
import StyledTabs from './StyledTabs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(1),
    },
    icon: {
      width: 16,
      height: 16,
      color: '#CE5959',
    },
  }),
);

interface Props {
  onChange: (value: Platform) => void;
  activeTabName: string;
  tabs: {
    name: string;
    text: string;
    mobileText?: string;
    hasWarning?: boolean;
  }[];
  variant: string;
}

const NativeTabs = (props: Props) => {
  const classes = useStyles();

  const handleChange = (_event: ChangeEvent<unknown>, value: string) => {
    props.onChange(value as Platform);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <StyledTabs value={props.activeTabName} onChange={handleChange}>
      {props.tabs.map((v, i) => {
        const label = isMobile ? v.mobileText : v.text;

        return (
          <StyledTab
            key={i}
            label={
              v.hasWarning ? (
                <span className={classes.labelContainer}>
                  {label} <ErrorOutline className={classes.icon} />
                </span>
              ) : (
                label
              )
            }
            value={v.name}
            variant={props.variant}
          />
        );
      })}
    </StyledTabs>
  );
};

export default NativeTabs;
