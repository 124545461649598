/** @format */

import {withStyles} from '@material-ui/core/styles';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

const TertiaryMediumButton = withStyles(theme => ({
  root: {
    backgroundColor: '#2D2D2D',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#2D2D2D',
    },
  },
}))(props => <PrimaryMediumButton {...props} />);

export default TertiaryMediumButton;
