/** @format */

import {useState} from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Box, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';

import ArrowBack from 'components/navigation/ArrowBack';
import CreatePasswordFieldFilled from 'components/inputs/CreatePasswordFieldFilled';
import EmailFieldFilled from 'components/inputs/EmailFieldFilled';
import PrimaryLargeButton from 'components/buttons/PrimaryLargeButton';
import {useAuthContext} from 'contexts/AuthContext';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import Footer from './Footer';

const SIGN_UP = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $referrer: String
    $lang: String!
  ) {
    signUpWithEmail(
      email: $email
      password: $password
      referrer: $referrer
      lang: $lang
    ) {
      token
      user {
        id
        uuid
        email
        role
        insertedAt
        firstCompany {
          id
          uuid
          name
        }
      }
    }
  }
`;

const SignUp = () => {
  const navigate = useNavigate();

  const [cookies] = useCookies();

  const authContext = useAuthContext();
  const {mixpanel} = useMixpanelContext();
  const {switchCompany} = useCompanyContext();

  const [state, setState] = useState({});
  const [signUpError, setSignUpError] = useState('');

  const [signUp, {loading}] = useMutation(SIGN_UP);

  const {i18n} = useTranslation();

  const handleChange = event => {
    const {name, value} = event.target;
    setState({...state, [name]: value});
  };

  const handleSubmit = async event => {
    mixpanel.track('click_sign_up_button');
    event.preventDefault();

    // https://stackoverflow.com/questions/59465864
    try {
      const {email, password} = state;
      // Variables with undefined value are removed from GraphQL query
      const referrer = cookies.partner || null;

      const {data} = await signUp({
        // NumberFormat adds trailing spaces
        variables: {
          email: email.trim(),
          password,
          referrer,
          lang: i18n.language,
        },
      });

      await authContext.signUp(data.signUpWithEmail);
      const company = data.signUpWithEmail.user.firstCompany;
      switchCompany(company);

      const companyId = company.id;
      navigate(
        {
          pathname: '/wizard/connect',
          search: `?companyId=${companyId}`,
        },
        {replace: true},
      );
    } catch (e) {
      setSignUpError(e.message);
    }
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Box mb={4}>
        <ArrowBack onClick={handleClickBack}>
          <Trans>Авторизация</Trans>
        </ArrowBack>
      </Box>
      <Typography variant='h1'>
        <Trans>Регистрация</Trans>
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mt={5}>
          <EmailFieldFilled
            error={Boolean(signUpError)}
            helperText={signUpError}
            value={state.email}
            inputProps={{name: 'email'}}
            fullWidth
            onChange={handleChange}
          />
        </Box>

        <Box mt={3}>
          <CreatePasswordFieldFilled
            value={state.password || ''}
            error={Boolean(signUpError)}
            inputProps={{name: 'password'}}
            onChange={handleChange}
          />
        </Box>

        <Box mt={5}>
          <PrimaryLargeButton
            color='primary'
            disabled={loading || !state.email || !state.password}
            type='submit'
            variant='contained'
            fullWidth
            endIcon={<ArrowForwardIcon />}
          >
            <Trans>Продолжить</Trans>
          </PrimaryLargeButton>
        </Box>
      </form>

      {i18n.language === 'ru' && <Footer />}
    </>
  );
};

export default SignUp;
