/** @format */

import {Routes, Route, useLocation} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';

import {ROUTES} from 'constants/ROUTES';

import PrivateRoute from 'components/PrivateRoute';

import SettingsLayout from 'layouts/SettingsLayout';
import MainLayoutNew from 'layouts/MainLayoutNew';

import WizardModals from 'pages/Wizard/Modals';
import {Connect as WizardConnect} from 'pages/Wizard/Connect';

import ConfirmChangePlan from './ConfirmChangePlan';
import ConfirmDeleteCompany from './ConfirmDeleteCompany';
import ConfirmPayAccounts from './ConfirmPayAccounts';
import ConfirmPostpayBudget from './ConfirmPostpayBudget';
import ConfirmPrepayBudget from './ConfirmPrepayBudget';
import Connect from './Connect';
import ExceptionForm from './Exception/Form';
import ExceptionList from './Exception/List';
import Index from './Index/index';
import Manager from './Manager';
import Payment from './Payment';
import PaymentMethods from './PaymentMethods';
import Profile from './Profile';
import Subscription from './Subscription';
import TwoFactorAuth from './TwoFactorAuth';
import WizardCompany from './Company';
import {Company} from './Connect/Company';
import {ConnectedUserList} from './ConnectedUsers/List';
import {EditConnectedUser} from './ConnectedUsers/Edit';
import {Mediaplan} from './Mediaplan';
import {NewConnectedUser} from './ConnectedUsers/New';
import {NotificationForm} from './Notification/Form';
import {NotificationFormNew} from './Notification/FormNew';
import {NotificationList} from './Notification/List';
import {SpecialistForm} from './Specialist/Form';
import {SpecialistList} from './Specialist/List';

const Settings = () => {
  const location = useLocation();

  const {isNewVersionEnabled} = useFlags();

  const background = location.state && location.state.background;

  if (isNewVersionEnabled) {
    return (
      <MainLayoutNew>
        <Routes location={background || location}>
          <Route
            index
            element={
              <PrivateRoute>
                <Index />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_PROFILE}
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_TWO_FACTOR_AUTH}
            element={
              <PrivateRoute>
                <TwoFactorAuth />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_CONNECTED_USERS}
            element={
              <PrivateRoute>
                <ConnectedUserList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.SETTINGS_CONNECT_COMPANY}/*`}
            element={
              <PrivateRoute>
                <Company />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_NOTIFICATION}
            element={
              <PrivateRoute>
                <NotificationFormNew />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.SETTINGS_NOTIFICATIONS}/*`}
            element={
              <PrivateRoute>
                <NotificationList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.SETTINGS_SPECIALISTS}/*`}
            element={
              <PrivateRoute>
                <SpecialistList />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_MANAGER}
            element={
              <PrivateRoute>
                <Manager />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_PAYMENT}
            element={
              <PrivateRoute>
                <Payment />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.SETTINGS_CONNECT}/*`}
            element={
              <PrivateRoute>
                <Connect />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_SUBSCRIPTION}
            element={
              <PrivateRoute>
                <Subscription />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_PAYMENT_METHODS}
            element={
              <PrivateRoute>
                <PaymentMethods />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_EXCEPTIONS}
            element={
              <PrivateRoute>
                <ExceptionList />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.WIZARD}/*`}
            element={
              <PrivateRoute>
                <WizardCompany />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.WIZARD_COMPANY}
            element={
              <PrivateRoute>
                <WizardCompany />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.WIZARD_CONNECT}
            element={
              <PrivateRoute>
                <WizardConnect showInviteGuest />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.WIZARD_COMPANY_CONNECT}
            element={
              <PrivateRoute>
                <WizardConnect showInviteGuest />
              </PrivateRoute>
            }
          />
        </Routes>
        {background && (
          <Routes>
            <Route
              path={`${ROUTES.BEFORE_CONNECT}/*`}
              element={
                <PrivateRoute>
                  <WizardModals.BeforeConnect />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.WIZARD_COMPANY_BEFORE_CONNECT}/*`}
              element={
                <PrivateRoute>
                  <WizardModals.BeforeConnect />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONFIRM_DISCONNECT}
              element={
                <PrivateRoute>
                  <WizardModals.ConfirmDisconnect />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.WIZARD_COMPANY_CONFIRM_DISCONNECT}
              element={
                <PrivateRoute>
                  <WizardModals.ConfirmDisconnect />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.CONNECT_BEFORE_CONNECT}/*`}
              element={
                <PrivateRoute>
                  <WizardModals.BeforeConnect />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONNECT_CONFIRM_DISCONNECT}
              element={
                <PrivateRoute>
                  <WizardModals.ConfirmDisconnect />
                </PrivateRoute>
              }
            />

            <Route
              path={ROUTES.CONNECT_SUBACCOUNTS}
              element={
                <PrivateRoute>
                  <WizardModals.Subaccounts />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.WIZARD_COMPANY_SUBACCOUNTS}
              element={
                <PrivateRoute>
                  <WizardModals.Subaccounts />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONFIRM_DELETE_COMPANY}
              element={
                <PrivateRoute>
                  <ConfirmDeleteCompany />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONFIRM_CHANGE_PLAN}
              element={
                <PrivateRoute>
                  <ConfirmChangePlan />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONFIRM_PAY_ACCOUNTS}
              element={
                <PrivateRoute>
                  <ConfirmPayAccounts />
                </PrivateRoute>
              }
            />

            <Route
              path={ROUTES.SETTINGS_MEDIAPLAN}
              element={
                <PrivateRoute>
                  <Mediaplan />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.SETTINGS_CONNECTED_USERS_ID}
              element={
                <PrivateRoute>
                  <EditConnectedUser />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.SETTINGS_CONNECTED_USERS_NEW}
              element={
                <PrivateRoute>
                  <NewConnectedUser />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.SETTINGS_EXCEPTION}
              element={
                <PrivateRoute>
                  <ExceptionForm />
                </PrivateRoute>
              }
            />
            <Route
              path={`${ROUTES.SETTINGS_SPECIALIST}/*`}
              element={
                <PrivateRoute>
                  <SpecialistForm />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONFIRM_POSTPAY_BUDGET}
              element={
                <PrivateRoute>
                  <ConfirmPostpayBudget />
                </PrivateRoute>
              }
            />
            <Route
              path={ROUTES.CONFIRM_PREPAY_BUDGET}
              element={
                <PrivateRoute>
                  <ConfirmPrepayBudget />
                </PrivateRoute>
              }
            />
          </Routes>
        )}
      </MainLayoutNew>
    );
  }

  return (
    <SettingsLayout>
      <Routes location={background || location}>
        <Route
          index
          element={
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_PROFILE}
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_TWO_FACTOR_AUTH}
          element={
            <PrivateRoute>
              <TwoFactorAuth />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_CONNECTED_USERS}
          element={
            <PrivateRoute>
              <ConnectedUserList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.SETTINGS_CONNECT_COMPANY}/*`}
          element={
            <PrivateRoute>
              <Company />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_NOTIFICATION}
          element={
            <PrivateRoute>
              <NotificationForm />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.SETTINGS_NOTIFICATIONS}/*`}
          element={
            <PrivateRoute>
              <NotificationList />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.SETTINGS_SPECIALISTS}/*`}
          element={
            <PrivateRoute>
              <SpecialistList />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_MANAGER}
          element={
            <PrivateRoute>
              <Manager />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_PAYMENT}
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path={`${ROUTES.SETTINGS_CONNECT}/*`}
          element={
            <PrivateRoute>
              <Connect />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_SUBSCRIPTION}
          element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_PAYMENT_METHODS}
          element={
            <PrivateRoute>
              <PaymentMethods />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.SETTINGS_EXCEPTIONS}
          element={
            <PrivateRoute>
              <ExceptionList />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.WIZARD_COMPANY}
          element={
            <PrivateRoute>
              <WizardCompany />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.WIZARD_CONNECT}
          element={
            <PrivateRoute>
              <WizardConnect showInviteGuest />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.WIZARD_COMPANY_CONNECT}
          element={
            <PrivateRoute>
              <WizardConnect showInviteGuest />
            </PrivateRoute>
          }
        />
      </Routes>
      {background && (
        <Routes>
          <Route
            path={`${ROUTES.BEFORE_CONNECT}/*`}
            element={
              <PrivateRoute>
                <WizardModals.BeforeConnect />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.WIZARD_COMPANY_BEFORE_CONNECT}/*`}
            element={
              <PrivateRoute>
                <WizardModals.BeforeConnect />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.WIZARD_COMPANY_SUBACCOUNTS}
            element={
              <PrivateRoute>
                <WizardModals.Subaccounts />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.WIZARD_COMPANY_CONFIRM_DISCONNECT}
            element={
              <PrivateRoute>
                <WizardModals.ConfirmDisconnect />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONFIRM_DISCONNECT}
            element={
              <PrivateRoute>
                <WizardModals.ConfirmDisconnect />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.CONNECT_BEFORE_CONNECT}/*`}
            element={
              <PrivateRoute>
                <WizardModals.BeforeConnect />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONNECT_CONFIRM_DISCONNECT}
            element={
              <PrivateRoute>
                <WizardModals.ConfirmDisconnect />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.CONNECT_SUBACCOUNTS}
            element={
              <PrivateRoute>
                <WizardModals.Subaccounts />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONFIRM_DELETE_COMPANY}
            element={
              <PrivateRoute>
                <ConfirmDeleteCompany />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONFIRM_CHANGE_PLAN}
            element={
              <PrivateRoute>
                <ConfirmChangePlan />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONFIRM_PAY_ACCOUNTS}
            element={
              <PrivateRoute>
                <ConfirmPayAccounts />
              </PrivateRoute>
            }
          />

          <Route
            path={ROUTES.SETTINGS_MEDIAPLAN}
            element={
              <PrivateRoute>
                <Mediaplan />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_CONNECTED_USERS_ID}
            element={
              <PrivateRoute>
                <EditConnectedUser />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_CONNECTED_USERS_NEW}
            element={
              <PrivateRoute>
                <NewConnectedUser />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.SETTINGS_EXCEPTION}
            element={
              <PrivateRoute>
                <ExceptionForm />
              </PrivateRoute>
            }
          />
          <Route
            path={`${ROUTES.SETTINGS_SPECIALIST}/*`}
            element={
              <PrivateRoute>
                <SpecialistForm />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONFIRM_POSTPAY_BUDGET}
            element={
              <PrivateRoute>
                <ConfirmPostpayBudget />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.CONFIRM_PREPAY_BUDGET}
            element={
              <PrivateRoute>
                <ConfirmPrepayBudget />
              </PrivateRoute>
            }
          />
        </Routes>
      )}
    </SettingsLayout>
  );
};

export default Settings;
