/** @format */

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {Box, LinearProgress, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {gql, useQuery} from '@apollo/client';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Breadcrumbs from 'components/Breadcrumbs';
import BackHeader from 'components/BackHeader';
import ButtonPlate from 'components/plates/ButtonPlate';
import ConnectedProviders from 'components/icons/ConnectedProviders';
import ConnectedProvidersNew from 'components/icons/ConnectedProvidersNew';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import constants from 'helpers/constants';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles(theme => ({
  headerTitle: {
    fontSize: '24px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '16px',
    },
  },
  row: {
    padding: '16px',
    borderRadius: '8px',
    background: '#202020',
  },
  companyTitle: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0.169px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
    },
  },
  companyHeader: {
    width: '100%',
    marginRight: '10px',
  },
  companyArrowPic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    background: '#2B2B2B',
  },
}));

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      companies {
        id
        isClientAccess
        name
        tokens {
          lastError
          provider
        }
      }
    }
  }
`;

const breadcrumbs = [
  {
    href: '/home/settings',
    title: <Trans>Настройки</Trans>,
  },
  {
    href: '',
    title: <Trans>Проекты</Trans>,
  },
];

const Connect = () => {
  const navigate = useNavigate();
  const {mixpanel} = useMixpanelContext();

  const classes = useStyles();

  const {isNewVersionEnabled} = useFlags();

  const {loading, error, data} = useQuery(GET_CURRENT_USER);

  if (loading) return <LinearProgress style={{flex: 1}} />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  const {companies} = data.currentUser;
  const sortedCompanies = R.pipe(
    R.filter(v => !v.isClientAccess),
    R.sortBy(R.prop('name')),
  )(companies);

  const renderCompany = company => {
    const handleClick = () => {
      navigate({
        pathname: '/home/settings/connect/company',
        search: `?companyId=${company.id}`,
      });
    };

    return (
      <ButtonPlate onClick={handleClick}>
        <Box
          alignItems='center'
          display='flex'
          flexGrow={1}
          justifyContent='space-between'
        >
          <Box display='flex' flexDirection='column'>
            <Typography variant='h2'>{company.name}</Typography>
            {company.tokens.length > 0 && (
              <Box mt={3}>
                <ConnectedProviders
                  adTypes={['CONTEXT', 'TARGET', 'STATS']}
                  disabled
                  size={20}
                  tokens={company.tokens}
                />
              </Box>
            )}
          </Box>

          <img
            alt='Arrow'
            height='16'
            src={new URL('./images/arrow_right.png', import.meta.url)}
            width='16'
          />
        </Box>
      </ButtonPlate>
    );
  };

  const renderCompanyNew = company => {
    const handleClick = () => {
      navigate({
        pathname: '/home/settings/connect/company',
        search: `?companyId=${company.id}`,
      });
    };

    return (
      <ButtonPlate onClick={handleClick}>
        <Box
          alignItems='center'
          display='flex'
          flexGrow={1}
          justifyContent='space-between'
        >
          <Box
            display='flex'
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className={classes.companyHeader}
          >
            <Typography className={classes.companyTitle}>
              {company.name}
            </Typography>

            {company.tokens.length > 0 && (
              <ConnectedProvidersNew
                adTypes={['CONTEXT', 'TARGET', 'STATS']}
                disabled
                size={16}
                tokens={company.tokens}
              />
            )}
          </Box>

          <Box className={classes.companyArrowPic}>
            <ChevronRightIcon fontSize='small' />
          </Box>
        </Box>
      </ButtonPlate>
    );
  };

  const handleClickAddNew = () => {
    mixpanel.track('click_settings_add_company_button');
  };
  const plusSign = constants.PLUS;

  return (
    <>
      {isNewVersionEnabled ? (
        <Box>
          <Box mt={12} mb={10}>
            <Breadcrumbs items={breadcrumbs} />
          </Box>

          <Box
            display='flex'
            flexWrap='wrap'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography className={classes.headerTitle}>
              <Trans>Управление проектами</Trans>
            </Typography>

            <Box>
              <PrimaryMediumButton
                component={RouterLink}
                to={{pathname: '/home/settings/wizard/company'}}
                variant='contained'
                onClick={handleClickAddNew}
              >
                <Box mr={2}>
                  <AddCircleOutlineOutlinedIcon fontSize='small' />
                </Box>

                <Trans>Добавить проект</Trans>
              </PrimaryMediumButton>
            </Box>
          </Box>

          <Box mt={6} mb={6} className={classes.row}>
            <Trans>
              В этом разделе вы можете добавлять или удалять проекты, подключать
              или отключать рекламные кабинеты.
            </Trans>
          </Box>

          {sortedCompanies.map((v, i) => (
            <Box key={i} mt={2}>
              {renderCompanyNew(v)}
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <Box mb={10}>
            <BackHeader backPath='/home/settings' />
          </Box>

          <Box
            display='flex'
            flexWrap='wrap'
            alignItems='center'
            justifyContent='space-between'
          >
            <Box>
              <Typography variant='h1'>
                <Trans>Управление проектами</Trans>
              </Typography>
            </Box>
            <Box>
              <SecondaryMediumButton
                component={RouterLink}
                to={{pathname: '/home/settings/wizard/company'}}
                variant='contained'
                onClick={handleClickAddNew}
              >
                <Trans>{{plusSign}} Добавить проект</Trans>
              </SecondaryMediumButton>
            </Box>
          </Box>

          <Box mt={6}>
            <Typography variant='body1'>
              <Trans>
                В этом разделе вы можете добавлять или удалять проекты,
                подключать или отключать рекламные кабинеты.
              </Trans>
            </Typography>
          </Box>

          {sortedCompanies.map((v, i) => (
            <Box key={i} mt={5}>
              {renderCompany(v)}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default Connect;
