/** @format */

import {Tabs, TabsTypeMap} from '@material-ui/core';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';
import {withStyles} from '@material-ui/core/styles';

const StyledTabsNew = withStyles(_theme => ({
  root: {
    borderRadius: '8px 8px 0 0',
    height: 48,
    minHeight: 48,
    backgroundColor: '#202020',
    boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
  },
  indicator: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'flex-start',
    height: 1,
  },
}))(props => (
  <Tabs TabIndicatorProps={{children: <span />}} {...props} />
)) as OverridableComponent<TabsTypeMap>;

export default StyledTabsNew;
