/** @format */

import {useNavigate} from 'react-router-dom';

import Modal from 'components/Modal';

type Props = {
  backgroundColor?: string;
  header: string | React.ReactNode;
  footer?: string | React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
};

const ModalPage = (props: Props) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }

    navigate(-1);
  };

  return <Modal {...props} onClose={handleClose} />;
};

export default ModalPage;
