/** @format */
import {useState} from 'react';

import {Box, Menu, MenuItem} from '@material-ui/core';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

type Item = {
  id: string,
  text: string,
};

type Props = {
  text: string,
  items: Item[],
  onSelect: (id: string) => void,
};

const PrymaryMenu = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (id: string) => {
    handleClose();
    props.onSelect(id);
  };

  return (
    <Box>
      <PrimaryMediumButton onClick={handleClick}>
        {props.text}
      </PrimaryMediumButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.items.map(v => (
          <MenuItem
            key={v.id}
            onClick={() => handleSelectItem(v.id)}
          >
            {v.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default PrymaryMenu;
