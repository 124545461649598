/** @format */

import {Box, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.action.hover,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: '20px',
  },
  link: {
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box mt={2}>
      <Typography className={classes.text}>
        <Trans>Cуммы с учётом НДС</Trans>
      </Typography>
    </Box>
  );
};

export default Footer;
