/** @format */

import {useEffect, useState} from 'react';

import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import {Box, Divider, Grid, Link, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {format, parseISO} from 'date-fns';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import Loading from 'components/loading/Loading';
import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PaymentMethod from 'pages/Home/Settings/PaymentMethods/PaymentMethod';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import miscHelpers from 'helpers/miscHelpers';
import numberHelpers from 'helpers/numberHelpers';
import paymentHelpers from 'helpers/paymentHelpers';
import routerHelpers from 'helpers/routerHelpers';

import PlusMinusButton from './PlusMinusButton';

const GET_SUBSCRIPTION = gql`
  query GetSubscription {
    currentUser {
      tokensCount
      subscription {
        activeSubscription
        beginDate
        endDate
        planId
      }
      currentAccountsPriceInfo {
        price
        count
      }
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUser($count: Int!, $addToCurrentPeriod: Boolean!) {
    currentUser {
      accountsPriceInfo(
        count: $count
        addToCurrentPeriod: $addToCurrentPeriod
      ) {
        price
      }
      paymentMethods {
        id
        paymentSystem
        last4
        isExpired
      }
    }
  }
`;

const PAY_ACCOUNTS = gql`
  mutation PayAccounts(
    $count: Int!
    $addToCurrentPeriod: Boolean!
    $paymentMethodId: Int!
  ) {
    payAccounts(
      count: $count
      addToCurrentPeriod: $addToCurrentPeriod
      paymentMethodId: $paymentMethodId
    ) {
      confirmationUrl
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      color: '#4A4A4A',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
    link: {
      textDecoration: 'underline',
      verticalAlign: 'baseline',
    },
    rowText: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    divider: {
      backgroundColor: '#DDDDDD',
      marginTop: theme.spacing(6),
    },
    totalText: {
      color: theme.palette.text.secondary,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
);

type Subscription = {
  activeSubscription: boolean;
  beginDate: string | null;
  endDate: string | null;
  planId: number | null;
};

const ConfirmPayAccounts = () => {
  const navigate = useNavigate();
  const searchParams = routerHelpers.useSearchParams();
  const addAccounts = Boolean(searchParams.get('addAccounts'));

  const classes = useStyles();

  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(0);

  const {
    loading: getSubscriptionLoading,
    error: getSubscriptionError,
    data: getSubscriptionData,
  } = useQuery(GET_SUBSCRIPTION);

  const subscription: Subscription = getSubscriptionData
    ? getSubscriptionData.currentUser.subscription
    : {activeSubscription: false, beginDate: null, endDate: null, planId: null};

  const isActiveSubscription = subscription.activeSubscription;
  const payFull = !addAccounts || !isActiveSubscription;

  const {loading, error, data} = useQuery(GET_CURRENT_USER, {
    // Passing accounts = 0 to /accounts_price_info API results
    // in field validation error since Go thinks 0 == null
    skip: !getSubscriptionData || count === 0,
    variables: {count, addToCurrentPeriod: !payFull},
  });

  const [payAccounts, {loading: payAccountsLoading}] =
    useMutation(PAY_ACCOUNTS);

  useEffect(() => {
    if (!getSubscriptionData) return;
    if (!payFull) return;
    setCount(getSubscriptionData.currentUser.tokensCount);
  }, [getSubscriptionData, payFull]);

  useEffect(() => {
    if (!data) return;
    setPrice(data.currentUser.accountsPriceInfo.price);
  }, [data]);

  if (getSubscriptionLoading) return <Loading />;
  if (loading) return <ModalLoading title='Пожалуйста, подождите' />;

  if (getSubscriptionError) {
    return <Alert severity='error'>{getSubscriptionError.message}</Alert>;
  }
  if (error) {
    return <Alert severity='error'>{error.message}</Alert>;
  }

  const daysLeft = paymentHelpers.daysLeft(subscription);
  const daysLeftText = miscHelpers.inflectNoun(daysLeft, 'день', 'дня', 'дней');

  const {price: fullPrice, count: totalCount} =
    getSubscriptionData.currentUser.currentAccountsPriceInfo;

  const {paymentMethods} = data.currentUser;
  const activePaymentMethod = paymentMethods.find(
    (v: PaymentMethod) => !v.isExpired,
  );

  // totalCount === 0 if user has deleted all accounts
  const pricePerAccount = totalCount === 0 ? 0 : fullPrice / totalCount;

  const endDate = parseISO(subscription.endDate as string);
  const formattedEndDate = format(endDate, 'd MMMM', {locale: ru});
  const fullPriceText = numberHelpers.formatRUB(pricePerAccount);

  const header = payFull
    ? 'Оплата подписки'
    : 'Выберите, сколько кабинетов вы хотите добавить';

  const renderTopText = () => {
    const handleClickSettings = () => {
      navigate('/home/settings/connect', {replace: true});
    };

    if (!payFull) {
      return (
        <Typography className={classes.body} display='inline'>
          {'Сможете подключить Гугл, Яндекс, ВК или Фейсбук'}
        </Typography>
      );
    }

    return (
      <>
        <Typography className={classes.body} display='inline'>
          {'Если кабинетов слишком много, вы всегда можете отключить лишние в '}
        </Typography>
        <Link
          className={classes.link}
          component='button'
          onClick={handleClickSettings}
        >
          <Typography
            className={clsx(classes.body, classes.link)}
            display='inline'
          >
            Управлении кабинетами
          </Typography>
        </Link>
      </>
    );
  };

  const renderAccountsInfo = () => {
    if (payFull) {
      const tokenText = miscHelpers.inflectNoun(
        count,
        'кабинет',
        'кабинета',
        'кабинетов',
      );

      return (
        <Box mt={3}>
          <Typography className={classes.body}>
            {`У вас подключено ${count} ${tokenText}`}
          </Typography>
        </Box>
      );
    }

    const handleClickPlus = () => {
      setCount(count + 1);
    };

    const handleClickMinus = () => {
      if (count === 0) return;
      if (count === 1) setPrice(0);
      setCount(count - 1);
    };

    return (
      <Box alignItems='center' display='flex' mt={3}>
        <Box mr={3}>
          <Typography className={classes.body}>
            Количество дополнительных кабинетов
          </Typography>
        </Box>

        <PlusMinusButton type='MINUS' onClick={handleClickMinus} />
        <Box px={2}>
          <Typography className={classes.body}>{count}</Typography>
        </Box>
        <PlusMinusButton type='PLUS' onClick={handleClickPlus} />
      </Box>
    );
  };

  const renderDaysLeft = () => {
    if (payFull) return null;

    return (
      <Box mt={6}>
        <Box display='flex' flexGrow={1} justifyContent='space-between'>
          <Typography className={classes.rowText}>
            {'Осталось дней до конца расчётного периода'}
          </Typography>
          <Typography className={classes.rowText}>
            {`${daysLeft} ${daysLeftText}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderBottomText = () => {
    if (payFull) return null;

    const accountsText = miscHelpers.inflectNoun(
      count,
      'кабинета',
      'кабинетов',
      'кабинетов',
    );

    return (
      <Box mt={3}>
        <Typography className={classes.rowText}>
          {`Стоимость проверки ${count} ${accountsText} за ${daysLeft} ${daysLeftText}`}
        </Typography>

        <Box mt={4}>
          <Typography className={classes.body}>
            {`С ${formattedEndDate} с вас спишется полная стоимость — ` +
              `${fullPriceText} за каждый подключенный кабинет.`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderButton = () => {
    const handlePay = async () => {
      const paymentMethodId = activePaymentMethod ? activePaymentMethod.id : -1;

      const {data: payAccountsData} = await payAccounts({
        variables: {count, addToCurrentPeriod: !payFull, paymentMethodId},
      });

      const {confirmationUrl} = payAccountsData.payAccounts;

      if (confirmationUrl) {
        window.location.href = confirmationUrl;
      } else {
        navigate('/home/main', {replace: true});
        navigate(0);
      }
    };

    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <PrimaryMediumButton
            disabled={count === 0 || payAccountsLoading}
            fullWidth
            onClick={handlePay}
          >
            {`Оплатить ${numberHelpers.formatRUB(price)}`}
          </PrimaryMediumButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalPage header={header}>
      {renderTopText()}

      <Box mt={15}>
        <Box display='flex' flexGrow={1} justifyContent='space-between'>
          <Typography className={classes.rowText}>
            {'Стоимость проверки одного кабинета за 30 дней'}
          </Typography>
          <Typography className={classes.rowText}>{fullPriceText}</Typography>
        </Box>
      </Box>

      {renderAccountsInfo()}
      {renderDaysLeft()}

      <Divider className={classes.divider} />

      <Box mt={5}>
        <Box display='flex' flexGrow={1} justifyContent='space-between'>
          <Typography className={classes.totalText}>Итого</Typography>
          <Typography className={classes.totalText}>
            {numberHelpers.formatRUB(price)}
          </Typography>
        </Box>
      </Box>

      {renderBottomText()}

      <Box mt={16}>
        {activePaymentMethod && (
          <Box mb={3}>
            <PaymentMethod
              paymentMethod={activePaymentMethod}
              variant='LIGHT'
            />
          </Box>
        )}

        {renderButton()}
      </Box>
    </ModalPage>
  );
};

export default ConfirmPayAccounts;
