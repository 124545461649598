/** @format */

import {useState} from 'react';

import {TextField} from '@material-ui/core';

import Hint from 'components/Hint';

const BaseTextField = props => {
  const [placeholder, setPlaceholder] = useState(props.placeholder);
  const {myProps = {}, ...muiProps} = props;

  const handleBlur = () => {
    setPlaceholder(muiProps.placeholder);
    if (props.onBlur) props.onBlur();
  };

  const handleFocus = () => {
    setPlaceholder(myProps.placeholderFocused);
  };

  return (
    <>
      <TextField
        {...muiProps}
        className={props.className}
        placeholder={placeholder}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {myProps.hint && <Hint text={myProps.hint} />}
    </>
  );
};

export default BaseTextField;
