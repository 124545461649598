/** @format */

import {Alert, AlertTitle} from '@material-ui/lab';
import {Box, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';

import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import routerHelpers from 'helpers/routerHelpers';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: '20px',
    whiteSpace: 'pre-line',
  },
}));

const AccessDenied = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const searchParams = routerHelpers.useSearchParams();
  const returnPath = searchParams.get('return_path');

  const handleClick = async () => {
    if (returnPath) {
      navigate(returnPath, {replace: true});
    } else {
      navigate('/', {replace: true});
    }
  };

  return (
    <Box>
      <Alert severity='warning'>
        <AlertTitle>
          <Typography className={classes.text}>
            <Trans>
              Ошибка получения доступа к рекламному кабинету.
              <br />
              <br />
              Вероятно, вы нажали кнопку &quot;Отмена&quot; в момент, когда
              AdSensor попросил доступ. Подключите рекламный аккаунт ещё раз и
              разрешите доступ приложению AdSensor.
            </Trans>
          </Typography>
        </AlertTitle>
      </Alert>

      <Box mt={4}>
        <PrimaryMediumButton onClick={handleClick}>
          <Trans>Вернуться на сайт</Trans>
        </PrimaryMediumButton>
      </Box>
    </Box>
  );
};

export default AccessDenied;
