/** @format */

import clsx from 'clsx';
import {Box, Link, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '22px',
    whiteSpace: 'pre-line',
  },
  underline: {
    textDecoration: 'underline',
  },
}));

const No2FA = () => {
  const classes = useStyles();

  return (
    <Box mt={5}>
      <Typography className={classes.body} display='inline'>
        <Trans>
          Мы не можем получить данные по вашему кабинету, пока вы не подключите
          двухфакторную аутентификацию.
        </Trans>
      </Typography>
      <Link
        className={clsx(classes.body, classes.underline)}
        display='inline'
        href='/Инструкция_2FA.pdf'
        target='_blank'
      >
        <Trans>Инструкция</Trans>
      </Link>
    </Box>
  );
};

export default No2FA;
