/** @format */
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {Box, Typography} from '@material-ui/core';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {format, parseISO} from 'date-fns';

import BoxPlate from 'components/plates/BoxPlate';

import type {GraphCheck} from 'types';

import CustomXTick from './CustomXTick';
import CustomYTick from './CustomYTick';
import Legend from './Legend';

const OLD_COLOR = '#6288EF';
const NEW_COLOR = '#CE8080';
const FIXED_COLOR = '#D5D5D5';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '8px 8px 0 0',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    legend: {
      justifyContent: 'center',
      backgroundColor: '#242424',
      padding: theme.spacing(4),
    },
    tooltip: {
      alignSelf: 'flex-start',
      backgroundColor: '#1A1A1A',
      borderRadius: 5,
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      fontWeight: 400,
      height: 110,
      justifyContent: 'space-between',
      lineHeight: '20px',
      padding: '8px 16px',
      width: 190,
    },
  }),
);

type Props = {
  graphChecks: GraphCheck[];
};

const CheckGraph = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const renderGraph = () => {
    const formatDateValue = (dateValue: string) => {
      if (props.graphChecks.length === 0) {
        return '';
      }

      const date = parseISO(dateValue);
      return format(date, 'dd.MM');
    };
    return (
      <Box width='100%' height={300} pb={3} pr={3} pt={3}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart data={props.graphChecks} margin={{left: 10, right: 10}}>
            <XAxis
              axisLine={false}
              dataKey='collectedOn'
              interval='preserveStartEnd'
              tickLine={false}
              tick={<CustomXTick formatDateValue={formatDateValue} />}
            />
            <YAxis axisLine={false} tick={<CustomYTick />} tickLine={false} />

            <CartesianGrid stroke='#4D4D4D' vertical={false} />

            <Tooltip
              content={
                <CustomTooltip formatDateValue={formatDateValue} showFixed />
              }
              cursor={false}
            />

            <Bar
              barSize={3}
              dataKey='old'
              radius={[8, 8, 8, 8]}
              stackId='found'
            >
              {props.graphChecks.map((_, index: number) => {
                return <Cell key={index} fill={OLD_COLOR} />;
              })}
            </Bar>

            <Bar
              barSize={3}
              dataKey='new'
              radius={[8, 8, 8, 8]}
              stackId='found'
            >
              {props.graphChecks.map((_, index: number) => {
                return <Cell key={index} fill={NEW_COLOR} />;
              })}
            </Bar>

            <Bar barSize={3} dataKey='fixed' radius={[8, 8, 8, 8]}>
              {props.graphChecks.map((_, index: number) => {
                return <Cell key={index} fill={FIXED_COLOR} />;
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  const renderLegend = () => {
    const items = [
      {
        text: t('старые', 'старые'),
        tooltip: null,
        backgroundColor: OLD_COLOR,
      },
      {
        text: t('новые', 'новые'),
        tooltip: null,
        backgroundColor: NEW_COLOR,
      },
      {
        text: t('исправлено', 'исправлено'),
        tooltip: null,
        backgroundColor: FIXED_COLOR,
      },
    ];

    return (
      <Box>
        <Legend
          className={classes.legend}
          items={items}
          smGridSize={2}
          xsGridSize={3}
          isBigDots
        />
      </Box>
    );
  };

  return (
    <Box mt={6}>
      <BoxPlate className={classes.container}>
        {renderGraph()}
        {renderLegend()}
      </BoxPlate>
    </Box>
  );
};

const CustomTooltip = (props: any) => {
  const classes = useStyles();

  if (!props.active) return null;
  if (!props.payload) return null;
  if (props.payload.length === 0) return null;

  const {collectedOn} = props.payload[0].payload;

  return (
    <Box className={classes.tooltip}>
      <Box>
        <Typography style={{color: OLD_COLOR}}>
          {props.payload[0].payload.old}
        </Typography>
        <Typography style={{color: NEW_COLOR}}>
          {props.payload[1].payload.new}
        </Typography>
        <Typography style={{color: FIXED_COLOR}}>
          {props.payload[2].payload.fixed}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography>{props.formatDateValue(collectedOn)}</Typography>
      </Box>
    </Box>
  );
};

export default CheckGraph;
