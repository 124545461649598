/** @format */
import React from 'react';

import {Box, Link, Typography} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useLocation} from 'react-router-dom';

type Props = {
  text: string;
  activeImg: string;
  inactiveImg: string;
  to: {
    pathname: string;
    search?: string;
  };
  openBlank?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    cursor: 'pointer',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    width: '100%'
  },
  current: {
    cursor: 'inherit',
    backgroundColor: '#73A011',
    width: 2
  },
  link: {
    display: 'inline-block',
    width: '100%'
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px'
  }
}));

const MenuItemLink = (props: Props) => {
  const styles = useStyles();
  const {pathname} = useLocation();

  const isActive = props.to.pathname.startsWith(pathname)

  const icon = () => {
    if (isActive) {
      return props.activeImg;
    }
    return props.inactiveImg;
  };

  const renderContent = () => {
    return (
      <Box display='flex' justifyContent='space-between' className={styles.container}>
        <Box display='flex'>
          <Box mr={2}>
            <img src={icon()} width={24} height={24} />
          </Box>
          <Box>
            <Typography className={styles.text}>{props.text}</Typography>
          </Box>
        </Box>
        {isActive && (
          <Box className={styles.current}></Box>
        )}
      </Box>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  if (isActive) {
    return renderContent();
  } else {
    return (
      <Link
        to={props.to}
        component={RouterLink}
        target={props.openBlank ? '_blank' : '_self'}
        className={styles.link}
        onClick={handleClick}
      >
        {renderContent()}
      </Link>
    );
  }
};

export default MenuItemLink;
