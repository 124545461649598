/** @format */

import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.success.main,
    height: 44,
    width: 44,
    '&:hover': {
      backgroundColor: theme.custom_palette.success.hover,
    },
  },
}));

const ConnectButton = props => {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} onClick={props.onConnect}>
      <img
        alt='Connect'
        height='19'
        src={new URL('./images/plus.png', import.meta.url)}
        width='19'
      />
    </IconButton>
  );
};

export default ConnectButton;
