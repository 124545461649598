/** @format */
import {useState} from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';

import LegacyTextField from 'components/inputs/LegacyTextField';

interface OptionData {
  id: string;
  title: string;
}

interface Props<T> {
  options: T[];
  placeholder?: string | null;
  onChange: (option: T | null) => void;
  onStartTyping: () => void;
}

const AutocompleteField = <T extends OptionData>(props: Props<T>) => {
  const [startInteracting, setStartInteracting] = useState(false);
  return (
    <Autocomplete
      freeSolo
      options={props.options}
      getOptionLabel={option => option.title}
      renderInput={params => (
        <LegacyTextField
          {...params}
          placeholder={props.placeholder}
          InputProps={{...params.InputProps, type: 'search'}}
        />
      )}
      onChange={(_event, value, _reason, _details) => {
        if (typeof value === 'string') return;

        props.onChange(value);
      }}
      onInputChange={(event, value, reason) => {
        if (!startInteracting) {
          props.onStartTyping();
          setStartInteracting(true);
        }
      }}
    />
  );
};

export default AutocompleteField;
