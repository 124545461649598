/** @format */

import {Box, Grid, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';

import Modal from 'components/Modal';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';

type Props = {
  header: string;
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmRemove = (props: Props) => {
  const renderButtons = () => {
    return (
      <Box mt={16}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton fullWidth onClick={props.onConfirm}>
              <Trans>Удалить</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item xs={12} sm={4}>
            <SecondaryMediumButton fullWidth onClick={props.onCancel}>
              <Trans>Оставить</Trans>
            </SecondaryMediumButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Modal header={props.header} showCloseIcon={false}>
      <Typography variant='body1'>{props.text}</Typography>

      {renderButtons()}
    </Modal>
  );
};

export default ConfirmRemove;
