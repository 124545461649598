/** @format */

import {Backdrop, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export const STEP_BACKDROP_BACKDROP_Z_INDEX = 1001;
export const STEP_BACKDROP_CONTAINER_Z_INDEX = 1002;

const useStyles = makeStyles(_theme => ({
  backdrop: {
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    zIndex: STEP_BACKDROP_BACKDROP_Z_INDEX,
  },
  container: {
    position: 'relative',
    zIndex: props =>
      props.enabled && props.highlight
        ? STEP_BACKDROP_CONTAINER_Z_INDEX
        : 'auto',
  },
}));

const StepBackdrop = props => {
  const classes = useStyles(props);

  return (
    <>
      {props.enabled && (
        <Backdrop className={classes.backdrop} open transitionDuration={1000} />
      )}
      <Box className={classes.container}>{props.children}</Box>
    </>
  );
};

export default StepBackdrop;
