/** @format */

import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {useFlags} from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles(_theme => ({
  button: {
    backgroundColor: '#332323',
    height: 44,
    width: 44,
    '&:hover': {
      backgroundColor: '#231313',
    },
  },
}));

const DisconnectButton = props => {
  const classes = useStyles();

  const {isNewVersionEnabled} = useFlags();

  if (isNewVersionEnabled) {
    return (
      <IconButton className={props.className} onClick={props.onDisconnect}>
        <RemoveCircleIcon fontSize='small' htmlColor='#AE5658' />
      </IconButton>
    );
  }

  return (
    <IconButton className={classes.button} onClick={props.onDisconnect}>
      <img
        alt='Disconnect'
        height='2'
        src={new URL('./images/minus.png', import.meta.url)}
        width='19'
      />
    </IconButton>
  );
};

export default DisconnectButton;
