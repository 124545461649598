/** @format */

import clsx from 'clsx';
import {Box, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import Plate from './Plate';

interface companyInterface {
  id: string
  tokens: any
}

interface Props {
  company: companyInterface
  currentCompany: companyInterface
  onClick: () => void
}

const useStyles = makeStyles(theme => ({
  plateText: {
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '17px',
  },
  selectedPlateText: {
    color: '#333333',
  },
}));

const NotConnectedPlate = (props: Props) => {
  const classes = useStyles();

  const isCurrentCompany = props.company.id === props.currentCompany.id;

  return (
    <Plate
      selected={isCurrentCompany}
      onClick={props.onClick}
    >
      <Box
        alignItems='center'
        display='flex'
        flexGrow={1}
        justifyContent='space-between'
      >
        <Typography
          className={clsx(classes.plateText, {
            [classes.selectedPlateText]: isCurrentCompany,
          })}
        >
          <Trans>⚠️ Нужно подключить доступы</Trans>
        </Typography>
      </Box>
    </Plate>
  );
};

export default NotConnectedPlate;
