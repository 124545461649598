/** @format */

import {Backdrop, Box, IconButton, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  header: string | React.ReactNode;
  footer?: string | React.ReactNode;
  children: React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  backgroundColor: string;
  onClose?: () => void;
  showCloseIcon?: boolean;
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    zIndex: theme.zIndex.drawer + 1,
  },
  backdropNew: {
    opacity: '0.9',
    background: '#111',
    backdropFilter: 'blur(2px)',
    zIndex: theme.zIndex.drawer + 1,
  },
  // https://front-back.com/how-to-make-absolute-positioned-elements-overlap-their-overflow-hidden-parent/
  //
  // Outer container is used as a relative parent for close icon.
  // Inner container is used to crop content (with border radius).
  //
  // Inner container can't be used as relative parent for close icon since it
  // would hide the icon because of `overflow: hidden` property.
  outerContainer: {
    minWidth: 350,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'stretch',
      flexGrow: 1,
    },
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center',
    },
  },
  outerOuterContainer: {
    // Set max width for `overflow-x: scroll` to work
    maxWidth: '100vw',
    minWidth: 350,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: (props: Props) => props.backgroundColor,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'stretch',
      flexGrow: 1,
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center',
      borderRadius: 24,
    },
  },
  outerOuterContainerNew: {
    // Set max width for `overflow-x: scroll` to work
    maxWidth: '100vw',
    minWidth: 350,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    backgroundColor: (props: Props) => props.backgroundColor,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'stretch',
      flexGrow: 1,
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'center',
    },
  },
  innerContainer: {
    backgroundColor: (props: Props) => props.backgroundColor,
    overflow: 'auto',
    // Fix for Firefox on Windows (Chrome doesn't know this property at all)
    scrollbarWidth: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: 584,
      maxWidth: 650,
    },
  },
  innerContainerNew: {
    backgroundColor: (props: Props) => props.backgroundColor,
    overflow: 'auto',
    // Fix for Firefox on Windows (Chrome doesn't know this property at all)
    scrollbarWidth: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: 584,
      maxWidth: 650,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  closeIconNew: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(5),
  },
  header: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(7),
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(12),
      paddingTop: theme.spacing(5),
      maxWidth: 650,
    },
  },
  headerNew: {
    padding: theme.spacing(8),
  },
  headerText: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '23px',
  },
  headerTextNew: {
    color: theme.palette.text.secondary,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  content: {
    color: '#000000',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      paddingTop: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6),
      paddingBottom: theme.spacing(0),
      paddingTop: 0,
    },
  },
  contentNew: {
    color: '#000000',
    padding: theme.spacing(8),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

// https://reactrouter.com/web/example/modal-gallery
const Modal = (props: Props) => {
  const classes = useStyles(props);

  const {isNewVersionEnabled} = useFlags();

  const handleClose = (event: React.SyntheticEvent) => {
    event.stopPropagation();

    if (props.onClose) {
      props.onClose();
    }
  };

  return isNewVersionEnabled ? (
    <Backdrop className={classes.backdropNew} open transitionDuration={50}>
      <Box className={classes.outerContainer}>
        <Box className={classes.outerOuterContainerNew}>
          <Box className={classes.headerNew}>
            <Typography className={classes.headerTextNew}>
              {props.header}
            </Typography>
          </Box>
          <Box className={classes.innerContainerNew}>
            {props.showCloseIcon && (
              <IconButton
                className={classes.closeIconNew}
                onClick={handleClose}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            )}

            <Box className={classes.contentNew}>{props.children}</Box>
          </Box>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>
              {props.footer}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  ) : (
    <Backdrop className={classes.backdrop} open transitionDuration={50}>
      <Box className={classes.outerContainer}>
        <Box className={classes.outerOuterContainer}>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>
              {props.header}
            </Typography>
          </Box>
          <Box className={classes.innerContainer}>
            {props.showCloseIcon && (
              <IconButton className={classes.closeIcon} onClick={handleClose}>
                <img
                  alt='Close'
                  src={new URL(
                    './images/close.svg',
                    import.meta.url,
                  ).toString()}
                />
              </IconButton>
            )}

            <Box className={classes.content}>{props.children}</Box>
          </Box>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>
              {props.footer}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
};

Modal.defaultProps = {
  backgroundColor: '#FFFFFF',
  onClose: null,
  showCloseIcon: true,
};

export default Modal;
