/** @format */
import {Box} from '@material-ui/core';

import TelegramIcon from '@material-ui/icons/Telegram';
import {Fab} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

import routerHelpers from 'helpers/routerHelpers';
import {useMixpanelContext} from 'contexts/MixpanelContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      backgroundColor: '#2E9ED6',
      bottom: theme.spacing(5),
      position: 'fixed',
      right: theme.spacing(5),
      '&:hover': {
        backgroundColor: '#1E8EC6',
      },
    },
    icon: {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      fontSize: 28,
    },
  }),
);

const TelegramFab = () => {
  const classes = useStyles();
  const mixpanelContext = useMixpanelContext();

  if (!routerHelpers.IsRuVersion()) {
    return (<Box></Box>);
  }

  const handleClick = () => {
    window.dataLayer?.push({event: 'app_click_telegram'});
    mixpanelContext?.mixpanel.track('click_fab_telegram');
  };

  return (
    <Fab
      className={classes.fab}
      href='https://t.me/adsensor_amocrm_bot'
      target='_blank'
      onClick={handleClick}
    >
      <TelegramIcon className={classes.icon} />
    </Fab>
  );
};

export default TelegramFab;
