/** @format */

import {Button} from '@material-ui/core';
import {Theme, createStyles, withStyles} from '@material-ui/core/styles';

type Props = {
  ml?: number
  onClick: () => void;
};

const OutlinedLargeButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #202020',
      borderRadius: 16,
      fontSize: 16,
      fontWeight: 500,
      height: 64,
      lineHeight: '20px',
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      textTransform: 'none',
    },
  }),
)((props: Props) => <Button {...props} variant='outlined' />);

export default OutlinedLargeButton;
