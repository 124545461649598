/** @format */

import {useEffect} from 'react';

import {useMachine} from '@xstate/react';

import {constantCase} from 'change-case';

// https://gist.github.com/mircostraessle/7d6d3ee22a1a3dd7e848ca94095fd901
const usePersistedMachine = machine => {
  const localStorageKey = constantCase(machine.id);

  // https://xstate.js.org/docs/packages/xstate-react/#persisted-and-rehydrated-state
  const persistedState =
    JSON.parse(localStorage.getItem(localStorageKey)) || machine.initialState;

  const [current, send, service] = useMachine(machine, {
    state: persistedState,
  });

  // https://xstate.js.org/docs/packages/xstate-react/#services
  useEffect(() => {
    const subscription = service.subscribe(state => {
      localStorage.setItem(localStorageKey, JSON.stringify(state));
    });

    return subscription.unsubscribe;
  }, [service, localStorageKey]);

  return {current, send, service};
};

export default {
  usePersistedMachine,
};
