/** @format */

import {Box, LinearProgress, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import Modal from 'components/Modal';

const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.secondary,
  },
  progress: {
    backgroundColor: theme.palette.success.main,
    flex: 1,
  },
}));

const Loading = props => {
  const classes = useStyles();

  return (
    <Modal header={props.title} showCloseIcon={false}>
      {props.body && (
        <Box mb={10}>
          <Typography className={classes.body} variant='body1'>
            {props.body}
          </Typography>
        </Box>
      )}

      <LinearProgress className={classes.progress} />
    </Modal>
  );
};

export default Loading;
