/** @format */

export const ROUTES = {
  OOPS: 'oops',
  ERRORS: 'errors',

  INDEX: '/',
  AUTH: 'auth',
  ADMIN: 'admin',
  GUEST: 'guest',
  WIZARD: 'wizard',
  HOME: 'home',
  HOME_NEW: 'home_new',
  SHARE: 'share',
  ADMIN_STATS: 'stats',

  EMAIL_SING_IN: 'sign-in-email',
  EMAIL_SING_UP: 'sign-up-email',
  PHONE_SIGN_UP: 'sign-up-phone',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',

  RESET_PASSWORD_SENT: 'reset-password/sent',
  RESET_PASSWORD: 'reset-password',
  PHONE_RESET_PASSWORD: 'reset-password-phone',
  EMAIL_RESET_PASSWORD: 'reset-password-email',

  VERIFY_CODE: 'verify-code',

  DEV_TRANSLATIONS: 'translations',
  DEV_MANAGERS: 'managers',
  DEV_INPUTS: 'inputs',
  DEV_BUTTONS: 'buttons',
  DEV_DASHBOARD: 'dashboard',
  DEV_SELECTS: 'selects',
  DEV_MENUS: 'menus',

  ASSESS_DENIED: 'access-denied',

  GUEST_CONNECT: 'connect',
  GUEST_FINISH: 'finish',
  GUEST_HELP: 'help',
  GUEST_BEFORE_CONNECT: 'connect/before-connect',
  GUEST_CONFIRM_DISCONNECT: 'connect/confirm-disconnect',
  GUEST_SUBACCOUNTS: 'connect/subaccounts',

  MAIN: 'main',
  MAIN_WAIT: 'wait',
  MAIN_STATUS: 'status',
  MAIN_CATEGORY: 'category',
  MAIN_DETAILS: 'details',
  MAIN_CONGRATULATION: 'congratulation',
  MAIN_BUSINESS_METRICS: 'business-metrics',
  MAIN_GRAPHS: 'graphs',
  MAIN_WAIT_FOR_PAYMENT: 'wait-for-payment',
  MAIN_ERROR_SETTINGS: 'error-settings',
  MAIN_SCORE_DESCRIPTION: 'score-description',
  MAIN_BUDGET_SCORE_DESCRIPTION: 'budget-score-description',
  MAIN_REPORT: 'report',
  MAIN_ERROR_DESCRIPTION: 'error-description',
  MAIN_REGIONS: 'regions',
  MAIN_BAD_WORDS: 'bad-words',
  MAIN_CORRECT_WORDS: 'correct-words',
  MAIN_GEO_GROUPS: 'geo-groups',
  MAIN_STOP_WORDS: 'stop-words',
  MAIN_SHARE: 'share',

  HOME_SETTINGS: 'home/settings',

  SETTINGS: 'settings',
  SETTINGS_PROFILE: 'profile',
  SETTINGS_TWO_FACTOR_AUTH: 'two-factor-auth',
  SETTINGS_CONNECTED_USERS: 'connected_users',
  SETTINGS_CONNECT: 'connect',
  SETTINGS_CONNECT_COMPANY: 'connect/company',
  SETTINGS_PAYMENT: 'payment',
  SETTINGS_MANAGER: 'manager',
  SETTINGS_SPECIALISTS: 'specialists',
  SETTINGS_NOTIFICATIONS: 'notifications',
  SETTINGS_NOTIFICATION: 'notification',
  SETTINGS_SUBSCRIPTION: 'subscription',
  SETTINGS_PAYMENT_METHODS: 'payment-methods',
  SETTINGS_EXCEPTIONS: 'exceptions',
  SETTINGS_SPECIALIST: 'specialist',
  SETTINGS_EXCEPTION: 'exception',
  SETTINGS_MEDIAPLAN: 'mediaplan',
  SETTINGS_CONNECTED_USERS_NEW: 'connected_users/new',
  SETTINGS_CONNECTED_USERS_ID: 'connected_users/:id',

  WIZARD_COMPANY: 'wizard/company',
  WIZARD_CONNECT: 'wizard/connect',
  WIZARD_BEFORE_CONNECT: 'connect/before-connect',
  WIZARD_CONFIRM_DISCONNECT: 'connect/confirm-disconnect',
  WIZARD_SUBACCOUNTS: 'connect/subaccounts',
  WIZARD_COMPANY_CONNECT: 'wizard/company/connect',
  WIZARD_COMPANY_BEFORE_CONNECT: 'wizard/company/connect/before-connect',
  WIZARD_COMPANY_CONFIRM_DISCONNECT:
    'wizard/company/connect/confirm-disconnect',
  WIZARD_COMPANY_SUBACCOUNTS: 'wizard/company/connect/subaccounts',

  CONFIRM_DISCONNECT: 'connect/company/confirm-disconnect',
  BEFORE_CONNECT: 'connect/company/before-connect',
  CONNECT_SUBACCOUNTS: 'connect/company/subaccounts',
  CONFIRM_DELETE_COMPANY: 'connect/company/confirm-delete-company',
  CONNECT_BEFORE_CONNECT: 'connect/before-connect',
  CONNECT_CONFIRM_DISCONNECT: 'connect/confirm-disconnect',

  CONFIRM_CHANGE_PLAN: 'confirm-change-plan',
  CONFIRM_PAY_ACCOUNTS: 'confirm-pay-accounts',
  CONFIRM_PREPAY_BUDGET: 'confirm-prepay-budget',
  CONFIRM_POSTPAY_BUDGET: 'confirm-postpay-budget',

  SHARE_UUID: ':uuid',

  CONNECT: 'connect',

  INVITE_GUEST: 'invite_guest',

  HELP: 'help',

  SUBACCOUNTS: 'subaccounts',

  WELCOME_GUEST_UUID: 'welcome/:guestUuid',
};
