/** @format */

import * as R from 'ramda';
import {Box, Grid, Link, Typography, useMediaQuery} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {format, parseISO} from 'date-fns';
import {gql, useQuery} from '@apollo/client';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import ModalPage from 'components/ModalPage';
import adTypeHelpers from 'helpers/adTypeHelpers';
import reportHelpers from 'helpers/reportHelpers';
import routerHelpers from 'helpers/routerHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useMixpanelContext} from 'contexts/MixpanelContext';
import {AdType, NotificationChannelType} from 'types';

import Emails from './Emails';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      currentCompany {
        uuid
        sensorExclusions {
          sensorName
        }
        tokens {
          id
          provider
        }
        lastCheckCollectDate
        lastChecks {
          sensor {
            name
          }
        }
        notifications {
          id
          channelType
          adTypes
          emailChannel {
            email
          }
        }
      }
      uuid
      lang
    }
  }
`;

const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '18px',
    whiteSpace: 'pre-line',
  },
  reportLink: {
    color: theme.palette.success.main,
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: '20px',
  },
}));

interface lastCheck {
  sensor: {
    name: string;
  };
}

interface notification {
  id: string;
  channelType: NotificationChannelType;
  adTypes: AdType[];
  emailChannel?: {
    email: string;
  };
}

interface currentCompany {
  uuid: string;
  sensorExclusions: {
    sensorName: string;
  }[];
  lastCheckCollectDate: string;
  lastChecks: lastCheck[];
  notifications: notification[];
  tokens: {
    id: string;
    provider: string;
  }[];
}

interface queryData {
  currentUser: {
    uuid: string;
    lang: string;
    currentCompany: currentCompany;
  };
}

const Report = () => {
  const searchParams = routerHelpers.useSearchParams();
  const sensors = searchParams.get('sensors');
  const {t} = useTranslation();

  const {mixpanel} = useMixpanelContext();
  const {currentAdType} = useCompanyContext();

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {loading, error, data} = useQuery<queryData>(GET_CURRENT_USER);
  if (loading || error || !data) return null;

  const {
    uuid,
    sensorExclusions,
    lastCheckCollectDate,
    lastChecks,
    notifications,
    tokens,
  } = data.currentUser.currentCompany;

  const adTypeNotifications = notifications.filter(v =>
    v.adTypes.includes(currentAdType),
  );

  const renderLink = (fileFormat: string, share: boolean) => {
    const allSensors = R.pipe(
      R.map((v: lastCheck) => v.sensor.name),
      R.without(sensorExclusions.map(v => v.sensorName)),
    )(lastChecks);

    const exportSensors = sensors || allSensors;

    const platforms = adTypeHelpers.platformsFromAdType(currentAdType);

    const filteredPlatforms = adTypeHelpers.filterPlatformsByTokens(
      tokens,
      platforms,
    );

    const url = reportHelpers.reportLink(
      data.currentUser.uuid,
      uuid,
      lastCheckCollectDate,
      exportSensors,
      filteredPlatforms,
      fileFormat,
      data.currentUser.lang,
    );

    const handleClick = async (event: any) => {
      mixpanel.track('click_report_download_link', {format: fileFormat});

      if (!isMobile) return;
      if (!share) return;
      if (!navigator.share) return;

      event.preventDefault();

      await navigator.share({
        title: 'AdSensor',
        text: t('Отчёт AdSensor', 'Отчёт AdSensor') || '',
        url: event.target.href,
      });
    };

    const formattedDate = R.pipe(
      parseISO,
      R.curryN(2, format)(R.__, 'dd-MM-yyyy'),
    )(lastCheckCollectDate);

    return (
      <Box alignItems='center' display='flex' mt={5}>
        <img
          alt='Icon'
          height='56'
          src={new URL(
            `./images/${fileFormat}.jpg`,
            import.meta.url,
          ).toString()}
          width='56'
        />
        <Box ml={4}>
          <Link
            className={classes.reportLink}
            href={url}
            target='_blank'
            onClick={handleClick}
          >
            {`${formattedDate}.${fileFormat}`}
          </Link>
        </Box>
      </Box>
    );
  };

  return (
    <ModalPage
      header={t(
        'Скачайте отчёты в удобном формате',
        'Скачайте отчёты в удобном формате',
      )}
    >
      <Typography className={classes.body}>
        <Trans>
          PDF подойдёт для презентации, а ещё его можно удобно листать, где
          угодно.
        </Trans>
      </Typography>
      <Typography className={classes.body}>
        <Trans>
          XLS можно отправить техническому специалисту, который сможет открыть с
          десктопа и внести изменения согласно рекомендациям.
        </Trans>
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={6}>
          {renderLink('pdf', false)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLink('xlsx', true)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderLink('html', true)}
        </Grid>
      </Grid>

      {!loading && <Emails notifications={adTypeNotifications} />}
    </ModalPage>
  );
};

export default Report;
