/** @format */

import {Box, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

type Props = {
  text: string;
  imageSrc: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    cursor: 'pointer',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    width: '100%'
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px'
  },
  image: {
    height: 17
  }
}));

const MenuItemImage = (props: Props) => {
  const styles = useStyles();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      className={styles.container}
      onClick={props.onClick}
    >
      <Box display='flex'>
        <Box mr={2} pt={0.5}>
          <img src={props.imageSrc} className={styles.image}/>
        </Box>
        <Box display='inline-block'>
          <Typography className={styles.text}>{props.text}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuItemImage;
