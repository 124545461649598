/** @format */

import React from 'react';

import {Breadcrumbs as MUIBreadcrumbs, Typography} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  breadcrumbsSeparator: {
    color: theme.palette.text.primary,
  },
  breadcrumbsItem: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 400,
  },
  breadcrumbsActiveItem: {
    color: '#AE5658',
    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'none',
  },
}));

interface Props {
  items: {
    href: string;
    title: React.ReactNode;
  }[];
}

const Breadcrumbs = (props: Props) => {
  const classes = useStyles();

  return (
    <MUIBreadcrumbs
      separator={
        <ChevronRightIcon
          fontSize='small'
          className={classes.breadcrumbsSeparator}
        />
      }
      aria-label='breadcrumb'
    >
      {props.items.map((v, index) =>
        index !== props.items.length - 1 ? (
          <RouterLink
            key={index}
            to={v.href}
            className={classes.breadcrumbsActiveItem}
          >
            {v.title}
          </RouterLink>
        ) : (
          <Typography key={index} className={classes.breadcrumbsItem}>
            {v.title}
          </Typography>
        ),
      )}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
