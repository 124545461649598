/** @format */
import {useMemo} from 'react';

import * as R from 'ramda';
import {Box, Grid, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';

import Autocomplete from 'components/inputs/AutocompleteField';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import checkHelpers from 'helpers/checkHelpers';
import type {Check, Region, SensorExclusion} from 'types';
import {sensorName} from 'helpers/sensor_names';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import categoriesData from './categories.json';
import Row from './Row';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        lineHeight: '24px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '30px',
      },
    },
    downloadButtonContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    row: {
      flexGrow: 1,
    },
  }),
);

interface Props {
  lastChecks: Check[];
  regions: Region[];
  sensorExclusions: SensorExclusion[];
}

interface sensorOption {
  id: string;
  title: string;
}

const Issues = (props: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const {t} = useTranslation();
  const location = useLocation();
  const {mixpanel} = useMixpanelContext();

  const {validChecks, excludedChecks, needRegionsChecks} =
    checkHelpers.groupedChecks(
      props.lastChecks,
      props.sensorExclusions,
      props.regions,
    );

  const handleClick = (category: string) => () => {
    navigate({
      pathname: '/home/main/category',
      search: `?name=${category}`,
    });
  };

  const sensorsOptions = () => {
    return validChecks
      .concat(excludedChecks)
      .concat(needRegionsChecks)
      .map(v => v.sensor.name)
      .map(v => ({id: v, title: t(sensorName(v)).replaceAll(' ', ' ')}))
      .sort((a, b) => {
        if (a.title > b.title) return 1;
        if (a.title === b.title) return 0;
        return -1;
      });
  };

  const handleChangeAutocomplete = (value: sensorOption | null) => {
    if (value === null) return;

    mixpanel.track('open_sensor_dashboard_autocomplete_variant', {
      sensor: value.id,
    });
    navigate(checkHelpers.detailsPageLink(value.id, value.title));
  };

  const handleStartTypingAutocomplete = () => {
    mixpanel.track('start_typing_dashboard_autocomplete_variant');
  };

  const handleClickDownload = () => {
    mixpanel.people.increment('open_full_report_page_count');
    mixpanel.track('click_status_open_full_report_button');

    navigate(
      {
        pathname: '/home/main/report',
      },
      {
        state: {background: location},
      },
    );
  };

  const filledCategories = useMemo(() => {
    return categoriesData.filter((v, _) => {
      const checks = validChecks.filter(check =>
        v['sensors'].includes(check.sensor.name),
      );
      return checks.length > 0;
    });
  }, [validChecks]);

  return (
    <>
      <Box mt={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          spacing={4}
        >
          <Grid item>
            <Typography className={classes.headerText}>
              <Trans>Рекомендации по улучшению</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.downloadButtonContainer}>
            <SecondaryMediumButton
              variant='contained'
              onClick={handleClickDownload}
            >
              <Trans>Скачать отчёт</Trans>
            </SecondaryMediumButton>
          </Grid>
        </Grid>
      </Box>

      <Box mt={6} mb={6}>
        <Autocomplete
          placeholder={t('Поиск по сенсорам')}
          options={sensorsOptions()}
          onChange={handleChangeAutocomplete}
          onStartTyping={handleStartTypingAutocomplete}
        />
      </Box>

      <Grid container spacing={2}>
        {filledCategories.map((v, i) => {
          const checks = validChecks.filter(check =>
            v['sensors'].includes(check.sensor.name),
          );

          const totalFound = R.sum(checks.map(v => v.found));
          const totalNew = R.sum(checks.map(v => v.new));

          return (
            <Grid item className={classes.row} key={i}>
              <Row
                description={t(v['description'])}
                found={totalFound}
                new={totalNew}
                title={t(v['title'])}
                onClick={handleClick(v['name'])}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Issues;
