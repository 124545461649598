/** @format */

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {Box, Typography} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {format, parseISO} from 'date-fns';

import BoxPlate from 'components/plates/BoxPlate';
import Legend from './Legend';
import type {LegendItemVariant} from './Legend';

const NO_ANOMALY_BAR_COLOR = '#A492F2';
const NO_ANOMALY_AREA_COLOR = '#514A72';
const HIGH_INCREASE_COLOR = '#F65E7A';
const HIGH_DECREASE_COLOR = '#694AD0';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      alignSelf: 'flex-start',
      backgroundColor: '#1A1A1A',
      borderRadius: 5,
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'column',
      fontSize: 12,
      fontWeight: 400,
      height: 56,
      justifyContent: 'space-between',
      lineHeight: '20px',
      padding: '8px 16px',
      width: 128,
    },
    tooltipValue: {
      color: '#A27AFC',
    },
  }),
);

type ANOMALY = 'NONE' | 'HIGH_DECREASE' | 'HIGH_INCREASE';

type Item = {
  dateValue: string;
  yValue: number;
};

type ItemWithAnomaly = Item & {anomaly: ANOMALY; averageYValue: number};

type YAxisProps = {
  domain?: number[];
  ticks?: number[];
  type?: 'number' | 'category';
};

type Props = {
  data: Item[];
  formatYValue: (value: number) => string;
  noAnomalyAreaText?: string;
  noAnomalyBarText?: string;
  showAnomalies: boolean;
  yAxisProps?: YAxisProps;
};

const DateGraph = (props: Props) => {
  const anomaly = (value: number, average: number): ANOMALY => {
    if (!props.showAnomalies) return 'NONE';

    if (value <= average * 0.5) return 'HIGH_DECREASE';
    if (value >= average * 2) return 'HIGH_INCREASE';

    return 'NONE';
  };

  const data: ItemWithAnomaly[] = R.pipe(
    R.groupBy<Item, string>(v => {
      const date = parseISO(v.dateValue);
      return format(date, 'MM');
    }),
    R.values,
    R.map(group => {
      const averageYValue = R.pipe(
        R.map<Item, number>(v => v.yValue),
        R.sum,
        R.divide(R.__, group.length),
      )(group);

      return group.map(v => ({
        ...v,
        anomaly: anomaly(v.yValue, averageYValue),
        averageYValue,
      }));
    }),
    R.flatten,
  )(props.data);

  const referenceAreas = R.pipe(
    R.groupBy<ItemWithAnomaly, string>(v => v.averageYValue.toString()),
    R.values,
    R.map(group => {
      const x1 = group[0].dateValue;
      const x2 = (group.at(-1) as ItemWithAnomaly).dateValue;

      const averageYValue = group[0].averageYValue;
      const y1 = averageYValue * 0.7;
      const y2 = averageYValue * 1.3;

      return {x1, x2, y1, y2};
    }),
  )(data);

  // BarChart crashs when data == []
  if (data.length === 0) {
    return (
      <Box mt={8}>
        <Alert severity='error'>Нет данных для построения графика</Alert>
      </Box>
    );
  }

  const formatDateValue = (dateValue: string) => {
    const date = parseISO(dateValue);
    return format(date, 'dd.MM');
  };

  const cellFill = (item: ItemWithAnomaly) => {
    if (item.anomaly === 'HIGH_DECREASE') return HIGH_DECREASE_COLOR;
    if (item.anomaly === 'HIGH_INCREASE') return HIGH_INCREASE_COLOR;

    return NO_ANOMALY_BAR_COLOR;
  };

  const renderLegend = () => {
    if (!props.showAnomalies) return null;

    const items = [
      {
        text: 'Превышение среднего в 2 раза и более',
        backgroundColor: HIGH_INCREASE_COLOR,
      },
      {
        text: props.noAnomalyBarText || 'Среднее значение',
        backgroundColor: NO_ANOMALY_BAR_COLOR,
      },
      {
        text: 'Снижение среднего в 2 раза и более',
        backgroundColor: HIGH_DECREASE_COLOR,
      },
      {
        text: props.noAnomalyAreaText || 'Зона предельной нормы',
        backgroundColor: NO_ANOMALY_AREA_COLOR,
        variant: 'SQUARE' as LegendItemVariant,
      },
    ];

    return (
      <Box mt={10}>
        <Legend items={items} smGridSize={3} xsGridSize={12} />
      </Box>
    );
  };

  return (
    <>
      <BoxPlate>
        <Box height={300} pb={3} pr={3} pt={3}>
          <ResponsiveContainer height='100%'>
            <BarChart barGap={12} data={data}>
              <XAxis
                axisLine={false}
                dataKey='dateValue'
                interval='preserveStartEnd'
                tickLine={false}
                tick={<CustomXTick formatDateValue={formatDateValue} />}
              />
              <YAxis
                axisLine={false}
                dataKey='yValue'
                tick={<CustomYTick formatYValue={props.formatYValue} />}
                tickLine={false}
                {...props.yAxisProps}
              />

              <CartesianGrid stroke='#414141' />

              <Tooltip
                content={
                  <CustomTooltip
                    formatDateValue={formatDateValue}
                    formatYValue={props.formatYValue}
                  />
                }
                cursor={false}
              />

              <Bar barSize={8} dataKey='yValue' radius={[6, 6, 0, 0]}>
                {data.map((v: ItemWithAnomaly, index: number) => {
                  const fill = cellFill(v);
                  return <Cell key={index} fill={fill} />;
                })}
              </Bar>

              {props.showAnomalies &&
                referenceAreas.map((v, i) => (
                  <ReferenceArea
                    key={i}
                    fill='#8271C7'
                    fillOpacity={0.25}
                    x1={v.x1}
                    x2={v.x2}
                    y1={v.y1}
                    y2={v.y2}
                  />
                ))}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </BoxPlate>

      {renderLegend()}
    </>
  );
};

const CustomXTick = (props: any) => {
  return (
    <text
      dx={-10}
      dy={10}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.formatDateValue(props.payload.value)}
    </text>
  );
};

const CustomYTick = (props: any) => {
  return (
    <text
      dx={-50}
      fill='#FFFFFF'
      fontSize={10}
      fontWeight='400'
      x={props.x}
      y={props.y}
    >
      {props.formatYValue(props.payload.value)}
    </text>
  );
};

// https://stackoverflow.com/questions/65913461
const CustomTooltip = (props: any) => {
  const classes = useStyles();

  if (!props.active) return null;
  if (!props.payload) return null;
  if (!props.payload[0]) return null;

  const {payload} = props.payload[0];

  return (
    <Box className={classes.tooltip}>
      <Typography className={classes.tooltipValue}>
        {props.formatYValue(payload.yValue)}
      </Typography>
      <Typography>{props.formatDateValue(payload.dateValue)}</Typography>
    </Box>
  );
};

export default DateGraph;
