/** @format */

import {Theme, createStyles, withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

type Props = Record<string, unknown>;

const Title = withStyles((_: Theme) =>
  createStyles({
    root: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '30px',
    },
  }),
)((props: Props) => <Typography component='div' {...props} />);

export default Title;
