/** @format */

import Alert from '@material-ui/lab/Alert';
import {Box, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import ModalPage from 'components/ModalPage';
import routerHelpers from 'helpers/routerHelpers';
import {useCompanyContext} from 'contexts/CompanyContext';
import {useTexts} from 'helpers/sensorsTextsHelpers';

const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '18px',
    whiteSpace: 'pre-line',
  },
  title: {
    color: theme.palette.success.main,
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: '21px',
  },
  content: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '18px',
    whiteSpace: 'pre-line',
  },
}));

// https://docs.google.com/document/d/1VtlotcH8MIEHx60rTi-K6B7E8zEsaxEoXiMm1VuZa_k/edit
// https://docs.google.com/document/d/1xJX_K28nyC32mdantFbll9QKSl58VrYCWdDq8q7G2CE/edit
const ErrorDescription = () => {
  const searchParams = routerHelpers.useSearchParams();
  const name = searchParams.get('name');
  const humanName = searchParams.get('humanName');

  const {t} = useTranslation();
  const {currentAdType} = useCompanyContext();
  const classes = useStyles();
  const sensorsData = useTexts();

  if (!name) return <Alert severity='error'>Не указан сенсор</Alert>;

  return (
    <ModalPage header={humanName}>
      <Typography className={classes.body}>{sensorsData[name].info}</Typography>

      <Box display='flex' flexDirection='column' mt={2}>
        {sensorsData[name]['growth'] && (
          <Box mt={4}>
            <Typography className={classes.title} display='inline'>
              {t('Точки роста', 'Точки роста')}
            </Typography>
            <Typography className={classes.body}>
              {sensorsData[name]['growth']}
            </Typography>
          </Box>
        )}

        <Box mt={4}>
          <Typography className={classes.title} display='inline'>
            {t('Как это работает', 'Как это работает')}
          </Typography>
          <Typography className={classes.body}>
            {sensorsData[name]['how_it_works'][currentAdType]}
          </Typography>
        </Box>
      </Box>
    </ModalPage>
  );
};

export default ErrorDescription;
