/** @format */

import {Box} from '@material-ui/core';

const Hr = () => {
  return (
    <Box borderBottom='1px solid #8E8E8E' my={4} />
  );
};

export default Hr;
