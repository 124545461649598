/** @format */

import {Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const NextButton = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: '20px',
    padding: 10,
    '&:hover': {
      backgroundColor: theme.custom_palette.success.hover,
    },
  },
}))(Button);

export default NextButton;
