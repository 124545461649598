/** @format */

import {forwardRef} from 'react';

import {Box} from '@material-ui/core';
import clsx from 'clsx';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';

type StyleProps = {
  backgroundColor: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: StyleProps) => ({
      alignItems: 'stretch',
      backgroundColor: props.backgroundColor,
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
      position: 'relative',
      padding: theme.spacing(4),
    }),
    // http://nicolasgallagher.com/pure-css-folded-corner-effect/
    dogEar: {
      borderBottomColor: theme.palette.secondary.main,
      borderLeftColor: theme.palette.secondary.main,
      borderRadius: '0 0 0 8px',
      borderRightColor: theme.palette.background.default,
      borderStyle: 'solid',
      borderTopColor: theme.palette.background.default,
      borderWidth: 24,
      height: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: 0,
    },
  }),
);

type Props = {
  backgroundColor?: string;
  hasDogEar?: boolean;
  children: React.ReactNode;
  className?: string;
};

// https://github.com/facebook/react/issues/16653#issuecomment-564423981
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forward_and_create_ref/
const BoxPlate = forwardRef<HTMLElement, Props>((props, ref) => {
  const {backgroundColor = '#202020', hasDogEar = false, className} = props;

  const styleProps = {backgroundColor};
  const classes = useStyles(styleProps);

  // https://github.com/mui-org/material-ui/issues/17010
  return (
    <Box {...{ref}} className={clsx(classes.container, className)}>
      {props.children}
      {hasDogEar && <Box className={classes.dogEar} />}
    </Box>
  );
});

BoxPlate.displayName = 'BoxPlate';

export default BoxPlate;
