/** @format */
import {useMemo} from 'react';

import * as R from 'ramda';
import {Box, Grid, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';

import Autocomplete from 'components/inputs/AutocompleteField';
import SecondaryMediumButton from 'components/buttons/SecondaryMediumButton';
import checkHelpers from 'helpers/checkHelpers';
import type {Check, Region, SensorExclusion} from 'types';
import {sensorName} from 'helpers/sensor_names';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import categoriesData from '../Issues/categories.json';
import Row from './Row';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      color: '#F1F1F1',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '30px',
      letterSpacing: '0.225px',
    },
    downloadButtonContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    downloadButton: {
      padding: theme.spacing(4, 8),
      borderRadius: '8px',
      background: '#202020',
      boxShadow: '1px 1px 5px 0px rgba(0, 0, 0, 0.05)',
      color: '#F1F1F1',
      fontSize: '14px',
      fontWeight: 400,
      '&:hover': {
        background: '#202020',
      },
    },
    downloadButtonIcon: {
      width: 18,
      height: 18,
      marginRight: theme.spacing(2),
    },
    row: {
      flexGrow: 1,
    },
  }),
);

interface Props {
  lastChecks: Check[];
  regions: Region[];
  sensorExclusions: SensorExclusion[];
}

interface sensorOption {
  id: string;
  title: string;
}

const IssuesNew = (props: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const {t} = useTranslation();
  const location = useLocation();
  const {mixpanel} = useMixpanelContext();

  const {validChecks, excludedChecks, needRegionsChecks} =
    checkHelpers.groupedChecks(
      props.lastChecks,
      props.sensorExclusions,
      props.regions,
    );

  const handleClick = (category: string) => () => {
    navigate(`/home/main/category?name=${category}`);
  };

  const sensorsOptions = () => {
    return validChecks
      .concat(excludedChecks)
      .concat(needRegionsChecks)
      .map(v => v.sensor.name)
      .map(v => ({id: v, title: t(sensorName(v)).replaceAll(' ', ' ')}))
      .sort((a, b) => {
        if (a.title > b.title) return 1;
        if (a.title === b.title) return 0;
        return -1;
      });
  };

  const handleChangeAutocomplete = (value: sensorOption | null) => {
    if (value === null) return;

    mixpanel.track('open_sensor_dashboard_autocomplete_variant', {
      sensor: value.id,
    });

    navigate(checkHelpers.detailsPageLink(value.id, value.title));
  };

  const handleStartTypingAutocomplete = () => {
    mixpanel.track('start_typing_dashboard_autocomplete_variant');
  };

  const handleClickDownload = () => {
    mixpanel.people.increment('open_full_report_page_count');
    mixpanel.track('click_status_open_full_report_button');

    navigate(
      {
        pathname: '/home/main/report',
      },
      {
        state: {background: location},
      },
    );
  };

  const generateCategoryIcon = (name: string) => {
    if (name === 'campaign_settings') {
      return new URL('./images/settings_suggest_icon.svg', import.meta.url);
    }

    if (name === 'ads_quality') {
      return new URL('./images/quality_icon.svg', import.meta.url);
    }

    if (name === 'targeting_semantic_kernel') {
      return new URL('./images/targeting_icon.svg', import.meta.url);
    }

    if (name === 'feeds') {
      return new URL('./images/feeds_icon.svg', import.meta.url);
    }

    return new URL('./images/budget_icon.svg', import.meta.url);
  };

  const categoriesWithIconsData = categoriesData.map(v => ({
    ...v,
    icon: generateCategoryIcon(v.name),
  }));

  const filledCategories = useMemo(() => {
    return categoriesWithIconsData.filter((v, _) => {
      const checks = validChecks.filter(check =>
        v['sensors'].includes(check.sensor.name),
      );
      return checks.length > 0;
    });
  }, [validChecks]);

  return (
    <>
      <Box mt={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          spacing={4}
        >
          <Grid item>
            <Typography className={classes.headerText}>
              <Trans>Все сенсоры</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.downloadButtonContainer}>
            <SecondaryMediumButton
              className={classes.downloadButton}
              variant='contained'
              onClick={handleClickDownload}
            >
              <GetAppIcon
                className={classes.downloadButtonIcon}
                fontSize='small'
                htmlColor='#fff'
              />
              <Trans>Отчёт</Trans>
            </SecondaryMediumButton>
          </Grid>
        </Grid>
      </Box>

      <Box mt={6} mb={6}>
        <Autocomplete
          placeholder={t('Поиск по сенсорам')}
          options={sensorsOptions()}
          onChange={handleChangeAutocomplete}
          onStartTyping={handleStartTypingAutocomplete}
        />
      </Box>

      <Box>
        {filledCategories.map((v, i) => {
          const checks = validChecks.filter(check =>
            v['sensors'].includes(check.sensor.name),
          );

          const totalFound = R.sum(checks.map(v => v.found));
          const totalNew = R.sum(checks.map(v => v.new));

          return (
            <Row
              key={i}
              description={t(v['description'])}
              found={totalFound}
              new={totalNew}
              title={t(v['title'])}
              onClick={handleClick(v['name'])}
              icon={v['icon']}
            />
          );
        })}
      </Box>
    </>
  );
};

export default IssuesNew;
