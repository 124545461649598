/** @format */

import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  spacing: 4,
  palette: {
    type: 'light',
    primary: {
      main: '#121212',
    },
    secondary: {
      main: '#121212',
    },
  },
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 500,
      lineHeight: '32px',
      whiteSpace: 'pre-line',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        borderRadius: 16
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 16
      }
    }
  }
});

export default theme;
