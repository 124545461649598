/** @format */

import {ChangeEvent, useEffect, useState} from 'react';

import * as R from 'ramda';
import Alert from '@material-ui/lab/Alert';
import {clsx} from 'clsx';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useNavigate} from 'react-router-dom';

import ConfirmRemove from 'components/ConfirmRemove';
import MediumTextField from 'components/inputs/MediumTextField';
import ModalLoading from 'components/loading/ModalLoading';
import ModalPage from 'components/ModalPage';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

type getStopWords = {
  currentUser: {
    currentCompany: {
      stopWords: string[];
    };
  };
};

const GET_STOP_WORDS = gql`
  query GetStopWords {
    currentUser {
      currentCompany {
        stopWords
      }
    }
  }
`;

const SAVE_STOP_WORDS = gql`
  mutation SaveStopWords($stopWords: [String!]!) {
    saveStopWords(stopWords: $stopWords)
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
      whiteSpace: 'pre-line',
    },
    addLink: {
      color: theme.palette.success.main,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.success.main,
      },
    },
    tag: {
      alignItems: 'center',
      display: 'flex',
      marginRight: theme.spacing(3),
    },
    tagText: {
      color: theme.palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
      opacity: 0.7,
    },
    addedTagText: {
      fontWeight: 700,
      opacity: 1,
    },
    removeTagIcon: {
      opacity: 0.7,
      padding: theme.spacing(1),
    },
    removeAddedTagIcon: {
      opacity: 1,
    },
    footer: {
      alignItems: 'center',
      display: 'flex',
    },
    footerText: {
      color: '#808080',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
  }),
);

const StopWords = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const {t} = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [stopWords, setStopWords] = useState<string[]>([]);
  const [addedStopWords, setAddedStopWords] = useState<string[]>([]);

  const [confirmRemove, setConfirmRemove] = useState(false);
  const [stopWordToRemove, setStopWordToRemove] = useState('');

  const {loading, error, data} = useQuery<getStopWords>(GET_STOP_WORDS);
  const [saveStopWords, {loading: saveStopWordsLoading}] =
    useMutation(SAVE_STOP_WORDS);

  useEffect(() => {
    if (!data) return;
    setStopWords(data.currentUser.currentCompany.stopWords);
  }, [data]);

  if (loading) return <ModalLoading title='Пожалуйста, подождите' />;
  if (error) return <Alert severity='error'>{error.message}</Alert>;

  if (confirmRemove) {
    const handleConfirm = () => {
      const newStopWords = stopWords.filter(v => v !== stopWordToRemove);
      const newAddedStopWords = addedStopWords.filter(
        v => v !== stopWordToRemove,
      );

      setStopWords(newStopWords);
      setAddedStopWords(newAddedStopWords);

      setConfirmRemove(false);
      setStopWordToRemove('');
    };

    const handleCancel = () => {
      setConfirmRemove(false);
      setStopWordToRemove('');
    };

    const confirmRemoveHeader = t(
      'Вы уверены что хотите удалить слово "{{stopWordToRemove}}" из списка стоп-слов?',
      'Вы уверены что хотите удалить слово "{{stopWordToRemove}}" из списка стоп-слов?',
      {stopWordToRemove},
    );
    return (
      <ConfirmRemove
        header={confirmRemoveHeader}
        text={t(
          'Вы всегда сможет добавить его снова, если захотите.',
          'Вы всегда сможет добавить его снова, если захотите.',
        )}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    );
  }

  const renderInput = () => {
    // https://stackoverflow.com/a/42645711/3632318
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    };

    const handleAdd = () => {
      const lines = R.pipe(
        R.map(R.trim),
        R.reject(R.isEmpty),
      )(inputValue.split('\n'));
      const newStopWords = R.uniq(stopWords.concat(lines));
      const newAddedStopWords = R.uniq(addedStopWords.concat(lines));

      setStopWords(newStopWords);
      setAddedStopWords(newAddedStopWords);

      setInputValue('');
    };

    return (
      <Box mt={8}>
        <MediumTextField
          placeholder={t('Добавьте новые слова', 'Добавьте новые слова')}
          minRows={4}
          maxRows={8}
          multiline
          value={inputValue}
          myProps={{placeholderFocused: ''}}
          InputProps={{
            endAdornment: inputValue ? (
              <InputAdornment position='end'>
                <Link
                  className={classes.addLink}
                  component='button'
                  onClick={handleAdd}
                >
                  <Typography>
                    <Trans>Добавить</Trans>
                  </Typography>
                </Link>
              </InputAdornment>
            ) : null,
          }}
          onChange={handleChange}
        />
      </Box>
    );
  };

  const renderTag = (stopWord: string, index: number) => {
    const handleRemove = () => {
      setConfirmRemove(true);
      setStopWordToRemove(stopWord);
    };

    const isAddedTag = addedStopWords.includes(stopWord);

    return (
      <Box key={index} className={classes.tag}>
        <Typography
          className={clsx(classes.tagText, {
            [classes.addedTagText]: isAddedTag,
          })}
        >
          {stopWord}
        </Typography>
        <IconButton
          className={clsx([
            classes.removeTagIcon,
            {[classes.removeAddedTagIcon]: isAddedTag},
          ])}
          onClick={handleRemove}
        >
          <img
            alt='Remove'
            height={18}
            src={new URL('./images/remove.png', import.meta.url).href}
            width={18}
          />
        </IconButton>
      </Box>
    );
  };

  const renderButton = () => {
    const handleSave = async () => {
      await saveStopWords({variables: {stopWords: stopWords}});
      navigate(-1);
    };

    return (
      <Box mt={16}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <PrimaryMediumButton
              disabled={saveStopWordsLoading}
              fullWidth
              onClick={handleSave}
            >
              <Trans>Сохранить</Trans>
            </PrimaryMediumButton>
          </Grid>

          <Grid item className={classes.footer} xs={12} sm={8}>
            <Typography className={classes.footerText}>
              <Trans>
                Добавленные и удаленные слова будут учтены при следующей
                проверке — завтра в 10:00
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <ModalPage
      header={t(
        'Наличие стоп-слов в тексте объявлений',
        'Наличие стоп-слов в тексте объявлений',
      )}
    >
      <Typography className={classes.text}>
        <Trans>
          Вы можете настроить списки слов, по которым ваша реклама НЕ должна
          показываться. Добавьте новые слова или удалите существующие, если они
          вам не подходят.
          <br />
          <br />
          Вы можете ввести слово только в одной форме, остальные формы этого
          слова мы учтем сами.
        </Trans>
      </Typography>

      {renderInput()}

      <Box display='flex' flexWrap='wrap' mt={4}>
        {stopWords.sort().map((v, i) => renderTag(v, i))}
      </Box>

      {renderButton()}
    </ModalPage>
  );
};

export default StopWords;
