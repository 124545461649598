/** @format */

import {useMemo} from 'react';

import clsx from 'clsx';

import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

import {
  GOOD_BREAKPOINT,
  ALMOST_GOOD_BREAKPOINT,
  NORMAL_BREAKPOINT,
  UNOPTIMAL_BREAKPOINT,
} from 'constants/budgetScoreBreakpoints';

const useStyles = makeStyles(() =>
  createStyles({
    statusItem: {
      width: 56,
      height: 56,
      borderRadius: 4,
    },
    statusItemWithoutBackground: {
      backgroundColor: 'transparent !important',
    },
    badStatus: {
      backgroundColor: '#AE5658',
    },
    normalStatus: {
      backgroundColor: '#5C5C3F',
    },
    goodStatus: {
      backgroundColor: '#677C41',
    },
    unOptimalStatus: {
      backgroundColor: '#AA8258',
    },
    neutralStatus: {
      backgroundColor: '#9D9D9D',
    },
  }),
);

const generateStatusImageName = (
  imageName: string,
  isWithoutBackground: boolean,
) => (
  <img
    alt='status'
    src={
      new URL(
        `./images/${imageName}${!isWithoutBackground ? '-white' : ''}.svg`,
        import.meta.url,
      ).href
    }
  />
);

export const renderStatusImage = (
  isLaunched: boolean,
  budgetScore: number,
  isWithoutBackground: boolean,
) => {
  if (isLaunched) {
    return (
      <img
        alt='status'
        src={new URL('./images/process.svg', import.meta.url).href}
      />
    );
  }
  if (budgetScore === GOOD_BREAKPOINT) {
    return generateStatusImageName('good', isWithoutBackground);
  }

  if (budgetScore >= NORMAL_BREAKPOINT) {
    return generateStatusImageName('normal', isWithoutBackground);
  }

  if (budgetScore >= UNOPTIMAL_BREAKPOINT) {
    return generateStatusImageName('unoptimal', isWithoutBackground);
  }

  return generateStatusImageName('bad', isWithoutBackground);
};

interface Props {
  statusClassName?: string;
  isLaunched?: boolean;
  isWithoutBackground?: boolean;
  budgetScore: number;
}

const BudgetScoreStatus = ({
  statusClassName,
  isLaunched = false,
  isWithoutBackground = false,
  budgetScore,
}: Props) => {
  const classes = useStyles();

  const statusItemClassName = useMemo(() => {
    if (isLaunched) {
      return classes.neutralStatus;
    }

    if (budgetScore === GOOD_BREAKPOINT) {
      return classes.goodStatus;
    }

    if (
      budgetScore > NORMAL_BREAKPOINT &&
      budgetScore <= ALMOST_GOOD_BREAKPOINT
    ) {
      return classes.normalStatus;
    }

    if (
      budgetScore >= UNOPTIMAL_BREAKPOINT &&
      budgetScore <= NORMAL_BREAKPOINT
    ) {
      return classes.unOptimalStatus;
    }

    return classes.badStatus;
  }, [isLaunched, budgetScore, classes]);

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='center'
      className={clsx(
        classes.statusItem,
        statusItemClassName,
        statusClassName,
        isWithoutBackground && classes.statusItemWithoutBackground,
      )}
    >
      {renderStatusImage(isLaunched, budgetScore, isWithoutBackground)}
    </Box>
  );
};

export default BudgetScoreStatus;
