/** @format */
import {useState} from 'react';

import {Box, Grid, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans} from 'react-i18next';

import constants from 'helpers/constants';
import BoxPlate from 'components/plates/BoxPlate';
import PrimaryMediumButton from 'components/buttons/PrimaryMediumButton';

type Props = {
  url: string;
  onCopy: () => void;
  variant: 'DARK' | 'LIGHT';
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    linkText: {
      color: (props: Props) => props.variant === 'LIGHT' ? '#73A011' : 'white',
      fontWeight: 400,
      lineHeight: '17px',
      overflowWrap: 'anywhere',
    },
  }),
);

const ShareLink = (props: Props) => {
  const [copied, setCopied] = useState(false);

  const classes = useStyles(props);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.url);
    setCopied(true);
    if (props.onCopy != null) {
      props.onCopy();
    }
  };

  const backgroundColor = () => {
    if (props.variant === 'LIGHT') {
      return '#F6F6F6';
    }
    return '#202020';
  }

  return (
    <>
      <BoxPlate backgroundColor={backgroundColor()}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9}>
            <Box display='flex' alignItems='center' height='100%'>
              <Typography
                variant='body2'
                component='span'
                className={classes.linkText}
              >
                {props.url}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <PrimaryMediumButton onClick={handleCopy} fullWidth>
              {copied ? (
                <>
                  <Trans>Скопировано</Trans> {constants.CHECK_MARK}
                </>
              ) : (
                <Trans>Скопировать</Trans>
              )}
            </PrimaryMediumButton>
          </Grid>
        </Grid>
      </BoxPlate>
    </>
  );
};

export default ShareLink;
