/** @format */

import {Box} from '@material-ui/core';

import CustomInfo from './CustomInfo';
import IdText from './IdText';

const Ad = props => {
  const sample = props.reportItemGroup[0];
  const showCustomInfo = sample.maxLocationField === 'adId';

  return (
    <Box display='flex' flexDirection='column'>
      <IdText id={sample.adId} text={sample.ad} />

      {showCustomInfo && (
        <CustomInfo
          reportItemGroup={props.reportItemGroup}
          sensorName={props.sensorName}
        />
      )}
    </Box>
  );
};

export default Ad;
