/** @format */

import {useEffect, useState} from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {Box, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import MediumTextField from 'components/inputs/MediumTextField';

const useStyles = makeStyles(theme => ({
  // https://inthetechpit.com/2020/09/21/change-highlight-color-autocomplete-material-ui-reactjs/
  option: {
    '&[data-focus="true"]': {
      backgroundColor: '#F0F0F0',
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&[aria-selected="true"][data-focus="true"]': {
      backgroundColor: '#F0F0F0',
    },
  },
  optionLabel: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '33px',
  },
  papper: {
    backgroundColor: 'white'
  },
}));

const AutocompletePicker = props => {
  const classes = useStyles();
  const [pendingValue, setPendingValue] = useState(props.value);

  const {t} = useTranslation();

  useEffect(() => {
    setPendingValue(props.value);
  }, [props.value]);

  const handleChange = (_, value) => {
    setPendingValue(value);
  };

  const handleClose = () => {
    props.onClose(pendingValue);
  };

  return (
    <Autocomplete
      classes={{option: classes.option, paper: classes.papper}}
      disableClearable
      disableCloseOnSelect
      forcePopupIcon={false}
      getOptionLabel={option => option[props.labelField]}
      getOptionSelected={(option, value) =>
        option[props.idField] === value[props.idField]
      }
      multiple
      noOptionsText={t('Ничего не найдено', 'Ничего не найдено')}
      options={props.options}
      renderTags={() => null}
      value={pendingValue}
      renderInput={params => (
        <MediumTextField
          {...params}
          myProps={{placeholderFocused: props.placeholderFocused}}
          placeholder={props.placeholder}
        />
      )}
      renderOption={(option, {selected}) => (
        <Box
          alignItems='center'
          display='flex'
          flexGrow={1}
          justifyContent='space-between'
        >
          <Typography className={classes.optionLabel}>{option.name}</Typography>
          {selected && (
            <img
              alt='Check'
              height='14'
              src={new URL('./images/check.png', import.meta.url)}
              width='14'
            />
          )}
        </Box>
      )}
      onChange={handleChange}
      onClose={handleClose}
    />
  );
};

export default AutocompletePicker;
