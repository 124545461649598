/** @format */
import {useEffect, useState} from 'react';

import EditIcon from '@material-ui/icons/Edit';
import {Box, IconButton, Typography} from '@material-ui/core';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import {gql, useMutation} from '@apollo/client';
import {useNavigate, useLocation} from 'react-router-dom';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import DemoModal from 'components/DemoModal';
import InformationDialog from 'components/dialogs/InformationDialog';
import PrimarySmallButton from 'components/buttons/PrimarySmallButton';
import imageHelpers from 'helpers/imageHelpers';
import routerHelpers from 'helpers/routerHelpers';
import type {Provider, UserRole} from 'types';
import {useMixpanelContext} from 'contexts/MixpanelContext';

import ConnectButton from './ConnectButton';
import ConnectButtonNew from './ConnectButtonNew';
import ConnectedIcon from './ConnectedIcon';
import DisconnectButton from './DisconnectButton';
import type {ProviderToken} from './ConnectRows';

const DELETE_PROVIDER_TOKEN = gql`
  mutation DeleteProviderToken($provider: OauthProvider!, $companyId: ID!) {
    deleteProviderToken(provider: $provider, companyId: $companyId)
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
    subtitle: {
      color: '#B3B3B3',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px',
    },
    row: {
      padding: theme.spacing(4),
      borderRadius: '8px',
      background: '#202020',
    },
    providerIconContainer: {
      width: '64px',
      height: '64px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2B2B2B',
    },
    actionButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '48px',
      height: '48px',
      borderRadius: '8px',
      background: '#2B2B2B',
      marginRight: theme.spacing(2),
      '&:last-of-type': {
        marginRight: '0',
      },
      [theme.breakpoints.down('sm')]: {
        flexGrow: '1',
      },
    },
    titleNew: {
      color: '#F1F1F1',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.169px',
    },
    subtitleNew: {
      marginTop: theme.spacing(2),
      color: '#8B8B8B',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '26px',
      letterSpacing: '0.169px',
    },
    actionButtonsContainer: {
      display: 'flex',
      marginLeft: '0',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(6),
      },
    },
  }),
);

interface Props {
  canSelectSubaccounts: boolean;
  companyId: string;
  needPay: boolean;
  provider: Provider;
  providerToken: ProviderToken | undefined;
  showMetaExtremismCaption?: boolean;
  userRole: UserRole[];
}

const ConnectRow = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {t} = useTranslation();

  const {isNewVersionEnabled} = useFlags();

  const searchParams = routerHelpers.useSearchParams();
  const confirm = searchParams.get('confirm') as Provider;

  const {mixpanel} = useMixpanelContext();
  const classes = useStyles();

  const lastError = props.providerToken && props.providerToken.lastError;
  const isConnected = props.providerToken && lastError == null;

  const [showErrorDialog, setShowErrorDialog] = useState(Boolean(lastError));
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [deleteProviderToken] = useMutation(DELETE_PROVIDER_TOKEN);

  useEffect(() => {
    if (props.provider !== confirm) return;
    if (!isConnected) return;

    navigate(
      `subaccounts?companyId=${props.companyId}&provider=${props.provider}`,
      {
        state: {background: location},
      },
    );
    // run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accountNames = () => {
    if (!props.providerToken) return '';
    if (props.providerToken.accounts.length === 0) {
      return t('Субаккаунты не выбраны');
    }

    return props.providerToken.accounts
      .map(v => v.name)
      .sort()
      .join(', ');
  };

  const renderAction = () => {
    const handlePay = () => {
      if (props.userRole.includes('DEMO')) {
        setShowDemoModal(true);
        return;
      }

      navigate(
        {
          pathname: '/home/settings/confirm-pay-accounts',
          search: '?addAccounts=1',
        },
        {
          state: {background: location},
        },
      );
    };

    const handleConnect = () => {
      if (props.userRole.includes('DEMO')) {
        setShowDemoModal(true);
        return;
      }

      mixpanel.track('click_connect_button', {provider: props.provider});
      if (window.dataLayer) {
        window.dataLayer.push({
          event: `app_click_add-sensor-${props.provider}`.toLowerCase(),
        });
      }

      navigate(
        `${location.pathname}/before-connect?companyId=${props.companyId}&provider=${props.provider}`,
        {
          state: {background: location},
        },
      );
    };

    const handleDisconnect = async () => {
      if (props.userRole.includes('DEMO')) {
        setShowDemoModal(true);
        return;
      }

      mixpanel.track('click_disconnect_button', {provider: props.provider});

      navigate(
        `${location.pathname}/confirm-disconnect?companyId=${props.companyId}&provider=${props.provider}`,
        {
          state: {background: location},
        },
      );
    };

    const handleSelectSubaccounts = () => {
      if (props.userRole.includes('DEMO')) {
        setShowDemoModal(true);
        return;
      }

      mixpanel.track('click_select_accounts_button', {
        provider: props.provider,
      });

      navigate(
        `${location.pathname}/subaccounts?companyId=${props.companyId}&provider=${props.provider}`,
        {
          state: {background: location},
        },
      );
    };

    if (!isConnected && props.needPay) {
      return (
        <PrimarySmallButton onClick={handlePay}>
          <Trans>Оплатить</Trans>
        </PrimarySmallButton>
      );
    }

    if (!isConnected) {
      return (
        <Box ml={4} display='flex' className={classes.actionButtonsContainer}>
          {isNewVersionEnabled ? (
            <ConnectButtonNew
              className={classes.actionButton}
              onConnect={handleConnect}
            />
          ) : (
            <ConnectButton
              className={classes.actionButton}
              onConnect={handleConnect}
            />
          )}
        </Box>
      );
    }

    if (!props.canSelectSubaccounts) {
      return <ConnectedIcon />;
    }

    if (isNewVersionEnabled) {
      return (
        <Box ml={4} display='flex' className={classes.actionButtonsContainer}>
          <IconButton
            className={classes.actionButton}
            onClick={handleSelectSubaccounts}
          >
            <CreateOutlinedIcon fontSize='small' htmlColor='#fff' />
          </IconButton>

          <DisconnectButton
            className={classes.actionButton}
            onDisconnect={handleDisconnect}
          />
        </Box>
      );
    }

    return (
      <Box ml={4} display='flex'>
        <IconButton onClick={handleSelectSubaccounts}>
          <EditIcon />
        </IconButton>

        <DisconnectButton onDisconnect={handleDisconnect} />
      </Box>
    );
  };

  const renderErrorDialog = () => {
    const handleClose = () => {
      setShowErrorDialog(false);
      deleteProviderToken({
        variables: {provider: props.provider, companyId: props.companyId},
      });
    };

    return (
      <InformationDialog
        open={showErrorDialog}
        onClose={handleClose}
        title={t(
          'Ошибка при подключении рекламной кампании',
          'Ошибка при подключении рекламной кампании',
        )}
        body={lastError}
      />
    );
  };

  const handleCloseDemoModal = () => {
    setShowDemoModal(false);
  };

  return (
    <>
      {isNewVersionEnabled ? (
        <Box className={classes.row}>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            flexWrap='wrap'
          >
            <Box alignItems='center' display='flex'>
              <Box className={classes.providerIconContainer}>
                <img
                  alt='Logo'
                  src={imageHelpers.providerNewIconSrc(props.provider, 40)}
                />
              </Box>

              <Box ml={5}>
                <Box display='flex' flexDirection='column'>
                  <Typography className={classes.titleNew}>
                    {t(`provider_names.${props.provider}`)}
                  </Typography>
                  {props.showMetaExtremismCaption && (
                    <Typography variant='caption'>
                      <Trans>
                        Facebook, продукт компании Meta, которая признана
                        экстремистской организацией в России
                      </Trans>
                    </Typography>
                  )}

                  <Typography className={classes.subtitleNew}>
                    {accountNames()}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {renderAction()}
          </Box>

          {renderErrorDialog()}

          {showDemoModal && <DemoModal onClose={handleCloseDemoModal} />}
        </Box>
      ) : (
        <>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Box alignItems='center' display='flex'>
              <img
                alt='Logo'
                height='64'
                src={imageHelpers.providerIconSrc(props.provider, 64)}
                width='64'
              />
              <Box ml={5}>
                <Box display='flex' flexDirection='column'>
                  <Typography className={classes.title}>
                    {t(`provider_names.${props.provider}`)}
                  </Typography>
                  {props.showMetaExtremismCaption && (
                    <Typography variant='caption'>
                      <Trans>
                        Facebook, продукт компании Meta, которая признана
                        экстремистской организацией в России
                      </Trans>
                    </Typography>
                  )}
                  <Box mt={1}>
                    <Typography className={classes.subtitle}>
                      {accountNames()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {renderAction()}
          </Box>

          {renderErrorDialog()}

          {showDemoModal && <DemoModal onClose={handleCloseDemoModal} />}
        </>
      )}
    </>
  );
};

export default ConnectRow;
