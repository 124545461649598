/** @format */

import {Theme, createStyles, withStyles} from '@material-ui/core/styles';

import PrimarySmallButton from 'components/buttons/PrimarySmallButton';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  component?: React.ReactNode;
  href?: string;
  target?: string;
};

const TransparentSmallButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent',
      border: '1px solid #5A5A5A',
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
)((props: Props) => <PrimarySmallButton {...props} />);

export default TransparentSmallButton;
